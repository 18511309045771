import * as React from 'react';

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span
      style={{
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
      }}
    >
      {data.options.length}
    </span>
  </div>
);

export const disabledSelectStylesWithMenu = {
  control: (styles: any, state: any) => {
    return {
      ...styles,
      borderColor: '#ced4da',
      borderRadius: '1.25rem',
    };
  },
  singleValue: (styles: any, state: any) => {
    return {
      ...styles,
      color: '#495057',
    };
  },
  menu: (provided: any) => {
    return {
      ...provided,
      zIndex: 9999,
    };
  },
};

export const disabledSelectStyles = {
  control: (styles: any, state: any) => {
    return {
      ...styles,
      borderColor: '#ced4da',
      borderRadius: '1.25rem',
    };
  },
  singleValue: (styles: any, state: any) => {
    return {
      ...styles,
      color: '#495057',
    };
  },
};
