import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { FileContext, IEmergencyContactInformation, IWellnessCentreFields } from './FileContext';
import { FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import { DEPARTMENT_WELLNESS_CENTRE, DomainContext, IStage } from '../../contexts/DomainContext';
import { RequiredLabel } from '../../components/RequiredLabel';
import DatePicker from 'react-datepicker';
import { IUser } from '../../components/Users/UsersTable';
import { UsersContext } from '../../contexts/UsersContext';
import { MyUserContext } from '../../contexts/MyUserContext';
import { disabledSelectStylesWithMenu, formatGroupLabel } from '../../utils/StylingUtils';

export const WellnessCentreFileDetails: React.FC<{
  isEditingAllowed: boolean;
  trySave: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUncheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const usersContext = useContext(UsersContext);
  const domainContext = useContext(DomainContext);
  const myUser = useContext(MyUserContext);
  const staff = usersContext.users;
  const stages = domainContext.stages;
  const fileContext = useContext(FileContext);
  const setWellnessCentreFields = fileContext.setWellnessCentreFields;
  const setEmergencyContactInformationList = fileContext.setEmergencyContactInformationList;

  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  const wellnessHouseUnitOptions = domainContext.wellnessHouses.map((wh) => ({
    label: wh.name,
    options: domainContext.wellnessHouseUnits
      .filter((whu) => whu.wellnessHouseId === wh.wellnessHouseId)
      .map((whu) => ({ value: whu.wellnessHouseUnitId, label: whu.name })),
  }));

  return (
    <div>
      <Row>
        <Col sm={5} md={5} xl={5}>
          <Row>
            <Col>
              <Card className={'mb-4'}>
                <CardHeader>File Details</CardHeader>
                <CardBody>
                  {myUser.isSuperAdmin && (
                    <Row className='square border-bottom mb-2'>
                      <Col>
                        <FormGroup>
                          <Label for={'isConfidential'}>Confidentiality</Label>
                          <CustomInput
                            type='checkbox'
                            id={'isConfidential'}
                            name={'isConfidential'}
                            label={'Highly Confidential File'}
                            checked={fileContext.isConfidential}
                            onChange={() => {
                              if (fileContext.isConfidential) {
                                props.setOpenUncheckConfidentialityModal(true);
                              } else {
                                props.setOpenCheckConfidentialityModal(true);
                              }
                              fileContext.setIsConfidential(!fileContext.isConfidential);
                              props.setIsDirty(true);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <FormGroup className={'w-100 mb-2'}>
                        <Label for={'stageId'}>Stage</Label>
                        <Select
                          styles={disabledSelectStylesWithMenu}
                          name={'stageId'}
                          placeholder={<span className='placeholder'>Select...</span>}
                          options={stages.filter(
                            (s) =>
                              s.fileTypes && s.fileTypes.includes(fileContext.fileTypeId ? fileContext.fileTypeId : '')
                          )}
                          value={stages.find((s: IStage) => s.stageId === fileContext.stageId)}
                          onChange={(value: any) => {
                            fileContext.setStageId(value?.stageId || '');
                            props.setIsDirty(true);
                          }}
                          getOptionValue={(option: IStage) => option.stageId}
                          getOptionLabel={(option: IStage) => option.name}
                          isDisabled={!props.isEditingAllowed}
                        />
                        <FormFeedback>This field is mandatory</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className={'w-100 mb-2'}>
                        <RequiredLabel for={'application-date'} required={fileContext.createdDate === undefined}>
                          Created Date
                        </RequiredLabel>
                        <DatePicker
                          selected={fileContext.createdDate}
                          name={'application-date'}
                          onChange={(date) => {
                            fileContext.setCreatedDate(date ? date : undefined);
                            props.setIsDirty(true);
                          }}
                          peekNextMonth
                          showMonthDropdown
                          disabled={!props.isEditingAllowed}
                          showYearDropdown
                          isClearable={true}
                          dateFormat='MMM dd, yyyy'
                          dropdownMode='select'
                          className={
                            props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                          }
                          placeholderText={'MMM dd, yyyy'}
                        />
                        {props.trySave && fileContext.createdDate === undefined && (
                          <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className={'w-100 mb-2'}>
                        <Label for={'staffIds'}>Staff</Label>
                        <Select
                          styles={disabledSelectStylesWithMenu}
                          name={'staffIds'}
                          placeholder={<span className='placeholder'>Select...</span>}
                          options={
                            fileContext.isConfidential
                              ? staff
                                  .sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName))
                                  .filter(
                                    (u) =>
                                      (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                      u.departmentAccessIds.includes(DEPARTMENT_WELLNESS_CENTRE) &&
                                      u.role === 'SuperAdmin'
                                  )
                              : staff
                                  .sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName))
                                  .filter(
                                    (u) =>
                                      (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                      u.departmentAccessIds.includes(DEPARTMENT_WELLNESS_CENTRE)
                                  )
                          }
                          onChange={(value: any) => {
                            fileContext.setStaffIds(value.map((v: IUser) => v.userId));
                            props.setIsDirty(true);
                          }}
                          value={selectedStaff}
                          getOptionValue={(option: IUser) => option.userId}
                          getOptionLabel={(option: IUser) =>
                            option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                          }
                          isDisabled={!props.isEditingAllowed}
                          isClearable={true}
                          isMulti={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for={'staffIds'}>Wellness House/Unit</Label>
                        <Select
                          styles={disabledSelectStylesWithMenu}
                          formatGroupLabel={formatGroupLabel}
                          placeholder={<span className='placeholder'>Select or Type...</span>}
                          options={wellnessHouseUnitOptions}
                          value={
                            fileContext.wellnessCentreFields?.wellnessHouseUnitIds
                              ? fileContext.wellnessCentreFields?.wellnessHouseUnitIds.map((uid) => ({
                                  value: uid,
                                  label: domainContext.wellnessHouseUnits.find((u) => u.wellnessHouseUnitId === uid)
                                    ?.name,
                                }))
                              : []
                          }
                          onChange={(val) => {
                            props.setIsDirty(true);
                            console.log(val);
                            setWellnessCentreFields(
                              (w) => ({ ...w, wellnessHouseUnitIds: val.map((v) => v.value) } as IWellnessCentreFields)
                            );
                          }}
                          isMulti={true}
                          isClearable={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className={'mb-3'}>
                <CardHeader className={'d-flex align-items-center'}>
                  Emergency Contact Information
                  {props.isEditingAllowed && (
                    <div className={'card-header__actions'}>
                      <Button
                        color={'primary'}
                        className='btn-sm'
                        onClick={() => {
                          const newInfo: IEmergencyContactInformation[] = [
                            {
                              name: '',
                              phone: '',
                              relationship: '',
                            },
                          ];
                          setEmergencyContactInformationList((ecil) => newInfo.concat(ecil));
                          props.setIsDirty(true);
                        }}
                      >
                        Add Info
                      </Button>
                    </div>
                  )}
                </CardHeader>
                {fileContext.emergencyContactInformationList.length > 0 && (
                  <CardBody>
                    <Row>
                      <Col>
                        <Label>Name</Label>
                      </Col>
                      <Col>
                        <Label>Phone Number</Label>
                      </Col>
                      <Col className={'mr-4'}>
                        <Label>Relationship</Label>
                      </Col>
                    </Row>
                    {fileContext.emergencyContactInformationList.map((eci: IEmergencyContactInformation, index) => (
                      <Row className={'pb-3'} key={index}>
                        <Col>
                          <Input
                            type='text'
                            placeholder='Enter name ... '
                            value={eci.name}
                            disabled={!props.isEditingAllowed}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const name = e.target.value;
                              setEmergencyContactInformationList((c) => [
                                ...c.slice(0, index),
                                { ...c[index], name: name } as IEmergencyContactInformation,
                                ...c.slice(index + 1),
                              ]);
                              props.setIsDirty(true);
                            }}
                          />
                        </Col>
                        <Col>
                          <Input
                            type='text'
                            placeholder='Enter phone ... '
                            value={eci.phone}
                            disabled={!props.isEditingAllowed}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const phone = e.target.value;
                              setEmergencyContactInformationList((c) => [
                                ...c.slice(0, index),
                                { ...c[index], phone: phone } as IEmergencyContactInformation,
                                ...c.slice(index + 1),
                              ]);
                              props.setIsDirty(true);
                            }}
                          />
                        </Col>
                        <Col>
                          <Input
                            type='text'
                            placeholder='Enter relationship ... '
                            value={eci.relationship}
                            disabled={!props.isEditingAllowed}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const relationship = e.target.value;
                              setEmergencyContactInformationList((c) => [
                                ...c.slice(0, index),
                                { ...c[index], relationship: relationship } as IEmergencyContactInformation,
                                ...c.slice(index + 1),
                              ]);
                              props.setIsDirty(true);
                            }}
                          />
                        </Col>
                        {props.isEditingAllowed && (
                          <Button
                            color={'link'}
                            className={'pl-0 text-danger'}
                            onClick={() => {
                              props.setIsDirty(true);
                              setEmergencyContactInformationList((c) => [...c.slice(0, index), ...c.slice(index + 1)]);
                            }}
                          >
                            <FaTimes />
                          </Button>
                        )}
                      </Row>
                    ))}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className={'mb-3'}>
              <Card>
                <CardHeader>Accompanying Children</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for={'childrenInformation'}>
                      Is there anything that we need to know about in relation to your children (e.g. custody
                      arrangements, school trips, etc.)?
                    </Label>
                    <Input
                      type='textarea'
                      disabled={!props.isEditingAllowed}
                      name='childrenInformation'
                      style={{ minHeight: '100px' }}
                      value={fileContext.wellnessCentreFields?.childrenInformation}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setWellnessCentreFields((w) => ({ ...w, childrenInformation: value } as IWellnessCentreFields));
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>Additional Information</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Input
                      type='textarea'
                      disabled={!props.isEditingAllowed}
                      placeholder='Additional Information...'
                      name='additionalInformation'
                      style={{ minHeight: '200px' }}
                      value={fileContext.wellnessCentreFields?.additionalInformation}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setWellnessCentreFields(
                          (w) => ({ ...w, additionalInformation: value } as IWellnessCentreFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm={7} md={7} xl={7}>
          <Card className={'mb-3'}>
            <CardHeader>Health and Safety</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for={'personsOfConcern'}>
                      Are there any persons of concern that you would like to make us aware of?
                    </Label>
                    <Input
                      type='textarea'
                      disabled={!props.isEditingAllowed}
                      name='personsOfConcern'
                      style={{ minHeight: '100px' }}
                      value={fileContext.wellnessCentreFields?.personsOfConcern}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setWellnessCentreFields((w) => ({ ...w, personsOfConcern: value } as IWellnessCentreFields));
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for={'immediateSafetyRisks'}>Are you facing any immediate safety risks?</Label>
                    <Input
                      type='textarea'
                      disabled={!props.isEditingAllowed}
                      name='immediateSafetyRisks'
                      style={{ minHeight: '100px' }}
                      value={fileContext.wellnessCentreFields?.immediateSafetyRisks}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setWellnessCentreFields(
                          (w) => ({ ...w, immediateSafetyRisks: value } as IWellnessCentreFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for={'emergencyNeedsOrFactors'}>
                      Are there any emergency needs or factors that put you at risk, e.g. epilepsy, diabetes, other?
                    </Label>
                    <Input
                      type='textarea'
                      disabled={!props.isEditingAllowed}
                      name='emergencyNeedsOrFactors'
                      style={{ minHeight: '100px' }}
                      value={fileContext.wellnessCentreFields?.emergencyNeedsOrFactors}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setWellnessCentreFields(
                          (w) => ({ ...w, emergencyNeedsOrFactors: value } as IWellnessCentreFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for={'currentHealthConcerns'}>Current medical/physical health concerns?</Label>
                    <Input
                      type='textarea'
                      disabled={!props.isEditingAllowed}
                      name='currentHealthConcerns'
                      style={{ minHeight: '100px' }}
                      value={fileContext.wellnessCentreFields?.currentHealthConcerns}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setWellnessCentreFields(
                          (w) => ({ ...w, currentHealthConcerns: value } as IWellnessCentreFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for={'allergies'}>Do you or any person with you have any allergies?</Label>
                    <Input
                      type='textarea'
                      disabled={!props.isEditingAllowed}
                      name='allergies'
                      style={{ minHeight: '100px' }}
                      value={fileContext.wellnessCentreFields?.allergies}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setWellnessCentreFields((w) => ({ ...w, allergies: value } as IWellnessCentreFields));
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for={'dietaryRestrictions'}>
                      Do you or any person with you have specific dietary/nutrition needs/restrictions?
                    </Label>
                    <Input
                      type='textarea'
                      disabled={!props.isEditingAllowed}
                      name='dietaryRestrictions'
                      style={{ minHeight: '100px' }}
                      value={fileContext.wellnessCentreFields?.dietaryRestrictions}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setWellnessCentreFields((w) => ({ ...w, dietaryRestrictions: value } as IWellnessCentreFields));
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for={'immediateThingsToAttendTo'}>
                      Are there any immediate things you need to attend to? (belongings, appointments etc,)
                    </Label>
                    <Input
                      type='textarea'
                      disabled={!props.isEditingAllowed}
                      name='immediateThingsToAttendTo'
                      style={{ minHeight: '100px' }}
                      value={fileContext.wellnessCentreFields?.immediateThingsToAttendTo}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setWellnessCentreFields(
                          (w) => ({ ...w, immediateThingsToAttendTo: value } as IWellnessCentreFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
