import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { FileContext, IFile } from '../../../components/Files/FileContext';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { Button, Col, Modal, ModalBody } from 'reactstrap';
import Select from 'react-select';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import * as React from 'react';
import { AssociatedIntakeFileDisplay } from '../../../components/Files/AssociatedIntakeFileDisplay';
import { authAxios } from '../../../services/AxiosService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { useParams } from 'react-router';
import { STAGE_INTAKE_IN_PROGRESS } from '../../../contexts/DomainContext';
import { disabledSelectStyles } from '../../../utils/StylingUtils';

interface IProps {
  openAssociateWithIntakeFileModal: boolean;
  setOpenAssociateWithIntakeFileModal: Dispatch<SetStateAction<boolean>>;
  intakeFiles: IFile[];
}

export const AssociateIntakeFileModal = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const params = useParams<{ fileId: string }>();
  const [intakeFile, setIntakeFile] = useState<IFile | undefined>(undefined);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const associateFile = () => {
    if (intakeFile) {
      const data = { fileId: params.fileId, intakeFileId: intakeFile?.fileId, stageId: STAGE_INTAKE_IN_PROGRESS };
      authAxios.post('/api/files/associate-with-intake-file', data).then((response) => {
        if (response.data.status === 'OK') {
          fileContext.setFile((f) => ({ ...f, intakeFileId: intakeFile.fileId, stageId: STAGE_INTAKE_IN_PROGRESS }));
          fileContext.setStageId(STAGE_INTAKE_IN_PROGRESS);
          props.setOpenAssociateWithIntakeFileModal(false);
          setOpenConfirmModal(false);
          notifySuccess('File Updated.');
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={props.openAssociateWithIntakeFileModal}
        participantList={props.intakeFiles}
        style={{ overflow: 'visible', minWidth: '60%' }}
      >
        <ModalHeader>Select An Intake File</ModalHeader>
        <ModalBody
          id={'associateWithIntakeFileModal'}
          style={{ minHeight: '30vh', maxHeight: '80vh', overflowY: 'auto' }}
        >
          <div className='d-flex flex-column'>
            <div className='d-inline-flex align-items-center'>
              <Col md={4}>
                <Select
                  className={'mb-2'}
                  styles={disabledSelectStyles}
                  name={'intakeFileId'}
                  placeholder={<span className='placeholder'>Select...</span>}
                  options={props.intakeFiles}
                  value={props.intakeFiles.find((f: IFile) => f.fileId === intakeFile?.fileId) || null}
                  onChange={(value: any) => {
                    setIntakeFile(value);
                  }}
                  getOptionValue={(option: IFile) => option.fileId}
                  getOptionLabel={(option: IFile) => option.fileNumber}
                  isClearable={true}
                />
              </Col>
            </div>
            {intakeFile && <AssociatedIntakeFileDisplay associatedIntakeFile={intakeFile} forModal={true} />}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            disabled={!intakeFile}
            onClick={() => {
              setOpenConfirmModal(true);
            }}
          >
            Save
          </Button>
          <Button
            color={'link'}
            onClick={() => {
              setIntakeFile(undefined);
              props.setOpenAssociateWithIntakeFileModal((s: boolean) => !s);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openConfirmModal}>
        <ModalHeader>Confirm Your Selection</ModalHeader>
        <ModalBody id={'associateWithIntakeFileModal'}>
          Please confirm that this is the correct Intake file you wish the associate this file with. This process is not
          reversible.
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            disabled={!intakeFile}
            onClick={() => {
              associateFile();
            }}
          >
            Save
          </Button>
          <Button
            color={'link'}
            onClick={() => {
              setOpenConfirmModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
