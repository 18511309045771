import * as React from 'react';
import { useState } from 'react';
import { INote } from './FileNotes';
import * as AuthenticationService from '../../services/AuthenticationService';
import { IReminder } from './FileReminders';
import { IContactDetails } from '../Clients/ClientContext';
import {
  FILE_SUB_TYPE_COMMUNITY_IMPACT,
  FILE_SUB_TYPE_GLADUE_REPORT,
  FILE_SUB_TYPE_WCC_CONNECTIVE,
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_CULTURAL_CONNECTIONS,
  FILE_TYPE_BIRTH_WORKER,
  FILE_TYPE_JORDANS_PRINCIPLE,
  STAGE_DRAFT,
  FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR,
  FILE_TYPE_INTAKE,
  FILE_TYPE_WELLNESS_CENTRE,
} from '../../contexts/DomainContext';
import { IAppointment } from './FileAppointments';
import { IUser } from '../../components/Users/UsersTable';

export interface IRequest {
  requestId?: string;
  description: string;
  cost: string;
  frequencyId: string;
  categoryId: string;
  duration: string;
  outcomeId?: string;
  serviceProvider?: string;
  dateProvided?: number;
  comment?: string;
  numberOfChildren?: string;
}

export interface IPayment {
  paymentId?: string;
  paymentType: string;
  amount: string;
  paymentDate?: number;
  vendorOrServiceProvider?: string;
  poNumber?: string;
  requestId?: string;
  submittedToFinance?: boolean;
  submittedDate?: number;
  createdBy?: string;
  receiptReceivedDate?: number;
}

export interface IIncomeOrEmployment {
  incomeOrEmploymentTypeId?: string;
  description: string;
  monthlyAmount: string;
}

export interface IResourceSupport {
  resourceSupportTypeId?: string;
  description: string;
}

export interface IOtherPerson {
  clientId: string;
  relationship: string;
}

export interface IReferral {
  referralId: string;
  date?: number;
  referralType: string;
  referredBy: string;
  name: string;
  contact: IContactDetails;
  fromCyfn?: boolean;
}

export interface IServiceProvided {
  serviceProvidedId: string;
  serviceTypeId: string;
  fromDate?: number;
  toDate?: number;
  description: string;
}

export interface IFinancialSupport {
  financialSupportId: string;
  financialSupportTypeId: string;
  description: string;
}

export interface IFilePost {
  fileId?: string;
  fileNumber?: string;
  clientIds: string[];
  stageId: string;
  fileTypeId: string;
  departmentId?: string;
  createdDate: number;
  details?: string;
  staffIds: string[];
}

export interface IFileMeta {
  noteCount: number;
}

export interface IFamilyPreservationFields {
  completedDate?: number;
  servicesProvided: IServiceProvided[];
  initialReferralDate?: number;
  numberOfKidsGrandkids?: string;
  fcsAssignedSocialWorker?: string;
}

export interface IFamilyPreservationData extends IFamilyPreservationFields {
  fileId: string;
  referrals: IReferral[];
  financialAssistance: IRequest[];
}

export interface ICulturalConnectionsFields {
  completedDate?: number;
}

export interface ICulturalConnectionsData extends ICulturalConnectionsFields {
  fileId: string;
}

export interface IBirthWorkersFields {
  completedDate?: number;
  servicesProvided: IServiceProvided[];
  referralsFromCyfn: IReferral[];
  prePostNatalId?: string;
  financialSupports: IFinancialSupport[];
}

export interface IBirthWorkersData extends IBirthWorkersFields {
  fileId: string;
  requests: IRequest[];
  referrals: IReferral[];
  financialSupports: IFinancialSupport[];
}

export interface IJordansPrincipleFields {
  iscNumber?: string;
  fileName: string;
  submittedDate?: number;
  decisionDate?: number;
  completedDate?: number;
  requestName?: string;
  childrenWithComplexMedicalNeeds?: boolean;
  childrenWithComplexMedicalNeedsDescription?: string;
  childrenLivingInPoverty?: boolean;
  childrenLivingInPovertyDescription?: string;
  experiencedRecentEmergency?: boolean;
  experiencedRecentEmergencyDescription?: string;
  familyReunification?: boolean;
  familyReunificationDescription?: string;
  culturalExperiences?: boolean;
  culturalExperiencesDescription?: string;
  overallEligibility?: boolean;
  requestTypeId?: string;
  indigenousGroupId?: string;
  bandNation?: string;
}

export interface IJordansPrincipleData extends IJordansPrincipleFields {
  fileId: string;
  requests: IRequest[];
  payments: IPayment[];
  fileSubTypeId: string;
}

export interface IWCCConnectiveFields {
  charges: string;
  clientStatus: string;
  participatedInDischargeAndCasePlanning?: number;
  sentencingDate?: number;
  indigenousGroupId?: string;
  bandNation?: string;
}

export interface IWCCConnectiveData extends IWCCConnectiveFields {
  fileId: string;
  referrals: IReferral[];
}

export interface ICourtDate {
  courtDateId?: string;
  date?: number;
  details?: string;
  addReminder?: boolean;
}

export interface ILegalSystemNavigatorFields {
  charges?: string;
  offender?: string;
  courtDatesList: ICourtDate[];
  indigenousGroupId?: string;
  bandNation?: string;
  legalSystemNavigatorTypeOfServiceId?: string;
  legalSystemNavigatorTypeOfServiceDetails?: string;
  lawyer?: string;
  lawyerContactDetails?: IContactDetails;
  complainant?: string;
}

export interface ILegalSystemNavigatorData extends ILegalSystemNavigatorFields {
  fileId: string;
  referrals: IReferral[];
}

export interface ICommunityImpactFields {
  applicationDate?: number;
  firstNation?: string;
  communityName: string;
  communityContactName: string;
  communityContactNumber: string;
  communityContactEmail: string;
  offenderName: string;
  coOffenderName: string;
  offenderLawyer: string;
  offenderChargeIds: string[];
  sentencingDate?: number;
  courtLocation: string;
  victim: string;
}

export interface ICommunityImpactData extends ICommunityImpactFields {
  fileId: string;
  referrals: IReferral[];
  offenderCharges: string;
  courtLocationId?: string;
}

export interface ISentence {
  sentenceId?: string;
  sentenceTypeId?: string;
  amount?: string;
  months?: string;
  days?: string;
}

export interface IGladueReportFields {
  assignedWriter: string;
  mentor: string;
  mentee: string;
  requestedDate?: number;
  sentencingDate?: number;
  defenceLawyer: string;
  defenceLawyerContactDetails?: IContactDetails;
  crownLawyer: string;
  crownLawyerContactDetails?: IContactDetails;
  reportUpdated?: boolean;
  courtMatter: string;
  communityImpact?: boolean;
  dispositionHistoric: string;
  initialSentencingPosition?: string;
  initialSentencingPositionAmount?: string;
  initialSentencingPositionMonths?: string;
  initialSentencingPositionDays?: string;
  dispositionType?: string;
  dispositionAmount?: string;
  dispositionMonths?: string;
  dispositionDays?: string;
  indigenousGroupId?: string;
  bandNation?: string;
  chargeIds: string[];
  initialSentencingPositionList: ISentence[];
  dispositionList: ISentence[];
}

export interface IGladueReportData extends IGladueReportFields {
  fileId: string;
  courtLocationId?: string;
}

export interface IIntakeFields {
  intakeDate?: number;
  intakeDetails: string;
  requestFileIds: string[];
  briefConcerns: string;
  custodyInformation: string;
  currentLivingSituation: string;
  experiencingOngoingPovertyOrHardship: boolean;
  experiencingOngoingPovertyOrHardshipDetails: string;
  experiencingViolence: boolean;
  experiencingViolenceDetails: string;
  identifiableGoals: string;
  nextSteps: string;
  fcsInvolvement: boolean;
  efca: boolean;
  complexMedicalNeeds: boolean;
  complexMedicalNeedsDetails: string;
  emergencies: boolean;
  emergenciesDetails: string;
  familyConnectionOrReunification: boolean;
  familyConnectionOrReunificationDetails: string;
  culturalConnections: boolean;
  culturalConnectionsDetails: string;
  incomeOrEmployments: IIncomeOrEmployment[];
  resourceSupports: IResourceSupport[];
}

export interface IIntakeData extends IIntakeFields {
  fileId: string;
}

export interface IEmergencyContactInformation {
  name: string;
  phone: string;
  relationship: string;
}
export interface IWellnessCentreFields {
  emergencyContactInformationList: IEmergencyContactInformation[];
  personsOfConcern?: string;
  immediateSafetyRisks?: string;
  emergencyNeedsOrFactors?: string;
  currentHealthConcerns?: string;
  allergies?: string;
  dietaryRestrictions?: string;
  immediateThingsToAttendTo?: string;
  childrenInformation?: string;
  additionalInformation?: string;
  wellnessHouseUnitIds: string[];
}
export interface IWellnessCentreData extends IWellnessCentreFields {
  fileId: string;
}

export interface IFile {
  fileId: string;
  fileNumber: string;
  clientIds: string[];
  otherPeople: IOtherPerson[];
  stageId: string;
  isConfidential?: boolean;
  fileTypeId: string;
  fileSubTypeId: string;
  fileTypeDisplay: string;
  departmentId?: string;
  createdDate: number;
  details?: string;
  staffIds: string[];
  reminders: IReminder[];
  referrals: IReferral[];
  requests: IRequest[];
  payments: IPayment[];
  familyPreservationFields?: IFamilyPreservationFields;
  culturalConnectionsFields?: ICulturalConnectionsFields;
  birthWorkersFields?: IBirthWorkersFields;
  jordansPrincipleFields?: IJordansPrincipleFields;
  wccConnectiveFields?: IWCCConnectiveFields;
  legalSystemNavigatorFields?: ILegalSystemNavigatorFields;
  communityImpactFields?: ICommunityImpactFields;
  gladueReportFields?: IGladueReportFields;
  originalGladueReportFile?: IGladueReportFields;
  wellnessCentreFields?: IWellnessCentreFields;
  intakeFields?: IIntakeFields;
  relationship?: string;
  courtLocationId?: string;
  appointments: IAppointment[];
  reason?: string;
  intakeFileId?: string;
  intakeStaffIds: string[];
  fileRequestDetails?: string;
  acceptedDate?: number;
  rejectedDate?: number;
  financialSupports: IFinancialSupport[];
}

export interface IFileForTable {
  fileId: string;
  fileNumber: string;
  fileDisplayName?: string;
  clientIds: string[];
  fileOtherPeople: string;
  stageId: string;
  isConfidential?: boolean;
  fileTypeId: string;
  fileSubTypeId: string;
  fileTypeDisplay: string;
  departmentId?: string;
  createdDate: number;
  details?: string;
  staffIds: string[];
  reminders: IReminder[];
  familyPreservationFields?: IFamilyPreservationFields;
  // culturalConnectionsFields?: ICulturalConnectionsFields;
  // birthWorkersFields?: IBirthWorkersFields;
  jordansPrincipleFields?: IJordansPrincipleFields;
  wccConnectiveFields?: IWCCConnectiveFields;
  legalSystemNavigatorFields?: ILegalSystemNavigatorFields;
  communityImpactFields?: ICommunityImpactFields;
  gladueReportFields?: IGladueReportFields;
  intakeFields?: IIntakeFields;
  relationship?: string;
  canEdit: boolean;
  requestFileStrings: string[] | undefined;
  reason?: string;
  payments: IPayment[];
}

type ContextType = {
  file: IFile;
  setFile: React.Dispatch<React.SetStateAction<IFile>>;
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  fileMeta: IFileMeta;
  setFileMeta: React.Dispatch<React.SetStateAction<IFileMeta>>;
  setFileAndAttributes: (file: IFile) => void;
  setFileFromAttributes: () => void;
  getCreateData: () => IFilePost;
  getEditData: () => IFilePost;
  getFamilyPreservationData: (fileId: string) => any;
  getCulturalConnectionsData: (fileId: string) => any;
  getBirthWorkersData: (fileId: string) => any;
  getJordansPrincipleData: (fileId: string) => any;
  getWCCConnectiveData: (fileId: string) => any;
  getLegalSystemNavigatorData: (fileId: string) => any;
  getCommunityImpactData: (fileId: string) => any;
  getGladueReportData: (fileId: string) => any;
  getIntakeData: (fileId: string) => any;
  getWellnessCentreData: (fileId: string) => IWellnessCentreData;

  setFamilyPreservationFields: React.Dispatch<React.SetStateAction<IFamilyPreservationFields | undefined>>;
  familyPreservationFields: IFamilyPreservationFields | undefined;

  setJordansPrincipleFields: React.Dispatch<React.SetStateAction<IJordansPrincipleFields | undefined>>;
  jordansPrincipleFields: IJordansPrincipleFields | undefined;

  wccConnectiveFields: IWCCConnectiveFields | undefined;
  setWCCConnectiveFields: React.Dispatch<React.SetStateAction<IWCCConnectiveFields | undefined>>;

  legalSystemNavigatorFields: ILegalSystemNavigatorFields | undefined;
  setLegalSystemNavigatorFields: React.Dispatch<React.SetStateAction<ILegalSystemNavigatorFields | undefined>>;

  setBirthWorkerFields: React.Dispatch<React.SetStateAction<IBirthWorkersFields | undefined>>;
  birthWorkerFields: IBirthWorkersFields | undefined;

  communityImpactFields: ICommunityImpactFields | undefined;
  setCommunityImpactFields: React.Dispatch<React.SetStateAction<ICommunityImpactFields | undefined>>;

  gladueReportFields: IGladueReportFields | undefined;
  setGladueReportFields: React.Dispatch<React.SetStateAction<IGladueReportFields | undefined>>;

  originalGladueReportFields: IGladueReportFields | undefined;
  setOriginalGladueReportFields: React.Dispatch<React.SetStateAction<IGladueReportFields | undefined>>;

  intakeFields: IIntakeFields | undefined;
  setIntakeFields: React.Dispatch<React.SetStateAction<IIntakeFields | undefined>>;

  setWellnessCentreFields: React.Dispatch<React.SetStateAction<IWellnessCentreFields | undefined>>;
  wellnessCentreFields: IWellnessCentreFields | undefined;

  emergencyContactInformationList: IEmergencyContactInformation[];
  setEmergencyContactInformationList: React.Dispatch<React.SetStateAction<IEmergencyContactInformation[]>>;

  createdDate: Date | undefined;
  setCreatedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;

  submittedDate: Date | undefined;
  setSubmittedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;

  decisionDate: Date | undefined;
  setDecisionDate: React.Dispatch<React.SetStateAction<Date | undefined>>;

  completedDate: Date | undefined;
  setCompletedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;

  staffIds: string[];
  setStaffIds: React.Dispatch<React.SetStateAction<string[]>>;

  clientIds: string[];
  setClientIds: React.Dispatch<React.SetStateAction<string[]>>;

  otherPeople: IOtherPerson[];
  setOtherPeople: React.Dispatch<React.SetStateAction<IOtherPerson[]>>;

  fileTypeId: string | undefined;
  setFileTypeId: React.Dispatch<React.SetStateAction<string | undefined>>;

  fileSubTypeId: string | undefined;
  setFileSubTypeId: React.Dispatch<React.SetStateAction<string | undefined>>;

  details: string | undefined;
  setDetails: React.Dispatch<React.SetStateAction<string | undefined>>;

  fileName: string | undefined;
  setFileName: React.Dispatch<React.SetStateAction<string | undefined>>;

  iscNumber: string | undefined;
  setIscNumber: React.Dispatch<React.SetStateAction<string | undefined>>;

  stageId: string;
  setStageId: React.Dispatch<React.SetStateAction<string>>;

  requests: IRequest[];
  setRequests: React.Dispatch<React.SetStateAction<IRequest[]>>;

  payments: IPayment[];
  setPayments: React.Dispatch<React.SetStateAction<IPayment[]>>;

  notes: INote[];
  setNotes: React.Dispatch<React.SetStateAction<INote[]>>;

  documentUploaded: boolean;
  setDocumentUploaded: React.Dispatch<React.SetStateAction<boolean>>;

  reminders: IReminder[];
  setReminders: React.Dispatch<React.SetStateAction<IReminder[]>>;

  referrals: IReferral[];
  setReferrals: React.Dispatch<React.SetStateAction<IReferral[]>>;

  referralsFromCyfn: IReferral[];
  setReferralsFromCyfn: React.Dispatch<React.SetStateAction<IReferral[]>>;

  servicesProvided: IServiceProvided[];
  setServicesProvided: React.Dispatch<React.SetStateAction<IServiceProvided[]>>;

  initialSentencingPositionList: ISentence[];
  setInitialSentencingPositionList: React.Dispatch<React.SetStateAction<ISentence[]>>;

  dispositionList: ISentence[];
  setDispositionList: React.Dispatch<React.SetStateAction<ISentence[]>>;

  requestName: string | undefined;
  setRequestName: React.Dispatch<React.SetStateAction<string | undefined>>;

  courtLocationId: string | undefined;
  setCourtLocationId: React.Dispatch<React.SetStateAction<string | undefined>>;

  courtDatesList: ICourtDate[];
  setCourtDatesList: React.Dispatch<React.SetStateAction<ICourtDate[]>>;

  appointments: IAppointment[];
  setAppointments: React.Dispatch<React.SetStateAction<IAppointment[]>>;

  isConfidential: boolean | undefined;
  setIsConfidential: React.Dispatch<React.SetStateAction<boolean | undefined>>;

  reason: string | undefined;
  setReason: React.Dispatch<React.SetStateAction<string | undefined>>;

  financialSupports: IFinancialSupport[];
  setFinancialSupports: React.Dispatch<React.SetStateAction<IFinancialSupport[]>>;

  removeNonSuperAdmins: (staff: IUser[]) => any;
};

export const FileContext = React.createContext<ContextType>({
  file: {} as IFile,
  setFile: () => {},
  loaded: false,
  setLoaded: () => {},
  fileMeta: {} as IFileMeta,
  setFileMeta: () => {},
  setFileAndAttributes: () => {},
  setFileFromAttributes: () => {},
  getCreateData: () => ({} as IFilePost),
  getEditData: () => ({} as IFilePost),
  getFamilyPreservationData: () => ({}),
  getCulturalConnectionsData: () => ({}),
  getBirthWorkersData: () => ({}),
  getJordansPrincipleData: () => ({}),
  getWCCConnectiveData: () => ({}),
  getLegalSystemNavigatorData: () => ({}),
  getCommunityImpactData: () => ({}),
  getGladueReportData: () => ({}),
  getIntakeData: () => ({}),
  getWellnessCentreData: () => ({} as IWellnessCentreData),

  setFamilyPreservationFields: () => {},
  familyPreservationFields: undefined,

  wccConnectiveFields: undefined,
  setWCCConnectiveFields: () => {},

  legalSystemNavigatorFields: undefined,
  setLegalSystemNavigatorFields: () => {},

  setBirthWorkerFields: () => {},
  birthWorkerFields: undefined,

  communityImpactFields: undefined,
  setCommunityImpactFields: () => {},

  gladueReportFields: undefined,
  setGladueReportFields: () => {},

  jordansPrincipleFields: undefined,
  setJordansPrincipleFields: () => {},

  originalGladueReportFields: undefined,
  setOriginalGladueReportFields: () => {},

  intakeFields: undefined,
  setIntakeFields: () => {},

  setWellnessCentreFields: () => {},
  wellnessCentreFields: undefined,

  emergencyContactInformationList: [] as IEmergencyContactInformation[],
  setEmergencyContactInformationList: () => {},

  createdDate: undefined,
  setCreatedDate: () => {},

  submittedDate: undefined,
  setSubmittedDate: () => {},

  decisionDate: undefined,
  setDecisionDate: () => {},

  completedDate: undefined,
  setCompletedDate: () => {},

  staffIds: [],
  setStaffIds: () => {},

  clientIds: [],
  setClientIds: () => {},

  otherPeople: [],
  setOtherPeople: () => {},

  fileTypeId: undefined,
  setFileTypeId: () => {},

  fileSubTypeId: undefined,
  setFileSubTypeId: () => {},

  details: undefined,
  setDetails: () => {},

  iscNumber: undefined,
  setIscNumber: () => {},

  stageId: '',
  setStageId: () => {},

  requests: [] as IRequest[],
  setRequests: () => {},

  payments: [] as IPayment[],
  setPayments: () => {},

  notes: [] as INote[],
  setNotes: () => {},

  documentUploaded: false,
  setDocumentUploaded: () => {},

  reminders: [] as IReminder[],
  setReminders: () => {},

  fileName: undefined,
  setFileName: () => {},

  referrals: [] as IReferral[],
  setReferrals: () => {},

  referralsFromCyfn: [] as IReferral[],
  setReferralsFromCyfn: () => {},

  servicesProvided: [] as IServiceProvided[],
  setServicesProvided: () => {},

  initialSentencingPositionList: [] as ISentence[],
  setInitialSentencingPositionList: () => {},

  dispositionList: [] as ISentence[],
  setDispositionList: () => {},

  requestName: undefined,
  setRequestName: () => {},

  courtLocationId: undefined,
  setCourtLocationId: () => {},

  courtDatesList: [] as ICourtDate[],
  setCourtDatesList: () => {},

  appointments: [] as IAppointment[],
  setAppointments: () => {},

  isConfidential: undefined,
  setIsConfidential: () => {},

  reason: undefined,
  setReason: () => {},

  financialSupports: [] as IFinancialSupport[],
  setFinancialSupports: () => {},

  removeNonSuperAdmins: () => {},
});

export const FileProvider = (props: any) => {
  const [file, setFile] = useState({} as IFile);
  const [fileMeta, setFileMeta] = useState({} as IFileMeta);
  const [createdDate, setCreatedDate] = useState<Date | undefined>(new Date());
  const [submittedDate, setSubmittedDate] = useState<Date | undefined>(undefined);
  const [decisionDate, setDecisionDate] = useState<Date | undefined>(undefined);
  const [completedDate, setCompletedDate] = useState<Date | undefined>(undefined);

  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [otherPeople, setOtherPeople] = useState<IOtherPerson[]>([]);
  const [staffIds, setStaffIds] = useState([AuthenticationService.getUserId()] as string[]);
  const [fileTypeId, setFileTypeId] = useState<string | undefined>(undefined);
  const [fileSubTypeId, setFileSubTypeId] = useState<string | undefined>(undefined);
  const [iscNumber, setIscNumber] = useState<string | undefined>(undefined);
  const [details, setDetails] = useState<string | undefined>(undefined);
  const [stageId, setStageId] = useState<string>(STAGE_DRAFT);
  const [reason, setReason] = useState<string | undefined>(undefined);

  const [requests, setRequests] = useState<IRequest[]>([]);
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [notes, setNotes] = useState<INote[]>([]);
  const [documentUploaded, setDocumentUploaded] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [reminders, setReminders] = useState<IReminder[]>([]);
  const [referrals, setReferrals] = useState<IReferral[]>([]);
  const [referralsFromCyfn, setReferralsFromCyfn] = useState<IReferral[]>([]);
  const [servicesProvided, setServicesProvided] = useState<IServiceProvided[]>([]);
  const [initialSentencingPositionList, setInitialSentencingPositionList] = useState<ISentence[]>([]);
  const [dispositionList, setDispositionList] = useState<ISentence[]>([]);
  const [requestName, setRequestName] = useState<string | undefined>();
  const [wccConnectiveFields, setWCCConnectiveFields] = useState<IWCCConnectiveFields | undefined>(undefined);
  const [legalSystemNavigatorFields, setLegalSystemNavigatorFields] = useState<ILegalSystemNavigatorFields | undefined>(
    undefined
  );
  const [communityImpactFields, setCommunityImpactFields] = useState<ICommunityImpactFields | undefined>(undefined);
  const [gladueReportFields, setGladueReportFields] = useState<IGladueReportFields | undefined>(undefined);
  const [jordansPrincipleFields, setJordansPrincipleFields] = useState<IJordansPrincipleFields | undefined>(undefined);
  const [originalGladueReportFields, setOriginalGladueReportFields] = useState<IGladueReportFields | undefined>(
    undefined
  );
  const [courtLocationId, setCourtLocationId] = useState<string | undefined>(undefined);
  const [familyPreservationFields, setFamilyPreservationFields] = useState<IFamilyPreservationFields | undefined>(
    undefined
  );
  const [birthWorkerFields, setBirthWorkerFields] = useState<IBirthWorkersFields | undefined>(undefined);
  const [intakeFields, setIntakeFields] = useState<IIntakeFields | undefined>(undefined);
  const [emergencyContactInformationList, setEmergencyContactInformationList] = useState<
    IEmergencyContactInformation[]
  >([]);
  const [wellnessCentreFields, setWellnessCentreFields] = useState<IWellnessCentreFields | undefined>(undefined);
  const [courtDatesList, setCourtDatesList] = useState<ICourtDate[]>([]);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [isConfidential, setIsConfidential] = useState<boolean | undefined>(undefined);
  const [intakeStaffIds, setIntakeStaffIds] = useState<string[]>([]);
  const [intakeFileId, setIntakeFileId] = useState<string | undefined>('');
  const [financialSupports, setFinancialSupports] = useState<IFinancialSupport[]>([]);

  const setFileAndAttributes = (pFile: IFile) => {
    setFile(pFile);
    setClientIds(pFile.clientIds);
    setFileTypeId(pFile.fileTypeId);
    setFileSubTypeId(pFile.fileSubTypeId);
    setCreatedDate(pFile.createdDate ? new Date(pFile.createdDate) : undefined);
    setOtherPeople(pFile.otherPeople);
    setDetails(pFile.details);
    setStageId(pFile.stageId);
    setStaffIds(pFile.staffIds);
    setReferrals(pFile.referrals);
    setRequests(pFile.requests);
    setIsConfidential(pFile.isConfidential);
    setIntakeStaffIds(pFile.intakeStaffIds);
    setIntakeFileId(pFile.intakeFileId);
    const payments = pFile.payments?.sort((a: IPayment, b: IPayment) => {
      if (a.paymentDate && b.paymentDate) {
        return a.paymentDate - b.paymentDate;
      } else {
        if (a.paymentDate === 0) {
          return 1;
        }
        if (b.paymentDate === 0) {
          return -1;
        }
        return 0;
      }
    });
    setPayments(payments);
    setCourtLocationId(pFile.courtLocationId);

    if (pFile.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION && pFile.familyPreservationFields) {
      setCompletedDate(
        pFile.familyPreservationFields.completedDate
          ? new Date(pFile.familyPreservationFields.completedDate)
          : undefined
      );
      setServicesProvided(
        pFile.familyPreservationFields.servicesProvided ? pFile.familyPreservationFields.servicesProvided : []
      );
      setFamilyPreservationFields(pFile.familyPreservationFields);
    }

    if (pFile.fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS && pFile.culturalConnectionsFields) {
      setCompletedDate(
        pFile.culturalConnectionsFields.completedDate
          ? new Date(pFile.culturalConnectionsFields.completedDate)
          : undefined
      );
    }

    if (pFile.fileTypeId === FILE_TYPE_BIRTH_WORKER && pFile.birthWorkersFields) {
      setCompletedDate(
        pFile.birthWorkersFields.completedDate ? new Date(pFile.birthWorkersFields.completedDate) : undefined
      );

      setServicesProvided(pFile.birthWorkersFields.servicesProvided ? pFile.birthWorkersFields.servicesProvided : []);
      setReferralsFromCyfn(
        pFile.birthWorkersFields.referralsFromCyfn ? pFile.birthWorkersFields.referralsFromCyfn : []
      );
      setFinancialSupports(
        pFile.birthWorkersFields.financialSupports ? pFile.birthWorkersFields.financialSupports : []
      );
      setBirthWorkerFields(pFile.birthWorkersFields);
    }

    if (pFile.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && pFile.jordansPrincipleFields) {
      setFileName(pFile.jordansPrincipleFields.fileName ? pFile.jordansPrincipleFields.fileName : undefined);
      setIscNumber(pFile.jordansPrincipleFields.iscNumber ? pFile.jordansPrincipleFields.iscNumber : undefined);
      setSubmittedDate(
        pFile.jordansPrincipleFields.submittedDate ? new Date(pFile.jordansPrincipleFields.submittedDate) : undefined
      );
      setDecisionDate(
        pFile.jordansPrincipleFields.decisionDate ? new Date(pFile.jordansPrincipleFields.decisionDate) : undefined
      );
      setCompletedDate(
        pFile.jordansPrincipleFields.completedDate ? new Date(pFile.jordansPrincipleFields.completedDate) : undefined
      );
      setRequestName(pFile.jordansPrincipleFields.requestName ? pFile.jordansPrincipleFields.requestName : undefined);
      setJordansPrincipleFields(pFile.jordansPrincipleFields);
    }
    if (pFile.fileSubTypeId === FILE_SUB_TYPE_WCC_CONNECTIVE) {
      setWCCConnectiveFields(pFile.wccConnectiveFields);
    }
    if (pFile.fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR) {
      setCourtDatesList(
        pFile.legalSystemNavigatorFields?.courtDatesList ? pFile.legalSystemNavigatorFields.courtDatesList : []
      );
      setLegalSystemNavigatorFields(pFile.legalSystemNavigatorFields);
    }
    if (pFile.fileSubTypeId === FILE_SUB_TYPE_COMMUNITY_IMPACT) {
      setCommunityImpactFields(pFile.communityImpactFields);
    }
    if (pFile.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT) {
      setInitialSentencingPositionList(
        pFile.gladueReportFields?.initialSentencingPositionList
          ? pFile.gladueReportFields.initialSentencingPositionList
          : []
      );
      setDispositionList(pFile.gladueReportFields?.dispositionList ? pFile.gladueReportFields.dispositionList : []);
      setGladueReportFields(pFile.gladueReportFields);
      setOriginalGladueReportFields(pFile.gladueReportFields);
    }
    if (pFile.fileTypeId === FILE_TYPE_INTAKE && pFile.intakeFields) {
      setIntakeFields(pFile.intakeFields);
    }
    if (pFile.fileTypeId === FILE_TYPE_WELLNESS_CENTRE) {
      setWellnessCentreFields(pFile.wellnessCentreFields);
      setEmergencyContactInformationList(
        pFile.wellnessCentreFields ? pFile.wellnessCentreFields.emergencyContactInformationList : []
      );
    }

    setLoaded(true);
  };

  const setFileFromAttributes = () => {
    setFile((f) => ({
      fileId: f.fileId,
      fileNumber: f.fileNumber,
      clientIds: clientIds,
      otherPeople,
      stageId,
      fileTypeId: fileTypeId ? fileTypeId : '',
      fileSubTypeId: fileSubTypeId ? fileSubTypeId : '',
      fileTypeDisplay: f.fileTypeDisplay,
      createdDate: createdDate ? createdDate.getTime() : new Date().getTime(),
      details,
      staffIds,
      reminders,
      referrals,
      requests,
      payments,
      courtLocationId,
      familyPreservationFields:
        fileTypeId === FILE_TYPE_FAMILY_PRESERVATION
          ? {
              referrals,
              completedDate: completedDate ? completedDate.getTime() : undefined,
              financialAssistance: requests,
              servicesProvided: servicesProvided,
              ...familyPreservationFields,
            }
          : undefined,
      culturalConnectionsFields:
        fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS
          ? {
              completedDate: completedDate ? completedDate.getTime() : undefined,
            }
          : undefined,
      birthWorkersFields:
        fileTypeId === FILE_TYPE_BIRTH_WORKER
          ? {
              completedDate: completedDate ? completedDate.getTime() : undefined,
              servicesProvided: servicesProvided,
              requests,
              referrals,
              referralsFromCyfn: referralsFromCyfn,
              financialSupports: financialSupports,
            }
          : undefined,
      jordansPrincipleFields:
        fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE
          ? {
              iscNumber,
              requests,
              payments,
              fileName: fileName ? fileName : '',
              submittedDate: submittedDate ? submittedDate.getTime() : undefined,
              decisionDate: decisionDate ? decisionDate.getTime() : undefined,
              completedDate: completedDate ? completedDate.getTime() : undefined,
              fileSubTypeId,
              requestName,
              ...jordansPrincipleFields,
            }
          : undefined,
      wccConnectiveFields:
        fileSubTypeId === FILE_SUB_TYPE_WCC_CONNECTIVE ? { ...getWCCConnectiveData(f.fileId) } : undefined,
      communityImpactFields:
        fileSubTypeId === FILE_SUB_TYPE_COMMUNITY_IMPACT ? { ...getCommunityImpactData(f.fileId) } : undefined,
      originalGladueReportFields: originalGladueReportFields,
      gladueReportFields:
        fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT
          ? {
              ...getGladueReportData(f.fileId),
            }
          : undefined,
      legalSystemNavigatorFields:
        fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR
          ? { courtDatesList: courtDatesList, ...legalSystemNavigatorFields }
          : undefined,
      intakeFields: fileTypeId === FILE_TYPE_INTAKE ? { ...getIntakeData(f.fileId) } : undefined,
      appointments,
      intakeFileId,
      intakeStaffIds,
      financialSupports,
    }));
  };

  const getCreateData = (): IFilePost => {
    const data = {
      clientIds: clientIds,
      fileTypeId: fileTypeId,
      fileSubTypeId: fileSubTypeId,
      createdDate: createdDate ? createdDate.getTime() : undefined,
      details: details,
      stageId: stageId,
      staffIds: staffIds,
      isConfidential: isConfidential,
    };

    return data as IFilePost;
  };

  const getEditData = (): IFilePost => {
    return { fileId: file.fileId, ...getCreateData() };
  };

  const removeNonSuperAdmins = (selectedStaff: IUser[]) => {
    setStaffIds(selectedStaff.filter((s) => s.role === 'SuperAdmin').map((s) => s.userId));
  };

  const getFamilyPreservationData = (fileId: string): IFamilyPreservationData => {
    return {
      fileId: fileId,
      referrals: referrals,
      completedDate: completedDate ? completedDate.getTime() : undefined,
      financialAssistance: requests,
      servicesProvided: servicesProvided,
      initialReferralDate: familyPreservationFields?.initialReferralDate,
      numberOfKidsGrandkids: familyPreservationFields?.numberOfKidsGrandkids,
      fcsAssignedSocialWorker: familyPreservationFields?.fcsAssignedSocialWorker,
    };
  };

  const getCulturalConnectionsData = (fileId: string): ICulturalConnectionsData => {
    return {
      fileId: fileId,
      completedDate: completedDate ? completedDate.getTime() : undefined,
    };
  };

  const getBirthWorkersData = (fileId: string): IBirthWorkersData => {
    return {
      fileId: fileId,
      completedDate: completedDate ? completedDate.getTime() : undefined,
      servicesProvided: servicesProvided,
      requests: requests,
      referrals: referrals,
      financialSupports: financialSupports,
      referralsFromCyfn: referralsFromCyfn,
      prePostNatalId: birthWorkerFields?.prePostNatalId,
    };
  };

  const getJordansPrincipleData = (fileId: string): IJordansPrincipleData => {
    return {
      fileId: fileId,
      iscNumber: iscNumber,
      requests: requests,
      payments: payments,
      fileName: fileName ? fileName : '',
      submittedDate: submittedDate ? submittedDate.getTime() : undefined,
      decisionDate: decisionDate ? decisionDate.getTime() : undefined,
      completedDate: completedDate ? completedDate.getTime() : undefined,
      fileSubTypeId: fileSubTypeId ? fileSubTypeId : '',
      requestName: requestName,
      childrenWithComplexMedicalNeeds: jordansPrincipleFields?.childrenWithComplexMedicalNeeds,
      childrenWithComplexMedicalNeedsDescription: jordansPrincipleFields?.childrenWithComplexMedicalNeedsDescription,
      childrenLivingInPoverty: jordansPrincipleFields?.childrenLivingInPoverty,
      childrenLivingInPovertyDescription: jordansPrincipleFields?.childrenLivingInPovertyDescription,
      experiencedRecentEmergency: jordansPrincipleFields?.experiencedRecentEmergency,
      experiencedRecentEmergencyDescription: jordansPrincipleFields?.experiencedRecentEmergencyDescription,
      familyReunification: jordansPrincipleFields?.familyReunification,
      familyReunificationDescription: jordansPrincipleFields?.familyReunificationDescription,
      culturalExperiences: jordansPrincipleFields?.culturalExperiences,
      culturalExperiencesDescription: jordansPrincipleFields?.culturalExperiencesDescription,
      overallEligibility: jordansPrincipleFields?.overallEligibility,
      requestTypeId: jordansPrincipleFields?.requestTypeId,
      indigenousGroupId: jordansPrincipleFields?.indigenousGroupId,
      bandNation: jordansPrincipleFields?.bandNation,
    };
  };

  const getWCCConnectiveData = (fileId: string): IWCCConnectiveData => {
    return {
      fileId: fileId,
      referrals: referrals,
      charges: wccConnectiveFields?.charges ? wccConnectiveFields.charges : '',
      clientStatus: wccConnectiveFields?.clientStatus ? wccConnectiveFields.clientStatus : '',
      ...wccConnectiveFields,
    };
  };

  const getLegalSystemNavigatorData = (fileId: string): ILegalSystemNavigatorData => {
    return {
      fileId: fileId,
      referrals: referrals,
      charges: legalSystemNavigatorFields?.charges ? legalSystemNavigatorFields?.charges : undefined,
      offender: legalSystemNavigatorFields?.offender ? legalSystemNavigatorFields?.offender : undefined,
      courtDatesList: courtDatesList,
      indigenousGroupId: legalSystemNavigatorFields?.indigenousGroupId
        ? legalSystemNavigatorFields?.indigenousGroupId
        : undefined,
      bandNation: legalSystemNavigatorFields?.bandNation,
      legalSystemNavigatorTypeOfServiceId: legalSystemNavigatorFields?.legalSystemNavigatorTypeOfServiceId
        ? legalSystemNavigatorFields?.legalSystemNavigatorTypeOfServiceId
        : undefined,
      legalSystemNavigatorTypeOfServiceDetails: legalSystemNavigatorFields?.legalSystemNavigatorTypeOfServiceDetails
        ? legalSystemNavigatorFields?.legalSystemNavigatorTypeOfServiceDetails
        : undefined,
      lawyer: legalSystemNavigatorFields?.lawyer ? legalSystemNavigatorFields?.lawyer : undefined,
      lawyerContactDetails: legalSystemNavigatorFields?.lawyerContactDetails,
      complainant: legalSystemNavigatorFields?.complainant ? legalSystemNavigatorFields?.complainant : undefined,
    };
  };

  const getCommunityImpactData = (fileId: string): ICommunityImpactData => {
    return {
      fileId: fileId,
      referrals: referrals,
      communityName: '',
      communityContactName: '',
      communityContactNumber: '',
      communityContactEmail: '',
      offenderName: '',
      coOffenderName: '',
      offenderLawyer: '',
      offenderCharges: '',
      courtLocation: '',
      victim: '',
      offenderChargeIds: communityImpactFields?.offenderChargeIds ? communityImpactFields?.offenderChargeIds : [],
      courtLocationId: courtLocationId,
      ...communityImpactFields,
    };
  };
  const getGladueReportData = (fileId: string): IGladueReportData => {
    return {
      fileId: fileId,
      assignedWriter: gladueReportFields?.assignedWriter ? gladueReportFields.assignedWriter : '',
      mentor: gladueReportFields?.mentor ? gladueReportFields.mentor : '',
      mentee: gladueReportFields?.mentee ? gladueReportFields.mentee : '',
      requestedDate: gladueReportFields?.requestedDate,
      sentencingDate: gladueReportFields?.sentencingDate,
      defenceLawyer: gladueReportFields?.defenceLawyer ? gladueReportFields?.defenceLawyer : '',
      defenceLawyerContactDetails: gladueReportFields?.defenceLawyerContactDetails,
      crownLawyer: gladueReportFields?.crownLawyer ? gladueReportFields?.crownLawyer : '',
      crownLawyerContactDetails: gladueReportFields?.crownLawyerContactDetails,
      dispositionHistoric: gladueReportFields?.dispositionHistoric ? gladueReportFields?.dispositionHistoric : '',
      courtMatter: gladueReportFields?.courtMatter ? gladueReportFields?.courtMatter : '',
      courtLocationId: courtLocationId,
      indigenousGroupId: gladueReportFields?.indigenousGroupId ? gladueReportFields?.indigenousGroupId : undefined,
      bandNation: gladueReportFields?.bandNation,
      reportUpdated: gladueReportFields?.reportUpdated,
      communityImpact: gladueReportFields?.communityImpact,
      initialSentencingPosition: gladueReportFields?.initialSentencingPosition
        ? gladueReportFields?.initialSentencingPosition
        : undefined,
      initialSentencingPositionAmount: gladueReportFields?.initialSentencingPositionAmount,
      initialSentencingPositionMonths: gladueReportFields?.initialSentencingPositionMonths,
      initialSentencingPositionDays: gladueReportFields?.initialSentencingPositionDays,
      dispositionType: gladueReportFields?.dispositionType,
      dispositionAmount: gladueReportFields?.dispositionAmount,
      dispositionMonths: gladueReportFields?.dispositionMonths,
      dispositionDays: gladueReportFields?.dispositionDays,
      chargeIds: gladueReportFields ? gladueReportFields.chargeIds : [],
      initialSentencingPositionList: initialSentencingPositionList,
      dispositionList: dispositionList,
    };
  };

  const getIntakeData = (fileId: string): IIntakeData => {
    return {
      fileId: fileId,
      intakeDate: intakeFields?.intakeDate ? intakeFields.intakeDate : undefined,
      intakeDetails: intakeFields?.intakeDetails ? intakeFields.intakeDetails : '',
      requestFileIds: intakeFields ? intakeFields.requestFileIds : [],
      briefConcerns: intakeFields?.briefConcerns ? intakeFields.briefConcerns : '',
      custodyInformation: intakeFields?.custodyInformation ? intakeFields.custodyInformation : '',
      currentLivingSituation: intakeFields?.currentLivingSituation ? intakeFields.currentLivingSituation : '',
      experiencingOngoingPovertyOrHardship: intakeFields?.experiencingOngoingPovertyOrHardship
        ? intakeFields.experiencingOngoingPovertyOrHardship
        : false,
      experiencingOngoingPovertyOrHardshipDetails: intakeFields?.experiencingOngoingPovertyOrHardshipDetails
        ? intakeFields.experiencingOngoingPovertyOrHardshipDetails
        : '',
      experiencingViolence: intakeFields?.experiencingViolence ? intakeFields?.experiencingViolence : false,
      experiencingViolenceDetails: intakeFields?.experiencingViolenceDetails
        ? intakeFields?.experiencingViolenceDetails
        : '',
      identifiableGoals: intakeFields?.identifiableGoals ? intakeFields?.identifiableGoals : '',
      nextSteps: intakeFields?.nextSteps ? intakeFields?.nextSteps : '',
      fcsInvolvement: intakeFields?.fcsInvolvement ? intakeFields?.fcsInvolvement : false,
      efca: intakeFields?.efca ? intakeFields?.efca : false,
      complexMedicalNeeds: intakeFields?.complexMedicalNeeds ? intakeFields?.complexMedicalNeeds : false,
      complexMedicalNeedsDetails: intakeFields?.complexMedicalNeedsDetails
        ? intakeFields?.complexMedicalNeedsDetails
        : '',
      emergencies: intakeFields?.emergencies ? intakeFields?.emergencies : false,
      emergenciesDetails: intakeFields?.emergenciesDetails ? intakeFields?.emergenciesDetails : '',
      familyConnectionOrReunification: intakeFields?.familyConnectionOrReunification
        ? intakeFields?.familyConnectionOrReunification
        : false,
      familyConnectionOrReunificationDetails: intakeFields?.familyConnectionOrReunificationDetails
        ? intakeFields?.familyConnectionOrReunificationDetails
        : '',
      culturalConnections: intakeFields?.culturalConnections ? intakeFields?.culturalConnections : false,
      culturalConnectionsDetails: intakeFields?.culturalConnectionsDetails
        ? intakeFields?.culturalConnectionsDetails
        : '',
      incomeOrEmployments: intakeFields?.incomeOrEmployments ? intakeFields?.incomeOrEmployments : [],
      resourceSupports: intakeFields?.resourceSupports ? intakeFields?.resourceSupports : [],
    };
  };

  const getWellnessCentreData = (fileId: string): IWellnessCentreData => {
    return {
      fileId: fileId,
      emergencyContactInformationList: emergencyContactInformationList,
      personsOfConcern: wellnessCentreFields?.personsOfConcern,
      immediateSafetyRisks: wellnessCentreFields?.immediateSafetyRisks,
      emergencyNeedsOrFactors: wellnessCentreFields?.emergencyNeedsOrFactors,
      currentHealthConcerns: wellnessCentreFields?.currentHealthConcerns,
      allergies: wellnessCentreFields?.allergies,
      dietaryRestrictions: wellnessCentreFields?.dietaryRestrictions,
      immediateThingsToAttendTo: wellnessCentreFields?.immediateThingsToAttendTo,
      childrenInformation: wellnessCentreFields?.childrenInformation,
      additionalInformation: wellnessCentreFields?.additionalInformation,
      wellnessHouseUnitIds: wellnessCentreFields?.wellnessHouseUnitIds
        ? wellnessCentreFields?.wellnessHouseUnitIds
        : [],
    };
  };

  return (
    <FileContext.Provider
      value={{
        file,
        setFile,
        loaded,
        setLoaded,
        fileMeta,
        setFileMeta,
        setFileAndAttributes,
        setFileFromAttributes,
        getCreateData,
        getEditData,
        getFamilyPreservationData,
        getCulturalConnectionsData,
        getBirthWorkersData,
        getJordansPrincipleData,
        getWCCConnectiveData,
        getCommunityImpactData,
        getGladueReportData,
        getLegalSystemNavigatorData,
        getIntakeData,
        getWellnessCentreData,

        setFamilyPreservationFields,
        familyPreservationFields,

        setBirthWorkerFields,
        birthWorkerFields,

        wccConnectiveFields,
        setWCCConnectiveFields,

        legalSystemNavigatorFields,
        setLegalSystemNavigatorFields,

        communityImpactFields,
        setCommunityImpactFields,
        gladueReportFields,
        setGladueReportFields,

        jordansPrincipleFields,
        setJordansPrincipleFields,

        originalGladueReportFields,
        setOriginalGladueReportFields,

        intakeFields,
        setIntakeFields,

        wellnessCentreFields,
        setWellnessCentreFields,

        emergencyContactInformationList,
        setEmergencyContactInformationList,

        createdDate,
        setCreatedDate,

        submittedDate,
        setSubmittedDate,

        decisionDate,
        setDecisionDate,

        completedDate,
        setCompletedDate,

        clientIds,
        setClientIds,

        otherPeople,
        setOtherPeople,

        fileTypeId,
        setFileTypeId,

        fileSubTypeId,
        setFileSubTypeId,

        iscNumber,
        setIscNumber,

        details,
        setDetails,

        stageId,
        setStageId,

        requests,
        setRequests,

        payments,
        setPayments,

        notes,
        setNotes,

        staffIds,
        setStaffIds,

        documentUploaded,
        setDocumentUploaded,

        reminders,
        setReminders,

        fileName,
        setFileName,

        referrals,
        setReferrals,

        referralsFromCyfn,
        setReferralsFromCyfn,

        servicesProvided,
        setServicesProvided,

        initialSentencingPositionList,
        setInitialSentencingPositionList,

        dispositionList,
        setDispositionList,

        requestName,
        setRequestName,

        courtLocationId,
        setCourtLocationId,

        courtDatesList,
        setCourtDatesList,

        appointments,
        setAppointments,

        isConfidential,
        setIsConfidential,

        reason,
        setReason,

        financialSupports,
        setFinancialSupports,

        removeNonSuperAdmins,
      }}
    >
      {props.children}
    </FileContext.Provider>
  );
};

export const FileConsumer = FileContext.Consumer;
