import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import * as ToastService from '../../services/ToastService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { useHistory, useParams } from 'react-router';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { IEventWorkshop, IEventParticipant, IWorkshop } from './WorkshopsTable';
import {
  DEPARTMENT_BIRTH_WORKER,
  DEPARTMENT_CULTURAL_CONNECTIONS,
  DEPARTMENT_FAMILY_PRESERVATION,
  DomainContext,
  IDepartment,
  IStatus,
  ITheme,
  IWorkshopLocation,
  WORKSHOP_LOCATION_ID_OTHER,
  WORKSHOP_REGISTRATION_STATUS_REGISTERED,
  WORKSHOP_STATUS_UPCOMING,
} from '../../contexts/DomainContext';
import Select from 'react-select';
import { FaPlus } from 'react-icons/fa';
import { EventDetails } from './EventDetails';
import { MyUserContext } from '../../contexts/MyUserContext';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { disabledSelectStylesWithMenu } from '../../utils/StylingUtils';

export const WorkshopDetails: React.FC = () => {
  const params = useParams<{ workshopId: string }>();
  const domainContext = useContext(DomainContext);
  const myUserContext = useContext(MyUserContext);
  const departments = domainContext.departments;
  const statuses = domainContext.statuses;
  const workshopLocations = domainContext.workshopLocations;
  const workshopDepartments = departments.filter((d) =>
    [DEPARTMENT_BIRTH_WORKER, DEPARTMENT_FAMILY_PRESERVATION, DEPARTMENT_CULTURAL_CONNECTIONS].includes(d.departmentId)
  );
  const themes = domainContext.themes;
  const history = useHistory();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [themeIds, setThemeIds] = useState<string[]>([]);
  const [participantIds, setParticipantIds] = useState<string[]>([]);
  const [workshopEvents, setWorkshopEvents] = useState<IEventWorkshop[]>([]);
  const [status, setStatus] = useState(WORKSHOP_STATUS_UPCOMING);
  const [capacity, setCapacity] = useState('');
  const [workshopLocationId, setWorkshopLocationId] = useState('');
  const [workshopLocation, setWorkshopLocation] = useState('');

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [addingNewEventWorkshop, setAddingNewEventWorkshop] = useState(0);
  const [openIndex, setOpenIndex] = useState<number>(-1);
  const [openDeleteWorkshopModal, setOpenDeleteWorkshopModal] = useState(false);
  const [openEventsModal, setOpenEventsModal] = useState(false);

  const deleteWorkshop = () => {
    if (params.workshopId) {
      authAxios.get('/api/workshops/' + params.workshopId).then((response) => {
        if (response.data.participants.length === 0 && response.data.workshopEvents.length === 0) {
          authAxios.post('/api/workshops/delete-workshop/' + params.workshopId).then((response) => {
            if (response.data.status === 'OK') {
              notifySuccess('Workshop has been deleted.');
              history.push({ pathname: '/workshops', state: { deletedWorkshopId: params.workshopId } });
            } else {
              notifyError(response.data.status);
            }
          });
        } else {
          setOpenEventsModal(true);
        }
      });
    }
  };

  useEffect(() => {
    if (params.workshopId) {
      authAxios
        .get('/api/workshops/' + params.workshopId)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else if (response.data) {
            setName(response.data.name);
            setDescription(response.data.description);
            setDepartmentId(response.data.departmentId);
            setThemeIds(response.data.themeIds);
            setParticipantIds(
              response.data.participants
                .filter((p: any) => p.registrationStatusId === WORKSHOP_REGISTRATION_STATUS_REGISTERED)
                .map((p: any) => p.participantId)
            );
            setWorkshopEvents(
              response.data.workshopEvents.sort((a: IEventWorkshop, b: IEventWorkshop) => {
                return a.startDate - b.startDate;
              })
            );
            setStatus(response.data.status);
            setCapacity(response.data.capacity);
            setWorkshopLocationId(response.data.workshopLocationId);
            setWorkshopLocation(response.data.workshopLocation);
          } else {
            history.push('/404');
          }
        })
        .catch(() => {
          history.push('/404');
        });
    }
  }, [history, params.workshopId]);

  useEffect(() => {
    if (addingNewEventWorkshop > 0) {
      var elmnt = document.getElementById('new-event-workshop-add');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
      }
      setOpenIndex(workshopEvents.length - 1);
    }
  }, [addingNewEventWorkshop, workshopEvents.length]);

  const formFullyFilled = () => {
    return name !== '' && !workshopEvents.find((e) => e.startDate === undefined) && departmentId !== '';
  };

  const saveWorkshopDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
      return;
    }
    if (params.workshopId) {
      authAxios
        .post('/api/workshops/update', {
          workshopId: params.workshopId,
          name: name,
          description: description,
          departmentId: departmentId,
          themeIds: themeIds,
          status: status,
          capacity: capacity,
          workshopLocationId: workshopLocationId,
          workshopLocation: workshopLocation,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            authAxios
              .post('/api/workshops/update-events', {
                workshopId: params.workshopId,
                workshopEvents: workshopEvents,
              } as IWorkshop)
              .then((response) => {
                if (response.data.status === 'OK') {
                  ToastService.notifySuccess('Workshop Updated');
                  setIsDirty(false);
                  setOpenIndex(-1);
                  setWorkshopEvents(response.data.updatedEvents);
                } else {
                  ToastService.notifyError(response.data.errorMessage);
                }
              });
          } else {
            ToastService.notifyError(response.data.errorMessage);
          }
        })
        .catch((error) => {
          ToastService.notifyError('Error:' + error);
        });
    } else {
      authAxios
        .post('/api/workshops/create', {
          name,
          description,
          departmentId,
          themeIds,
          workshopEvents,
          status,
          capacity,
          workshopLocationId,
          workshopLocation,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Workshop Created');
            setIsDirty(false);
            setOpenIndex(-1);
            history.push({ pathname: '/workshops/' + response.data.workshopId });
          } else {
            ToastService.notifyError('Error Creating Workshop - ' + response.data.desc);
          }
        });
    }
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'}>
          <NavbarBrand>{params.workshopId ? 'Edit Workshop' : 'Add New Workshop'}</NavbarBrand>

          <Col className={'d-flex'}>
            {!params.workshopId ? (
              <>
                <Button color={'link'} className={'ml-auto'} onClick={() => history.push('/workshops')}>
                  Cancel
                </Button>

                <Button
                  color={'primary'}
                  className={'ml-2'}
                  onClick={() => {
                    saveWorkshopDetails();
                  }}
                >
                  Save Details
                </Button>
              </>
            ) : (
              <div className={'d-flex ml-auto navbar-actions'}>
                {myUserContext.isSuperAdmin && (
                  <Button
                    color='danger'
                    className={'mr-2'}
                    onClick={() => {
                      if (!isDirty) {
                        setOpenDeleteWorkshopModal(true);
                      } else {
                        history.push('/workshops');
                      }
                    }}
                  >
                    Delete Workshop
                  </Button>
                )}
                <Button
                  color={'primary'}
                  className={'ml-auto'}
                  onClick={() => {
                    saveWorkshopDetails();
                  }}
                >
                  Save Details
                </Button>
              </div>
            )}
          </Col>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row className={'mb-3'}>
            <Col>
              <Row className='pt-3'>
                <Col>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          Name <span className='text-required'>(required)</span>
                        </Label>
                        <Input
                          type='text'
                          placeholder=''
                          name='name'
                          value={name}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setName(e.target.value);
                            setIsDirty(true);
                          }}
                          invalid={trySubmit && name === ''}
                        />
                        <FormFeedback>This field is mandatory</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for={'departmentId'}>
                          Department <span className='text-required'> (required)</span>
                        </Label>
                        <Select
                          styles={disabledSelectStylesWithMenu}
                          name={'departmentId'}
                          options={workshopDepartments.filter((o: IDepartment) => o.active)}
                          placeholder={<span className='placeholder'>Select...</span>}
                          value={workshopDepartments.find((o: IDepartment) => departmentId === o.departmentId)}
                          onChange={(value: any) => {
                            setDepartmentId(value.departmentId);
                            setIsDirty(true);
                          }}
                          getOptionValue={(option: IDepartment) => option.departmentId}
                          getOptionLabel={(option: IDepartment) => option.name}
                          isClearable={true}
                        />
                        {trySubmit && departmentId === '' && (
                          <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for={'status'}>Status</Label>
                        <Select
                          styles={disabledSelectStylesWithMenu}
                          name={'status'}
                          options={statuses}
                          placeholder={<span className='placeholder'>Select...</span>}
                          value={statuses.find((s: IStatus) => status === s.statusId)}
                          onChange={(value: any) => {
                            setStatus(value.statusId);
                            setIsDirty(true);
                          }}
                          getOptionValue={(option: IStatus) => option.statusId}
                          getOptionLabel={(option: IStatus) => option.name}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Capacity</Label>
                        <Input
                          type='number'
                          placeholder=''
                          name='capacity'
                          value={capacity}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCapacity(e.target.value);
                            setIsDirty(true);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for={'workshopLocationId'}>Location</Label>
                        <Select
                          styles={disabledSelectStylesWithMenu}
                          name={'workshopLocationId'}
                          options={workshopLocations}
                          placeholder={<span className='placeholder'>Select...</span>}
                          value={workshopLocations.find(
                            (l: IWorkshopLocation) => workshopLocationId === l.workshopLocationId
                          )}
                          onChange={(value: any) => {
                            setWorkshopLocationId(value.workshopLocationId);
                            setWorkshopLocation('');
                            setIsDirty(true);
                          }}
                          getOptionValue={(option: IWorkshopLocation) => option.workshopLocationId}
                          getOptionLabel={(option: IWorkshopLocation) => option.name}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      {workshopLocationId === WORKSHOP_LOCATION_ID_OTHER && (
                        <FormGroup>
                          <Label>Workshop Location</Label>
                          <Input
                            type='text'
                            placeholder='Please specify the location'
                            name='workshopLocation'
                            value={workshopLocation}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setWorkshopLocation(e.target.value);
                              setIsDirty(true);
                            }}
                            invalid={trySubmit && name === ''}
                          />
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for={'theme'}>Theme</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      className={'mb-2'}
                      name={'themeIds'}
                      placeholder={<span className='placeholder'>Select Theme to Add...</span>}
                      options={themes}
                      onChange={(value: any) => {
                        setThemeIds(value.map((t: ITheme) => t.themeId));
                        setIsDirty(true);
                      }}
                      value={themes.filter((t) => themeIds.includes(t.themeId))}
                      hideSelectedOptions={true}
                      getOptionValue={(option: ITheme) => (option.themeId ? option.themeId : '')}
                      formatOptionLabel={(option: ITheme) => <span>{option.name}</span>}
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Description / Notes</Label>
                    <Input
                      type='textarea'
                      placeholder=''
                      name='description'
                      style={{ minHeight: 100 }}
                      value={description}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setDescription(e.target.value);
                        setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col sm={12} md={6}>
                  <Card>
                    <CardHeader>Events</CardHeader>
                    <CardBody className={'p-0'}>
                      <ListGroup flush={true}>
                        {workshopEvents.map((t, index) => {
                          if (t !== undefined) {
                            return (
                              <ListGroupItem key={t.eventWorkshopId}>
                                <EventDetails
                                  eventWorkshopId={t.eventWorkshopId}
                                  eventStartDate={t.startDate}
                                  eventEndDate={t.endDate}
                                  setIsDirty={setIsDirty}
                                  index={index}
                                  setWorkshopEvents={setWorkshopEvents}
                                  openIndex={openIndex}
                                  setOpenIndex={setOpenIndex}
                                  workshopParticipants={participantIds}
                                  attendanceTaken={t.attendanceTaken}
                                  participants={t.participants}
                                />
                              </ListGroupItem>
                            );
                          }
                          return null;
                        })}
                        <ListGroupItem
                          id={'add-new-event-workshop'}
                          onClick={() => {
                            setWorkshopEvents((ew) =>
                              ew.concat([
                                {
                                  startDate: new Date().getTime(),
                                  endDate: undefined,
                                  attendanceTaken: false,
                                  participants: participantIds.map((pid) => {
                                    return { participantId: pid, attendanceId: undefined } as IEventParticipant;
                                  }),
                                },
                              ])
                            );
                            setAddingNewEventWorkshop((s) => s + 1);
                            setIsDirty(true);
                          }}
                          tag={'button'}
                        >
                          <FaPlus /> Add Event
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </form>
      <Modal
        isOpen={openDeleteWorkshopModal}
        toggle={() => setOpenDeleteWorkshopModal(!openDeleteWorkshopModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setOpenDeleteWorkshopModal(!openDeleteWorkshopModal)}>Delete Workshop</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>Are you sure you want to delete this workshop?</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              deleteWorkshop();
              setOpenDeleteWorkshopModal(false);
            }}
          >
            Delete Workshop
          </Button>
          <Button color={'light'} onClick={() => setOpenDeleteWorkshopModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openEventsModal} toggle={() => setOpenEventsModal(!openEventsModal)} className='logout-modal'>
        <ModalHeader toggle={() => setOpenEventsModal(!openEventsModal)}>Unable to Delete Workshop</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            There are events and/or participants associated with this workshop and it cannot be deleted.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              setOpenEventsModal(false);
            }}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
