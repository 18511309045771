import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Button, ButtonGroup, CustomInput, Navbar, NavbarBrand } from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import { TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import {
  DEPARTMENT_BIRTH_WORKER,
  DEPARTMENT_CULTURAL_CONNECTIONS,
  DEPARTMENT_FAMILY_PRESERVATION,
  DEPARTMENT_INTAKE,
  DEPARTMENT_JORDANS_PRINCIPLE,
  DEPARTMENT_JUSTICE,
  DEPARTMENT_WELLNESS_CENTRE,
  DomainContext,
  FILE_SUB_TYPE_GROUP,
  FILE_TYPE_BIRTH_WORKER,
  FILE_TYPE_CULTURAL_CONNECTIONS,
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_INTAKE,
  FILE_TYPE_JORDANS_PRINCIPLE,
  FILE_TYPE_JUSTICE,
  FILE_TYPE_WELLNESS_CENTRE,
  NOTIFICATION_TYPE_ON_SCREEN,
  STAGE_INTAKE_IN_PROGRESS,
} from '../../contexts/DomainContext';
import { IFileForTable } from '../Files/FileContext';
import { useDefaultColumn } from '../../hooks/ReactTableHooks';
import { TablePagination } from '../TablePagination/TablePagination';
import { getUserId } from '../../services/AuthenticationService';
import { FilesTableContext } from './FilesTableContext';
import { PersistantTableGlobalFilter } from '../TableGlobalFilter/PersistantTableGlobalFilter';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { MyUserContext } from '../../contexts/MyUserContext';
import { useCulturalConnectionsColumns } from './useCulturalConnectionsColumns';
import { useBirthWorkerColumns } from './useBirthWorkerColumns';
import { useFamilyPreservationColumns } from './useFamilyPreservationColumns';
import { useJordansPrincipleColumns } from './useJordansPrincipleColumns';
import { useJusticeColumns } from './useJusticeColumns';
import { useIntakeColumns } from '../../components/FilesTable/useIntakeColumns';
import { IReminder } from '../../components/Files/FileReminders';
import { useWellnessCentreColumns } from '../../components/FilesTable/useWellnessCentreColumns';

interface IProps {
  selectRow?: (userId: string) => void;
}

export const FilesTable: React.FC<IProps> = (props) => {
  const [files, setFiles] = useState<IFileForTable[]>([]);
  const myUserContext = useContext(MyUserContext);
  const domainContext = useContext(DomainContext);
  const stages = domainContext.stages;
  const history = useHistory();
  const location = useLocation<{ deletedFileId?: string }>();
  const searchContext = useContext(FilesTableContext);
  const selectedDepartmentType = searchContext.selectedDepartmentType;
  const setSelectedDepartmentType = searchContext.setSelectedDepartmentType;
  const { xs } = useWindowDimensions();
  const [justiceStaff, setJusticeStaff] = useState<string[]>([]);
  const [birthWorkerStaff, setBirthWorkerStaff] = useState<string[]>([]);
  const [culturalConnectionsStaff, setCulturalConnectionsStaff] = useState<string[]>([]);
  const [familyPreservationStaff, setFamilyPreservationStaff] = useState<string[]>([]);
  const [jordansPrincipleStaff, setJordansPrincipleStaff] = useState<string[]>([]);
  const [intakeStaff, setIntakeStaff] = useState<string[]>([]);
  const [wellnessCentreStaff, setWellnessCentreStaff] = useState<string[]>([]);
  const [activeReminders, setActiveReminders] = useState<IReminder[]>([]);
  const [showConfidentialForSuperadmin, setShowConfidentialForSuperadmin] = useState<boolean>(true);

  useEffect(() => {
    const deletedFileId = location?.state?.deletedFileId;

    authAxios.get('/api/files/admin').then((response) => {
      setFiles(response.data.filter((f: any) => f.fileId !== deletedFileId));
      let reminders: IReminder[] = [];
      response.data.forEach((f: any) => {
        if (f.fileTypeId === FILE_TYPE_INTAKE) {
          reminders = reminders.concat(
            f.reminders.filter((r: IReminder) =>
              r.date
                ? r.date <= new Date().getTime() &&
                  !r.resolved &&
                  r.staffToNotify.includes(getUserId()) &&
                  r.notificationTypeIds.includes(NOTIFICATION_TYPE_ON_SCREEN)
                : false
            )
          );
        }
      });
      setActiveReminders(reminders);
    });
  }, [stages]);

  const hasActiveReminder = (f: IFileForTable) => {
    if (f.reminders && f.reminders.length > 0) {
      const activeReminders = f.reminders.filter((r) => r.staffToNotify.includes(getUserId()));
      return activeReminders.length > 0;
    } else {
      return false;
    }
  };

  const resolveReminder = (fileId: string | undefined, reminderId: string) => {
    if (fileId) {
      authAxios.post('/api/files/resolve-reminder', { fileId: fileId, reminderId: reminderId }).then((response) => {
        if (response.data.status === 'OK') {
          setActiveReminders((r) => r.filter((reminder) => reminder.reminderId !== reminderId));
        }
      });
    }
  };

  const data: IFileForTable[] = useMemo(() => {
    const filteredFiles = files.filter((f) => !f.isConfidential || showConfidentialForSuperadmin);
    if (selectedDepartmentType === DEPARTMENT_BIRTH_WORKER) {
      return filteredFiles.filter((f) => f.fileTypeId === FILE_TYPE_BIRTH_WORKER);
    } else if (selectedDepartmentType === DEPARTMENT_CULTURAL_CONNECTIONS) {
      return filteredFiles.filter((f) => f.fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS);
    } else if (selectedDepartmentType === DEPARTMENT_FAMILY_PRESERVATION) {
      return filteredFiles.filter((f) => f.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION);
    } else if (selectedDepartmentType === DEPARTMENT_JORDANS_PRINCIPLE) {
      return filteredFiles.filter((f) => f.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE);
    } else if (selectedDepartmentType === DEPARTMENT_JUSTICE) {
      return filteredFiles.filter((f) => f.fileTypeId === FILE_TYPE_JUSTICE);
    } else if (selectedDepartmentType === DEPARTMENT_INTAKE) {
      return filteredFiles.filter((f) => f.fileTypeId === FILE_TYPE_INTAKE);
    } else if (selectedDepartmentType === DEPARTMENT_WELLNESS_CENTRE) {
      return filteredFiles.filter((f) => f.fileTypeId === FILE_TYPE_WELLNESS_CENTRE);
    } else {
      return filteredFiles;
    }
  }, [files, selectedDepartmentType, showConfidentialForSuperadmin]);

  useEffect(() => {
    setJusticeStaff(
      data
        .filter((f) => f.fileTypeId === FILE_TYPE_JUSTICE)
        .flatMap((item) => item.staffIds)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setBirthWorkerStaff(
      data
        .filter((f) => f.fileTypeId === FILE_TYPE_BIRTH_WORKER)
        .flatMap((item) => item.staffIds)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setCulturalConnectionsStaff(
      data
        .filter((f) => f.fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS)
        .flatMap((item) => item.staffIds)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setFamilyPreservationStaff(
      data
        .filter((f) => f.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION)
        .flatMap((item) => item.staffIds)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setJordansPrincipleStaff(
      data
        .filter((f) => f.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE)
        .flatMap((item) => item.staffIds)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setIntakeStaff(
      data
        .filter((f) => f.fileTypeId === FILE_TYPE_INTAKE)
        .flatMap((item) => item.staffIds)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setWellnessCentreStaff(
      data
        .filter((f) => f.fileTypeId === FILE_TYPE_WELLNESS_CENTRE)
        .flatMap((item) => item.staffIds)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
  }, [data]);

  const birthWorkersColumns = useBirthWorkerColumns(birthWorkerStaff);
  const culturalConnectionsColumns = useCulturalConnectionsColumns(culturalConnectionsStaff);
  const familyPreservationColumns = useFamilyPreservationColumns(familyPreservationStaff);
  const jordansPrincipleColumns = useJordansPrincipleColumns(jordansPrincipleStaff);
  const justiceColumns = useJusticeColumns(justiceStaff);
  const intakeColumns = useIntakeColumns(intakeStaff);
  const wellnessCentreColumns = useWellnessCentreColumns(wellnessCentreStaff);

  const defaultColumn = useDefaultColumn();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, globalFilter },
    setAllFilters,
  }: TableInstance<IFileForTable> = useTable(
    {
      columns:
        selectedDepartmentType === DEPARTMENT_BIRTH_WORKER
          ? birthWorkersColumns
          : selectedDepartmentType === DEPARTMENT_CULTURAL_CONNECTIONS
          ? culturalConnectionsColumns
          : selectedDepartmentType === DEPARTMENT_FAMILY_PRESERVATION
          ? familyPreservationColumns
          : selectedDepartmentType === DEPARTMENT_JORDANS_PRINCIPLE
          ? jordansPrincipleColumns
          : selectedDepartmentType === DEPARTMENT_INTAKE
          ? intakeColumns
          : selectedDepartmentType === DEPARTMENT_WELLNESS_CENTRE
          ? wellnessCentreColumns
          : justiceColumns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 20,
        filters: searchContext.filters,
        globalFilter: searchContext.selectedGlobalSearch,
        hiddenColumns: ['reminders'],
        sortBy: [
          {
            id: 'reminders',
            desc: false,
          },
          {
            id: 'fileNumber',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    searchContext.setFilters(filters);
  }, [filters]);

  const selectRow = (fileId: string) => {
    if (props.selectRow) {
      props.selectRow(fileId);
    } else {
      history.push('/files/' + fileId);
    }
  };

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <NavbarBrand className='mr-4'>Files</NavbarBrand>
        <ButtonGroup className={'mr-auto file-list-btn-group'}>
          {myUserContext.departmentAccessIds.includes(DEPARTMENT_INTAKE) && (
            <Button
              onClick={() => setSelectedDepartmentType(DEPARTMENT_INTAKE)}
              className={
                selectedDepartmentType === DEPARTMENT_INTAKE ? 'selected-department' : 'not-selected-department'
              }
            >
              Intake
            </Button>
          )}
          {myUserContext.departmentAccessIds.includes(DEPARTMENT_BIRTH_WORKER) && (
            <Button
              onClick={() => setSelectedDepartmentType(DEPARTMENT_BIRTH_WORKER)}
              className={
                selectedDepartmentType === DEPARTMENT_BIRTH_WORKER ? 'selected-department' : 'not-selected-department'
              }
            >
              Birth Worker
            </Button>
          )}
          {myUserContext.departmentAccessIds.includes(DEPARTMENT_CULTURAL_CONNECTIONS) && (
            <Button
              onClick={() => setSelectedDepartmentType(DEPARTMENT_CULTURAL_CONNECTIONS)}
              className={
                selectedDepartmentType === DEPARTMENT_CULTURAL_CONNECTIONS
                  ? 'selected-department'
                  : 'not-selected-department'
              }
            >
              Cultural Connections
            </Button>
          )}
          {myUserContext.departmentAccessIds.includes(DEPARTMENT_FAMILY_PRESERVATION) && (
            <Button
              onClick={() => setSelectedDepartmentType(DEPARTMENT_FAMILY_PRESERVATION)}
              className={
                selectedDepartmentType === DEPARTMENT_FAMILY_PRESERVATION
                  ? 'selected-department'
                  : 'not-selected-department'
              }
            >
              Client Services
            </Button>
          )}
          {myUserContext.departmentAccessIds.includes(DEPARTMENT_JORDANS_PRINCIPLE) && (
            <Button
              onClick={() => setSelectedDepartmentType(DEPARTMENT_JORDANS_PRINCIPLE)}
              className={
                selectedDepartmentType === DEPARTMENT_JORDANS_PRINCIPLE
                  ? 'selected-department'
                  : 'not-selected-department'
              }
            >
              Jordan&apos;s Principle
            </Button>
          )}
          {myUserContext.departmentAccessIds.includes(DEPARTMENT_WELLNESS_CENTRE) && (
            <Button
              onClick={() => setSelectedDepartmentType(DEPARTMENT_WELLNESS_CENTRE)}
              className={
                selectedDepartmentType === DEPARTMENT_WELLNESS_CENTRE
                  ? 'selected-department'
                  : 'not-selected-department'
              }
            >
              Wellness Centre
            </Button>
          )}
          {myUserContext.departmentAccessIds.includes(DEPARTMENT_JUSTICE) && (
            <Button
              onClick={() => setSelectedDepartmentType(DEPARTMENT_JUSTICE)}
              className={
                selectedDepartmentType === DEPARTMENT_JUSTICE ? 'selected-department' : 'not-selected-department'
              }
            >
              Justice
            </Button>
          )}
        </ButtonGroup>
        {myUserContext.isSuperAdmin && (
          <CustomInput
            type='checkbox'
            id={'showConfidential'}
            name={'showConfidential'}
            label={'Show Confidential'}
            checked={showConfidentialForSuperadmin}
            onChange={() => setShowConfidentialForSuperadmin((s) => !s)}
          />
        )}
        <Button
          className={'ml-2 mr-2'}
          color={'primary'}
          outline={true}
          onClick={() => {
            searchContext.setSelectedGlobalSearch('');
            setGlobalFilter('');
            setAllFilters([]);
          }}
        >
          Clear Filters
        </Button>
        {myUserContext.departmentAccessIds.includes(DEPARTMENT_JORDANS_PRINCIPLE) && (
          <Button
            color={'primary'}
            className={'ml-2'}
            tag={Link}
            to={{
              pathname: '/files/add',
              state: { fileTypeId: FILE_TYPE_JORDANS_PRINCIPLE, fileSubTypeId: FILE_SUB_TYPE_GROUP },
            }}
          >
            <FaPlus className='mr-2' />
            Jordans Principle Group File
          </Button>
        )}
      </Navbar>

      <div className={'mr-2 ml-2'}>
        {activeReminders.map((r) => (
          <Alert className={'mb-2'} color='warning' isOpen={true} key={r.reminderId}>
            <div className={'d-flex'}>
              REMINDER: {r.text}
              {r.fileId && (
                <>
                  <Button className={'ml-auto p-0'} color={'link'} onClick={() => history.push('/files/' + r.fileId)}>
                    View Intake File
                  </Button>
                  <Button className={'ml-4 p-0'} color={'link'} onClick={() => resolveReminder(r.fileId, r.reminderId)}>
                    Resolve
                  </Button>
                </>
              )}
            </div>
          </Alert>
        ))}
      </div>

      <div className={'table-responsive'} style={{ minHeight: '500px' }}>
        <table className={'table table-bordered table-hover'} {...getTableProps()}>
          <thead style={{ overflow: 'visible' }}>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={`files-table-thead-tr-${index}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    (!xs || (xs && column.xs)) && (
                      <th
                        key={`files-table-thead-tr-${index}-${column.id}`}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          width: column.width,
                          ...column.getHeaderProps(column.getSortByToggleProps()).style,
                        }}
                      >
                        {column.render('Header')}
                        <span>{column.isSorted && (column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp />)}</span>
                        <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    )
                  );
                })}
              </tr>
            ))}
            <tr>
              <PersistantTableGlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                colSpan={visibleColumns.length}
                value={searchContext.selectedGlobalSearch}
                setValue={searchContext.setSelectedGlobalSearch}
                label={{ singular: 'File', plural: 'Files' }}
              />
            </tr>
          </thead>
          <tbody {...getTableBodyProps()} style={{ cursor: 'pointer' }}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  key={`files-table-tr-${row.id}`}
                  {...row.getRowProps()}
                  className={
                    hasActiveReminder(row.original) || row.original.stageId === STAGE_INTAKE_IN_PROGRESS
                      ? 'alert-row'
                      : ''
                  }
                >
                  {row.cells.map((cell: any) => {
                    return (
                      (!xs || (xs && cell.column.xs)) && (
                        <td
                          key={`files-table-td-${cell.row.id}-${cell.column.id}`}
                          {...cell.getCellProps()}
                          onClick={() => selectRow(row.original.fileId)}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizes={[20, 50, 100, 500]}
      />
    </div>
  );
};
