import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { FileContext, ICommunityImpactFields } from './FileContext';
import DatePicker from 'react-datepicker';
import { UsersContext } from '../../contexts/UsersContext';
import { IUser } from '../Users/UsersTable';
import { DEPARTMENT_JUSTICE, DomainContext, ICourtLocation, IStage } from '../../contexts/DomainContext';
import CreatableSelect from 'react-select/creatable';
import { ICharge } from '../Charges/ChargesTable';
import { FaTimes } from 'react-icons/fa';
import { MyUserContext } from '../../contexts/MyUserContext';
import { disabledSelectStylesWithMenu } from '../../utils/StylingUtils';

export const CommunityImpactFileDetails: React.FC<{
  isEditingAllowed: boolean;
  trySave: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateReminder?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUncheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const fileContext = useContext(FileContext);
  const usersContext = useContext(UsersContext);
  const domainContext = useContext(DomainContext);
  const myUser = useContext(MyUserContext);
  const stages = domainContext.stages;
  const yukonFns = domainContext.yukonFns;
  const staff = usersContext.users;
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);
  const charges = domainContext.charges;
  const courtLocationOptions = domainContext.courtLocations;

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  const firstNationOptions = yukonFns.map((fn) => ({ value: fn.yukonFirstNationId, label: fn.name }));

  return (
    <>
      <Row className={'mb-4'}>
        <Col>
          <Card>
            <CardHeader>Court Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'submitted-date'}>Sentencing Date</Label>
                    <DatePicker
                      selected={
                        fileContext.communityImpactFields?.sentencingDate
                          ? new Date(fileContext.communityImpactFields?.sentencingDate)
                          : undefined
                      }
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCommunityImpactFields(
                          (s) => ({ ...s, sentencingDate: date?.getTime() } as ICommunityImpactFields)
                        );
                        if (props.setUpdateReminder) {
                          props.setUpdateReminder(true);
                        }
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      isClearable={true}
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
                <Col className={'pl-0'}>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'court-location'}>Court Location</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'courtLocationId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={courtLocationOptions}
                      value={
                        courtLocationOptions.find((ft: any) => ft.courtLocationId === fileContext.courtLocationId) ??
                        undefined
                      }
                      onChange={(value: any) => {
                        if (value !== null) {
                          fileContext.setCourtLocationId(value.courtLocationId);
                        } else {
                          fileContext.setCourtLocationId(undefined);
                        }
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: ICourtLocation) => option.courtLocationId}
                      getOptionLabel={(option: ICourtLocation) => option.name}
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>First Nation</Label>
                    <CreatableSelect
                      className={'input--border-rounded'}
                      styles={disabledSelectStylesWithMenu}
                      formatCreateLabel={(inputValue: string) => inputValue}
                      placeholder={<span className='placeholder'>Select or Type...</span>}
                      options={[
                        ...firstNationOptions,
                        ...(!fileContext.communityImpactFields?.firstNation ||
                        firstNationOptions.some((e) => e.value === fileContext.communityImpactFields?.firstNation)
                          ? []
                          : [
                              {
                                value: fileContext.communityImpactFields?.firstNation,
                                label: fileContext.communityImpactFields?.firstNation,
                              },
                            ]),
                      ]}
                      value={
                        fileContext.communityImpactFields?.firstNation
                          ? firstNationOptions
                              .map((firstNation) => firstNation.value)
                              .includes(fileContext.communityImpactFields?.firstNation)
                            ? {
                                value: fileContext.communityImpactFields?.firstNation,
                                label: firstNationOptions.find(
                                  (firstNation) => firstNation.value === fileContext.communityImpactFields?.firstNation
                                )?.label,
                              }
                            : {
                                value: fileContext.communityImpactFields?.firstNation,
                                label: fileContext.communityImpactFields?.firstNation,
                              }
                          : undefined
                      }
                      onChange={(val) => {
                        props.setIsDirty(true);
                        fileContext.setCommunityImpactFields(
                          (s) => ({ ...s, firstNation: val ? val.value : '' } as ICommunityImpactFields)
                        );
                      }}
                      isMulti={false}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                <Col className={'px-0'}>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Community Name</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='community'
                      autoComplete={'off'}
                      value={fileContext.communityImpactFields?.communityName}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const communityName = e.target.value;
                        fileContext.setCommunityImpactFields(
                          (s) => ({ ...s, communityName } as ICommunityImpactFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Community Contact</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='communityContactName'
                      autoComplete={'off'}
                      value={fileContext.communityImpactFields?.communityContactName}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const communityContactName = e.target.value;
                        fileContext.setCommunityImpactFields(
                          (s) => ({ ...s, communityContactName } as ICommunityImpactFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Contact Phone</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='communityContactNumber'
                      autoComplete={'off'}
                      value={fileContext.communityImpactFields?.communityContactNumber}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const communityContactNumber = e.target.value;
                        fileContext.setCommunityImpactFields(
                          (s) => ({ ...s, communityContactNumber } as ICommunityImpactFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className={'pl-0'}>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Contact Email</Label>
                    <Input
                      type='email'
                      placeholder=''
                      name='communityContactEmail'
                      autoComplete={'off'}
                      value={fileContext.communityImpactFields?.communityContactEmail}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const communityContactEmail = e.target.value;
                        fileContext.setCommunityImpactFields(
                          (s) => ({ ...s, communityContactEmail } as ICommunityImpactFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Victim(s)</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='victim'
                      value={fileContext.communityImpactFields?.victim}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const victim = e.target.value;
                        fileContext.setCommunityImpactFields((s) => ({ ...s, victim } as ICommunityImpactFields));
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Lawyers(s)</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='offenderLawyer'
                      autoComplete={'off'}
                      value={fileContext.communityImpactFields?.offenderLawyer}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const offenderLawyer = e.target.value;
                        fileContext.setCommunityImpactFields(
                          (s) => ({ ...s, offenderLawyer } as ICommunityImpactFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'staffIds'}>Charges</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      className={'mb-2'}
                      name={'chargeIds'}
                      placeholder={<span className='placeholder'>Select Charge to Add...</span>}
                      options={charges.filter(
                        (c) =>
                          c.isActive &&
                          !fileContext.communityImpactFields?.offenderChargeIds?.includes(c.chargeId ? c.chargeId : '')
                      )}
                      onChange={(value: any) => {
                        fileContext.setCommunityImpactFields(
                          (s) =>
                            ({
                              ...s,
                              offenderChargeIds: value.map((c: ICharge) => c.chargeId).concat(s?.offenderChargeIds),
                            } as ICommunityImpactFields)
                        );
                        props.setIsDirty(true);
                      }}
                      value={[]}
                      hideSelectedOptions={true}
                      getOptionValue={(option: ICharge) => (option.chargeId ? option.chargeId : '')}
                      formatOptionLabel={(option: ICharge) => (
                        <span>
                          {option.criminalSectionCode}
                          <i style={{ color: '#6c757d' }}>{' (' + option.description + ')'}</i>
                          {option.isActive ? '' : ' (Inactive)'}
                        </span>
                      )}
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                      isMulti={true}
                    />
                    <ListGroup>
                      {fileContext.communityImpactFields?.offenderChargeIds &&
                        fileContext.communityImpactFields?.offenderChargeIds.map((c) => {
                          const charge = charges.find((ch) => ch.chargeId === c);
                          if (charge) {
                            return (
                              <ListGroupItem key={charge.chargeId} className={'p-2 d-flex flex-row'}>
                                <span>
                                  {charge.criminalSectionCode}
                                  <i style={{ color: '#6c757d' }}>{' (' + charge.description + ')'}</i>
                                </span>
                                <Button
                                  color={'link'}
                                  className={'text-danger p-0 ml-auto'}
                                  style={{ textAlign: 'right' }}
                                  onClick={() => {
                                    fileContext.setCommunityImpactFields(
                                      (s) =>
                                        ({
                                          ...s,
                                          offenderChargeIds: s?.offenderChargeIds.filter(
                                            (ocId) => ocId !== charge.chargeId
                                          ),
                                        } as ICommunityImpactFields)
                                    );
                                    props.setIsDirty(true);
                                  }}
                                >
                                  <FaTimes />
                                </Button>
                              </ListGroupItem>
                            );
                          }
                        })}
                    </ListGroup>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>File Details</CardHeader>
            <CardBody>
              {myUser.isSuperAdmin && (
                <Row className='square border-bottom mb-2'>
                  <Col>
                    <FormGroup>
                      <Label for={'isConfidential'}>Confidentiality</Label>
                      <CustomInput
                        type='checkbox'
                        id={'isConfidential'}
                        name={'isConfidential'}
                        label={'Highly Confidential File'}
                        checked={fileContext.isConfidential}
                        onChange={() => {
                          if (fileContext.isConfidential) {
                            props.setOpenUncheckConfidentialityModal(true);
                          } else {
                            props.setOpenCheckConfidentialityModal(true);
                          }
                          fileContext.setIsConfidential(!fileContext.isConfidential);
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'stageId'}>Stage</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'stageId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={stages.filter(
                        (s) =>
                          s.fileTypes &&
                          s.fileTypes.includes(fileContext.fileTypeId ? fileContext.fileTypeId : '') &&
                          (s.fileSubTypes
                            ? s.fileSubTypes.includes(fileContext.fileSubTypeId ? fileContext.fileSubTypeId : '')
                            : true)
                      )}
                      value={stages.find((s: IStage) => s.stageId === fileContext.stageId)}
                      onChange={(value: any) => {
                        fileContext.setStageId(value?.stageId || '');
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IStage) => option.stageId}
                      getOptionLabel={(option: IStage) => option.name}
                      isDisabled={!props.isEditingAllowed}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
                <Col className={'pl-0'}>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'submitted-date'}>Application Date</Label>
                    <DatePicker
                      selected={
                        fileContext.communityImpactFields?.applicationDate
                          ? new Date(fileContext.communityImpactFields?.applicationDate)
                          : undefined
                      }
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCommunityImpactFields(
                          (s) => ({ ...s, applicationDate: date?.getTime() } as ICommunityImpactFields)
                        );
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      isClearable={true}
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'staffIds'}>Staff</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'staffIds'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={
                        fileContext.isConfidential
                          ? staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_JUSTICE) &&
                                u.role === 'SuperAdmin'
                            )
                          : staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_JUSTICE)
                            )
                      }
                      onChange={(value: any) => {
                        fileContext.setStaffIds(value.map((v: IUser) => v.userId));
                        if (props.setUpdateReminder) {
                          props.setUpdateReminder(true);
                        }
                        props.setIsDirty(true);
                      }}
                      value={selectedStaff}
                      getOptionValue={(option: IUser) => option.userId}
                      getOptionLabel={(option: IUser) =>
                        option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                      }
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>File Details/Background</Label>
                    <Input
                      type='textarea'
                      placeholder=''
                      style={{ minHeight: '226px' }}
                      name='name'
                      value={fileContext.details}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setDetails(e.target.value);
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
