import * as React from 'react';
import { useContext, useState } from 'react';
import { DomainContext, STAGE_REJECTED } from '../../contexts/DomainContext';
import { FileContext, IFile } from './FileContext';
import { UsersContext } from '../../contexts/UsersContext';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  CustomInput,
  FormGroup,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { MyUserContext } from '../../contexts/MyUserContext';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { IntakeFileRequestActions } from '../../components/Files/IntakeFileRequestActions';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { useHistory } from 'react-router-dom';

export const AssociatedIntakeFileDisplay: React.FC<{
  associatedIntakeFile: IFile;
  forModal: boolean;
}> = (props) => {
  const domainContext = useContext(DomainContext);
  const usersContext = useContext(UsersContext);
  const fileContext = useContext(FileContext);
  const myUser = useContext(MyUserContext);
  const history = useHistory();

  const stages = domainContext.stages;

  const file = fileContext.file;
  const staff = usersContext.users;
  const incomeOrEmploymentTypes = domainContext.incomeOrEmploymentTypes;
  const resourceSupportTypes = domainContext.resourceSupportTypes;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const formatCurrencyCommasTwoDigits = (amount: string | undefined) => {
    if (amount) {
      const floatToUse = parseFloat(amount.toString().replace(/[, ]+/g, ''));
      return Number.isNaN(floatToUse)
        ? '0.00'
        : floatToUse
            .toFixed(2)
            .toString()
            .replace(/-[^0-9.]/g, '')
            .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const formattedAmountToNumber = (amount: string | undefined) => {
    if (amount) {
      if (amount.length > 0 && amount.charAt(0) === '-' && amount !== '-') {
        return -Math.abs(Number(amount.toString().replace(/[, ]+|[- ]+/g, '')));
      } else {
        return Number(amount.toString().replace(/[,]+|[- ]+/g, ''));
      }
    } else {
      return 0;
    }
  };

  const calculateTotal = (cost: string | undefined) => {
    if (cost) {
      const total = formattedAmountToNumber(cost);
      return formatCurrencyCommasTwoDigits(total.toString());
    } else {
      return '';
    }
  };

  const allTotals = () => {
    let total = 0;
    props.associatedIntakeFile.intakeFields?.incomeOrEmployments.forEach((e) => {
      total = total + formattedAmountToNumber(calculateTotal(e.monthlyAmount));
    });
    return formatCurrencyCommasTwoDigits(total.toString());
  };

  const deleteFile = () => {
    if (file.fileId) {
      authAxios.post('/api/files/admin/delete-file/' + file.fileId).then((response) => {
        if (response.data.status === 'OK') {
          notifySuccess('File has been deleted.');
          history.replace({ pathname: '/files', state: { deletedFileId: file.fileId } });
        } else {
          notifyError(response.data.status);
        }
      });
    }
  };

  return (
    <>
      {!props.forModal && (
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex flex-wrap sticky-top'}>
          <NavbarBrand className={'w-mobile-100'}>Intake</NavbarBrand>
        {myUser.isSuperAdmin && file.stageId === STAGE_REJECTED && (
          <div className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}>
            <Button color='danger' className={'w-mobile-100'} onClick={() => setOpenDeleteModal(true)}>
              Delete File
            </Button>
          </div>
        )}</Navbar>
      )}
      <Container fluid={true} className={'my-3'}>
        <Row>
          {!props.forModal && (
            <Col sm={12} md={6}>
              <IntakeFileRequestActions />
            </Col>
          )}
          <Col sm={props.forModal ? 12 : 9} md={props.forModal ? 12 : 9}>
            <Card className={'mb-4'}>
              <CardHeader>Intake Details</CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label for={'intake-date'}>Intake Date</Label>
                      <div>{formatDateFromISONumber(props.associatedIntakeFile.intakeFields?.intakeDate) || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>File Request Details</Label>
                      <div>{file.fileRequestDetails || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>Intake Details</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.intakeDetails || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>Brief Concerns</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.briefConcerns || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>Custody Information</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.custodyInformation || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>Current Living Situation</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.currentLivingSituation || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>Experiencing Ongoing Poverty/Hardship</Label>
                      <div>
                        {props.associatedIntakeFile.intakeFields?.experiencingOngoingPovertyOrHardship ? 'Yes' : 'No'}
                      </div>
                      <Label>Details</Label>
                      <div>
                        {props.associatedIntakeFile.intakeFields?.experiencingOngoingPovertyOrHardshipDetails || '-'}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>Experiencing Violence</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.experiencingViolence ? 'Yes' : 'No'}</div>
                      <Label>Details</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.experiencingViolenceDetails || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className={'mb-4'}>
              <CardHeader>Child Protection History</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>FCS Involvement</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.fcsInvolvement ? 'Yes' : 'No'}</div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>EFCA</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.efca ? 'Yes' : 'No'}</div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className={'mb-4'}>
              <CardHeader>Jordan&apos;s Principle Eligibility</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>Complex Medical Needs</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.complexMedicalNeeds ? 'Yes' : 'No'}</div>
                      <Label>Details</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.complexMedicalNeedsDetails || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>
                        Children/Families who have emergencies: natural disasters, bed bugs, immediate family emergency
                        (treatment/death) eviction/homeless, heating (fuel/wood/pellets)
                      </Label>
                      <div>{props.associatedIntakeFile.intakeFields?.emergencies ? 'Yes' : 'No'}</div>
                      <Label>Details</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.emergenciesDetails || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>Family Connection/Reunification</Label>
                      <div>
                        {props.associatedIntakeFile.intakeFields?.familyConnectionOrReunification ? 'Yes' : 'No'}
                      </div>
                      <Label>Details</Label>
                      <div>
                        {props.associatedIntakeFile.intakeFields?.familyConnectionOrReunificationDetails || '-'}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2 '}>
                      <Label>Cultural Connections</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.culturalConnections ? 'Yes' : 'No'}</div>
                      <Label>Details</Label>
                      <div>{props.associatedIntakeFile.intakeFields?.culturalConnectionsDetails || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm={props.forModal ? 12 : 3} md={props.forModal ? 12 : 3}>
            <Card className={'mb-4'}>
              <CardHeader>Associated Intake File Details</CardHeader>
              <CardBody>
                {myUser.isSuperAdmin && (
                  <Row className='square border-bottom mb-2'>
                    <Col>
                      <FormGroup>
                        <Label for={'isConfidentialDisplay'}>Confidentiality</Label>
                        <CustomInput
                          type='checkbox'
                          id={'isConfidentialDisplay'}
                          name={'isConfidentialDisplay'}
                          label={'Highly Confidential File'}
                          checked={props.associatedIntakeFile.isConfidential}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2'}>
                      <Label for={'stageId'}>Stage</Label>
                      <div>{stages.find((s) => s.stageId === props.associatedIntakeFile.stageId)?.name || '-'}</div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className={'w-100 mb-2'}>
                      <Label>Created Date</Label>
                      <div>{formatDateFromISONumber(props.associatedIntakeFile.createdDate) || '-'}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'w-100 mb-2'}>
                      <Label for={'staffIds'}>Staff</Label>
                      <div>
                        {staff
                          .filter((u) => props.associatedIntakeFile.staffIds.some((s) => s === u.userId))
                          .map((s) => s.lastName.toUpperCase() + ', ' + s.firstName)
                          .join(' • ')}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className={'mb-3'}>
              <CardHeader className={'align-items-center'}>Income/Employment</CardHeader>
              {props.associatedIntakeFile.intakeFields?.incomeOrEmployments &&
                props.associatedIntakeFile.intakeFields?.incomeOrEmployments.length > 0 && (
                  <CardBody className={'p-0'}>
                    <ListGroupItem className={'py-2 d-none d-md-block'}>
                      <Row>
                        <Col style={{ flexGrow: 0.8 }}>
                          <Label>Type</Label>
                        </Col>
                        <Col style={{ flexGrow: 2.0 }}>
                          <Label>Description</Label>
                        </Col>
                        <Col style={{ flexGrow: 0.7, textAlign: 'right' }}>
                          <Label>Monthly Amount ($)</Label>
                        </Col>
                      </Row>
                    </ListGroupItem>
                    {props.associatedIntakeFile.intakeFields?.incomeOrEmployments.map((e, eIndex) => (
                      <>
                        <ListGroupItem key={eIndex} className={'py-2'}>
                          <Row>
                            <Col style={{ flexGrow: 0.8 }}>
                              <div>
                                {incomeOrEmploymentTypes.find(
                                  (t) => t.incomeOrEmploymentTypeId === e.incomeOrEmploymentTypeId
                                )?.name || '-'}
                              </div>
                            </Col>
                            <Col style={{ flexGrow: 2.0 }}>
                              <div>{e.description || '-'}</div>
                            </Col>
                            <Col style={{ flexGrow: 0.7, textAlign: 'right' }}>
                              <div>{formatCurrencyCommasTwoDigits(e.monthlyAmount)}</div>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      </>
                    ))}
                    {props.associatedIntakeFile.intakeFields?.incomeOrEmployments &&
                      props.associatedIntakeFile.intakeFields?.incomeOrEmployments.length > 0 && (
                        <Row style={{ paddingRight: 21 }}>
                          <Col style={{ flexGrow: 2.6 }} />
                          <Col style={{ textAlign: 'right' }}>
                            <Label>Total ($)</Label>
                          </Col>
                          <Col style={{ textAlign: 'right' }}>
                            <div>{allTotals()}</div>
                          </Col>
                        </Row>
                      )}
                  </CardBody>
                )}
            </Card>
          </Col>
          <Col>
            <Card className={'mb-3'}>
              <CardHeader className={'align-items-center'}>Resource Supports</CardHeader>
              {props.associatedIntakeFile.intakeFields?.resourceSupports &&
                props.associatedIntakeFile.intakeFields?.resourceSupports.length > 0 && (
                  <CardBody className={'p-0'}>
                    <ListGroupItem className={'py-2 d-none d-md-block'}>
                      <Row>
                        <Col>
                          <Label>Type</Label>
                        </Col>
                        <Col>
                          <Label>Description</Label>
                        </Col>
                      </Row>
                    </ListGroupItem>

                    {props.associatedIntakeFile.intakeFields?.resourceSupports.map((r, rIndex) => (
                      <>
                        <ListGroupItem key={rIndex} className={'py-2'}>
                          <Row>
                            <Col>
                              <div>
                                {resourceSupportTypes.find((t) => t.resourceSupportTypeId === r.resourceSupportTypeId)
                                  ?.name || '-'}
                              </div>
                            </Col>
                            <Col>
                              <div>{r.description || '-'}</div>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      </>
                    ))}
                  </CardBody>
                )}
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={openDeleteModal} toggle={() => setOpenDeleteModal(!openDeleteModal)} className='logout-modal'>
        <ModalHeader toggle={() => setOpenDeleteModal(!openDeleteModal)}>Delete File</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            Are you sure you want to delete this file and all associated case notes, documents and reminders? This
            action cannot be undone.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              deleteFile();
              setOpenDeleteModal(false);
            }}
          >
            Delete File
          </Button>
          <Button color={'light'} onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
