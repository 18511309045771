import * as React from 'react';
import { Button, Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { authAxios } from '../../services/AxiosService';

interface IWellnessVacancyReportData {
  wellnessHouseUnitName: string;
  totalOccupants: number;
}

export const WellnessVacancyReport: React.FC = () => {
  const [wellnessVacancyReportData, setWellnessVacancyReportData] = useState<IWellnessVacancyReportData[]>([]);

  useEffect(() => {
    authAxios.get(`/api/reports/wellness-vacancy-report`).then((response) => {
      setWellnessVacancyReportData(response.data);
    });
  }, []);

  return (
    <div>
      <Navbar color={'light'} light={true} expand={'xs'}>
        <Row className={'flex-fill'}>
          <Col md={3} xl={2}>
            <NavbarBrand>Wellness Vacancy Report</NavbarBrand>
          </Col>
          <Col className={'d-flex'}>
            <Button className={'ml-auto'} color={'warning'} onClick={() => window.print()}>
              Print <FaPrint />
            </Button>
          </Col>
        </Row>
      </Navbar>
      <Container>
        <div className={'report my-5 mx-3'}>
          <header>
            <h2 className={'text-center'}>Wellness Vacancy Report</h2>
          </header>
          <table className={'report1'}>
            <thead>
              <tr>
                <th className={'header'}>Wellness House/Unit</th>
                <th className={'header'}>Number of Occupants</th>
              </tr>
            </thead>
            <tbody>
              {wellnessVacancyReportData.map((wvrd, index) => {
                return (
                  <tr key={index}>
                    <td className={'report-row'}>{wvrd.wellnessHouseUnitName}</td>
                    <td className={'report-row'}>{wvrd.totalOccupants}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
};
