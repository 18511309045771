import * as React from 'react';
import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import {
  DomainContext,
  FILE_SUB_TYPE_BIRTH_WORKER,
  FILE_SUB_TYPE_COMMUNITY_IMPACT,
  FILE_SUB_TYPE_CULTURAL_CONNECTIONS,
  FILE_SUB_TYPE_FAMILY_PRESERVATION,
  FILE_SUB_TYPE_GLADUE_REPORT,
  FILE_SUB_TYPE_GROUP,
  FILE_SUB_TYPE_INDIVIDUAL,
  FILE_SUB_TYPE_INTAKE,
  FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR,
  FILE_SUB_TYPE_WCC_CONNECTIVE,
  FILE_SUB_TYPE_WELLNESS_CENTRE,
  FILE_TYPE_BIRTH_WORKER,
  FILE_TYPE_CULTURAL_CONNECTIONS,
  FILE_TYPE_FAMILY_PRESERVATION,
  FILE_TYPE_INTAKE,
  FILE_TYPE_JORDANS_PRINCIPLE,
  FILE_TYPE_JUSTICE,
  FILE_TYPE_WELLNESS_CENTRE,
  getPreviousBusinessDay,
  JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE,
  JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER,
  NOTIFICATION_TYPE_EMAIL,
  NOTIFICATION_TYPE_ON_SCREEN,
  REMINDER_TYPE_COURT_DATE,
  REMINDER_TYPE_SENTENCING_DATE,
  STAGE_ACTIVE,
  STAGE_DRAFT,
  STAGE_IN_PROGRESS,
  STAGE_REQUESTED,
} from '../../contexts/DomainContext';
import { useHistory, useLocation } from 'react-router-dom';
import { FileContext, ICourtDate } from './FileContext';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';

import { Requests } from './Actions/Requests';
import { UsersContext } from '../../contexts/UsersContext';
import { IUser } from '../Users/UsersTable';
import { Referrals } from './FileReferrals';
import { ClientsContext } from '../../contexts/ClientsContext';
import { JordansPrincipleFileDetails } from './JordansPrincipleFileDetails';
import { FamilyPreservationFileDetails } from './FamilyPreservationFileDetails';
import { WCCConnectiveFileDetails } from './WCCConnectiveFileDetails';
import { CommunityImpactFileDetails } from './CommunityImpactFileDetails';
import { GladueReportFileDetails } from './GladueReportFileDetails';
import { ServicesProvided } from './FileServicesProvided';
import { CulturalConnectionsFileDetails } from './CulturalConnectionsFileDetails';
import { BirthWorkerFileDetails } from './BirthWorkerFileDetails';
import { LegalSystemNavigatorFileDetails } from './LegalSystemNavigatorFileDetails';
import { IntakeFileDetails } from './IntakeFileDetails';
import { formatDate } from '../../formatters/DateTimeFormatter';
import { ConfidentialityModals } from '../../components/Files/Actions/ConfidentialityModals';
import { IncomeOrEmployments } from '../../components/Files/FileIncomeOrEmployments';
import { ResourceSupports } from '../../components/Files/FileResourceSupports';
import { IClient } from '../../components/Clients/ClientContext';
import { WellnessCentreFileDetails } from '../../components/Files/WellnessCentreFileDetails';

export const FileCreate: React.FC = () => {
  const fileContext = useContext(FileContext);
  const usersContext = useContext(UsersContext);
  const domainContext = useContext(DomainContext);
  const clientsContext = useContext(ClientsContext);

  const staff = usersContext.users;
  const clients = clientsContext.state.clients;
  const fileTypes = domainContext.fileTypes;
  const fileSubTypes = domainContext.fileSubTypes;
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);

  const [trySave, setTrySave] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [intakeFileStageChangedToCompleted, setIntakeFileStageChangedToCompleted] = useState<boolean>(false);

  const [openCheckConfidentialityModal, setOpenCheckConfidentialityModal] = useState(false);
  const [openUncheckConfidentialityModal, setOpenUncheckConfidentialityModal] = useState(false);
  const [openStageCompletedModal, setOpenStageCompletedModal] = useState<boolean>(false);
  const [openMissingIndigenousAncestryModal, setOpenMissingIndigenousAncestryModal] = useState<boolean>(false);
  const isEditingAllowed = true;

  const history = useHistory();
  const location = useLocation<{
    dutyCounsel?: boolean;
    counselRequest?: boolean;
    overrideReload?: boolean;
    createClientId?: string;
    fileTypeId?: string;
    fileSubTypeId?: string;
    clientId?: string;
  }>();

  const [openMissingRequestInfoModal, setOpenMissingRequestInfoModal] = useState(false);

  //JP Request fields
  const numberOfChildrenMissing =
    fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
    fileContext.stageId !== STAGE_DRAFT &&
    (fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
      fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER) &&
    fileContext.requests.filter((r) => !r.numberOfChildren).length > 0;
  const categoryMissing =
    fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
    fileContext.stageId !== STAGE_DRAFT &&
    fileContext.requests.filter((r) => !r.categoryId).length > 0;

  useEffect(() => {
    if (location.state?.fileTypeId) {
      fileContext.setFileTypeId(location.state.fileTypeId);
      if (location.state?.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE) {
        if (location.state?.fileSubTypeId === FILE_SUB_TYPE_GROUP) {
          fileContext.setFileSubTypeId(location.state?.fileSubTypeId);
        } else {
          fileContext.setFileSubTypeId(FILE_SUB_TYPE_INDIVIDUAL);
        }
        fileContext.setStageId(STAGE_DRAFT);
      } else if (location.state?.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION) {
        fileContext.setFileSubTypeId(FILE_SUB_TYPE_FAMILY_PRESERVATION);
        fileContext.setStageId(STAGE_DRAFT);
      } else if (location.state?.fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS) {
        fileContext.setFileSubTypeId(FILE_SUB_TYPE_CULTURAL_CONNECTIONS);
        fileContext.setStageId(STAGE_DRAFT);
      } else if (location.state?.fileTypeId === FILE_TYPE_BIRTH_WORKER) {
        fileContext.setFileSubTypeId(FILE_SUB_TYPE_BIRTH_WORKER);
        fileContext.setStageId(STAGE_DRAFT);
      } else if (location.state?.fileTypeId === FILE_TYPE_INTAKE) {
        fileContext.setFileSubTypeId(FILE_SUB_TYPE_INTAKE);
        fileContext.setStageId(STAGE_DRAFT);
      } else if (location.state?.fileTypeId === FILE_TYPE_WELLNESS_CENTRE) {
        fileContext.setFileSubTypeId(FILE_SUB_TYPE_WELLNESS_CENTRE);
        fileContext.setStageId(STAGE_DRAFT);
      } else if (location.state?.fileTypeId === FILE_TYPE_JUSTICE) {
        if (location.state?.fileSubTypeId) {
          if (location.state?.fileSubTypeId === FILE_SUB_TYPE_WCC_CONNECTIVE) {
            fileContext.setFileSubTypeId(FILE_SUB_TYPE_WCC_CONNECTIVE);
            fileContext.setStageId(STAGE_ACTIVE);
          } else if (location.state?.fileSubTypeId === FILE_SUB_TYPE_COMMUNITY_IMPACT) {
            fileContext.setFileSubTypeId(FILE_SUB_TYPE_COMMUNITY_IMPACT);
            fileContext.setStageId(STAGE_IN_PROGRESS);
          } else if (location.state?.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT) {
            fileContext.setFileSubTypeId(FILE_SUB_TYPE_GLADUE_REPORT);
            fileContext.setStageId(STAGE_REQUESTED);
          } else if (location.state?.fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR) {
            fileContext.setFileSubTypeId(FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR);
            fileContext.setStageId(STAGE_ACTIVE);
          }
        } else {
          fileContext.setFileSubTypeId(FILE_SUB_TYPE_WCC_CONNECTIVE);
          fileContext.setStageId(STAGE_ACTIVE);
        }
      }
    }
    if (location.state?.clientId) {
      fileContext.setClientIds([location.state.clientId]);
    }
  }, []);

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  useEffect(() => {
    if (location.state?.createClientId) {
      fileContext.setClientIds((pids) => [
        ...pids,
        location.state?.createClientId ? location.state?.createClientId : '',
      ]);
      fileContext.setFile((f) => ({
        ...f,
        clientIds: location.state?.createClientId ? f.clientIds.concat([location.state?.createClientId]) : f.clientIds,
      }));
      history.replace(history.location.pathname, { createClientId: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientsMissingIndigenousInfo = useMemo(() => {
    const foundClients = fileContext.clientIds.map((cid) => clients.find((c) => c.clientId === cid));
    return foundClients.filter((c) => c && c.indigenousInfo.length === 0) as IClient[];
  }, [fileContext.clientIds, clients]);

  const getFileType = () => {
    if (location.state.fileTypeId === FILE_TYPE_JUSTICE) {
      return fileSubTypes.find((fst) => fst.fileSubTypeId === location.state.fileSubTypeId)?.name;
    } else {
      return fileTypes.find((ft) => ft.fileTypeId === location.state.fileTypeId)?.name;
    }
  };

  const createFile = () => {
    if (fileContext.fileTypeId && fileContext.stageId && fileContext.createdDate) {
      if (
        fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
        !fileContext.jordansPrincipleFields?.requestTypeId
      ) {
        setTrySave(true);
      } else if (
        //if a relevant JP file is being set to a stage other than Draft has a request that doesn't have Number of Children filled in
        fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
        fileContext.stageId !== STAGE_DRAFT &&
        (fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
          fileContext.jordansPrincipleFields?.requestTypeId === JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER) &&
        fileContext.requests.filter((r) => !r.numberOfChildren).length > 0
      ) {
        setTrySave(true);
      } else if (
        //if any JP file is being set to a stage other than Draft has a request that doesn't have Category filled in
        fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
        fileContext.stageId !== STAGE_DRAFT &&
        fileContext.requests.filter((r) => !r.categoryId).length > 0
      ) {
        setTrySave(true);
      } else {
        const data = fileContext.getCreateData();

        authAxios.post('/api/files/create', data).then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            const file = response.data.file;
            fileContext.setFile({ ...file, clientIds: data.clientIds });

            fileContext.setLoaded(true);
            notifySuccess('File created.');
            history.push({ pathname: '/files/' + file.fileId, state: { overrideReload: true } });
            if (fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION) {
              const FPData = fileContext.getFamilyPreservationData(file.fileId);
              authAxios.post('/api/files/update-family-preservation', FPData).then((response) => {
                if (response.data.status === 'OK') {
                  console.log('FP updated :)');
                } else {
                  console.log('FP not updated :(');
                }
              });
            } else if (fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE) {
              const JPData = fileContext.getJordansPrincipleData(file.fileId);
              authAxios.post('/api/files/update-jordans-principle', JPData).then((response) => {
                if (response.data.status === 'OK') {
                  console.log('JP updated :)');
                } else {
                  console.log('JP not updated :(');
                }
              });
            } else if (fileContext.fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS) {
              const CCData = fileContext.getCulturalConnectionsData(file.fileId);
              authAxios.post('/api/files/update-cultural-connections', CCData).then((response) => {
                if (response.data.status === 'OK') {
                  console.log('CC updated :)');
                } else {
                  console.log('CC not updated :(');
                }
              });
            } else if (fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) {
              const BWData = fileContext.getBirthWorkersData(file.fileId);
              authAxios.post('/api/files/update-birth-workers', BWData).then((response) => {
                if (response.data.status === 'OK') {
                  console.log('BW updated :)');
                } else {
                  console.log('BW not updated :(');
                }
              });
            } else if (fileContext.fileTypeId === FILE_TYPE_INTAKE) {
              const INData = fileContext.getIntakeData(file.fileId);
              authAxios.post('/api/files/update-intake', INData).then((response) => {
                if (response.data.status === 'OK') {
                  console.log('IN updated :)');
                } else {
                  console.log('IN not updated :(');
                }
              });
            } else if (fileContext.fileTypeId === FILE_TYPE_WELLNESS_CENTRE) {
              const WCData = fileContext.getWellnessCentreData(file.fileId);
              authAxios.post('/api/files/update-wellness-centre', WCData).then((response) => {
                if (response.data.status === 'OK') {
                  console.log('WC updated :)');
                } else {
                  console.log('WC not updated :(');
                }
              });
            } else if (fileContext.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT) {
              let GRData = fileContext.getGladueReportData(file.fileId);
              if (GRData.requestedDate === undefined) {
                GRData.requestedDate = file.createdDate;
              }
              authAxios.post('/api/files/update-gladue-report', GRData).then((response) => {
                if (response.data.status === 'OK') {
                  if (GRData.sentencingDate) {
                    const updatedSentencingDate = new Date(GRData.sentencingDate);
                    const businessDay = getPreviousBusinessDay(updatedSentencingDate);
                    businessDay.setHours(7, 0, 0);

                    // update or create reminders for selected staff
                    selectedStaff.forEach((staff) => {
                      const sdReminder = fileContext.reminders.find(
                        (r) =>
                          r.staffToNotify.includes(staff.userId) && r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE
                      );

                      if (sdReminder) {
                        authAxios
                          .post('/api/files/update-reminder', {
                            fileId: file.fileId,
                            reminderId: sdReminder.reminderId,
                            text: 'Sentencing Date',
                            //7am of sentencing date
                            date: businessDay.getTime(),
                            staffToNotify: sdReminder.staffToNotify,
                            notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                            reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                          })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              notifySuccess('Reminder updated.');
                              //update fileContext with updated reminder
                              const rIndex: number = fileContext.reminders.findIndex(
                                (r) => r.reminderId === response.data.noteId
                              );
                              fileContext.reminders[rIndex].staffToNotify = sdReminder.staffToNotify;
                              fileContext.reminders[rIndex].date = businessDay.getTime();
                              fileContext.setReminders(fileContext.reminders);
                            } else {
                              notifyError('Error updating reminder.');
                            }
                          });
                      } else {
                        authAxios
                          .post('/api/files/create-reminder', {
                            fileId: file.fileId,
                            text: 'Sentencing Date',
                            date: businessDay.getTime(),
                            staffToNotify: [staff.userId],
                            notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                            reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                          })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              notifySuccess('Reminder created.');
                              fileContext.setReminders((r) =>
                                r.concat([
                                  {
                                    reminderId: response.data.reminderId,
                                    text: 'Sentencing Date',
                                    date: businessDay.getTime(),
                                    staffToNotify: [staff.userId],
                                    notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                                    reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                                    resolved: false,
                                  },
                                ])
                              );
                            } else {
                              notifyError('Error creating Reminder.');
                            }
                          });
                      }
                    });

                    // delete Sentencing Date Reminders for staff that are no longer in selected staff
                    fileContext.reminders.forEach((r) => {
                      if (
                        r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE &&
                        !r.staffToNotify.every((id) => selectedStaff.some((s) => s.userId === id))
                      ) {
                        authAxios
                          .post('/api/files/delete-reminder', { fileId: file.fileId, reminderId: r.reminderId })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              setIsDirty(false);
                              notifySuccess('Reminder deleted.');
                              fileContext.setReminders((rArray) =>
                                rArray.filter((rem) => rem.reminderId !== r.reminderId)
                              );
                            } else {
                              notifyError('Error deleting reminder.');
                            }
                          });
                      }
                    });
                  }
                  console.log('GR updated :)');
                } else {
                  console.log('GR not updated :(');
                }
              });
            } else if (fileContext.fileSubTypeId === FILE_SUB_TYPE_WCC_CONNECTIVE) {
              let WCCCDate = fileContext.getWCCConnectiveData(file.fileId);
              authAxios.post('/api/files/update-wcc-connective', WCCCDate).then((response) => {
                if (response.data.status === 'OK') {
                  if (WCCCDate.sentencingDate) {
                    const updatedSentencingDate = new Date(WCCCDate.sentencingDate);
                    const businessDay = getPreviousBusinessDay(updatedSentencingDate);
                    businessDay.setHours(7, 0, 0);

                    // update or create reminders for selected staff
                    selectedStaff.forEach((staff) => {
                      const sdReminder = fileContext.reminders.find(
                        (r) =>
                          r.staffToNotify.includes(staff.userId) && r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE
                      );

                      if (sdReminder) {
                        authAxios
                          .post('/api/files/update-reminder', {
                            fileId: file.fileId,
                            reminderId: sdReminder.reminderId,
                            text: 'Sentencing Date',
                            //7am of sentencing date
                            date: businessDay.getTime(),
                            staffToNotify: sdReminder.staffToNotify,
                            notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                            reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                          })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              notifySuccess('Reminder updated.');
                              //update fileContext with updated reminder
                              const rIndex: number = fileContext.reminders.findIndex(
                                (r) => r.reminderId === response.data.noteId
                              );
                              fileContext.reminders[rIndex].staffToNotify = sdReminder.staffToNotify;
                              fileContext.reminders[rIndex].date = businessDay.getTime();
                              fileContext.setReminders(fileContext.reminders);
                            } else {
                              notifyError('Error updating reminder.');
                            }
                          });
                      } else {
                        authAxios
                          .post('/api/files/create-reminder', {
                            fileId: file.fileId,
                            text: 'Sentencing Date',
                            date: businessDay.getTime(),
                            staffToNotify: [staff.userId],
                            notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                            reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                          })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              notifySuccess('Reminder created.');
                              fileContext.setReminders((r) =>
                                r.concat([
                                  {
                                    reminderId: response.data.reminderId,
                                    text: 'Sentencing Date',
                                    date: businessDay.getTime(),
                                    staffToNotify: [staff.userId],
                                    notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                                    reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                                    resolved: false,
                                  },
                                ])
                              );
                            } else {
                              notifyError('Error creating Reminder.');
                            }
                          });
                      }
                    });

                    // delete Sentencing Date Reminders for staff that are no longer in selected staff
                    fileContext.reminders.forEach((r) => {
                      if (
                        r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE &&
                        !r.staffToNotify.every((id) => selectedStaff.some((s) => s.userId === id))
                      ) {
                        authAxios
                          .post('/api/files/delete-reminder', { fileId: file.fileId, reminderId: r.reminderId })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              setIsDirty(false);
                              notifySuccess('Reminder deleted.');
                              fileContext.setReminders((rArray) =>
                                rArray.filter((rem) => rem.reminderId !== r.reminderId)
                              );
                            } else {
                              notifyError('Error deleting reminder.');
                            }
                          });
                      }
                    });
                  }
                  console.log('GR updated :)');
                } else {
                  console.log('GR not updated :(');
                }
              });
            } else if (fileContext.fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR) {
              let LSNData = fileContext.getLegalSystemNavigatorData(file.fileId);
              LSNData.courtDatesList = LSNData.courtDatesList?.sort((a: ICourtDate, b: ICourtDate) => {
                if (a.date && b.date) {
                  return a.date - b.date;
                } else {
                  if (a.date === (0 || undefined)) {
                    return 1;
                  }
                  if (b.date === (0 || undefined)) {
                    return -1;
                  }
                  return 0;
                }
              });
              authAxios.post('/api/files/update-legal-system-navigator', LSNData).then((response) => {
                if (response.data.status === 'OK') {
                  fileContext.setLegalSystemNavigatorFields(LSNData);
                  response.data.courtDatesList.forEach((c: ICourtDate) => {
                    if (c.date) {
                      const updatedCourtDate = new Date(c.date);
                      const businessDay = getPreviousBusinessDay(updatedCourtDate);
                      businessDay.setHours(7, 0, 0);

                      // update or create reminders for selected staff
                      selectedStaff.forEach((staff) => {
                        authAxios
                          .post('/api/files/create-reminder', {
                            fileId: file.fileId,
                            text: 'Court Date on: ' + formatDate(updatedCourtDate),
                            date: businessDay.getTime(),
                            staffToNotify: [staff.userId],
                            notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                            reminderTypeId: REMINDER_TYPE_COURT_DATE,
                            courtDateId: c.courtDateId,
                          })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              notifySuccess('Reminder created.');
                              fileContext.setReminders((r) =>
                                r.concat([
                                  {
                                    reminderId: response.data.reminderId,
                                    text: 'Court Date on: ' + formatDate(updatedCourtDate),
                                    date: businessDay.getTime(),
                                    staffToNotify: [staff.userId],
                                    notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                                    reminderTypeId: REMINDER_TYPE_COURT_DATE,
                                    resolved: false,
                                    courtDateId: c.courtDateId,
                                  },
                                ])
                              );
                            } else {
                              notifyError('Error creating Reminder.');
                            }
                          });
                      });
                    }
                  });
                  fileContext.setCourtDatesList(response.data.courtDatesList);
                  console.log('LSN updated :)');
                } else {
                  console.log('LSN not updated :(');
                }
              });
            } else if (fileContext.fileSubTypeId === FILE_SUB_TYPE_COMMUNITY_IMPACT) {
              const ciData = fileContext.getCommunityImpactData(file.fileId);
              authAxios.post('/api/files/update-community-impact', ciData).then((response) => {
                if (response.data.status === 'OK') {
                  if (ciData.sentencingDate) {
                    const updatedSentencingDate = new Date(ciData.sentencingDate);
                    const businessDay = getPreviousBusinessDay(updatedSentencingDate);
                    businessDay.setHours(7, 0, 0);

                    // update or create reminders for selected staff
                    selectedStaff.forEach((staff) => {
                      const sdReminder = fileContext.reminders.find(
                        (r) =>
                          r.staffToNotify.includes(staff.userId) && r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE
                      );

                      if (sdReminder) {
                        authAxios
                          .post('/api/files/update-reminder', {
                            fileId: file.fileId,
                            reminderId: sdReminder.reminderId,
                            text: 'Sentencing Date',
                            //7am of sentencing date
                            date: businessDay.getTime(),
                            staffToNotify: sdReminder.staffToNotify,
                            notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                            reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                          })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              notifySuccess('Reminder updated.');
                              //update fileContext with updated reminder
                              const rIndex: number = fileContext.reminders.findIndex(
                                (r) => r.reminderId === response.data.noteId
                              );
                              fileContext.reminders[rIndex].staffToNotify = sdReminder.staffToNotify;
                              fileContext.reminders[rIndex].date = businessDay.getTime();
                              fileContext.setReminders(fileContext.reminders);
                            } else {
                              notifyError('Error updating reminder.');
                            }
                          });
                      } else {
                        authAxios
                          .post('/api/files/create-reminder', {
                            fileId: file.fileId,
                            text: 'Sentencing Date',
                            date: businessDay.getTime(),
                            staffToNotify: [staff.userId],
                            notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                            reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                          })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              notifySuccess('Reminder created.');
                              fileContext.setReminders((r) =>
                                r.concat([
                                  {
                                    reminderId: response.data.reminderId,
                                    text: 'Sentencing Date',
                                    date: businessDay.getTime(),
                                    staffToNotify: [staff.userId],
                                    notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
                                    reminderTypeId: REMINDER_TYPE_SENTENCING_DATE,
                                    resolved: false,
                                  },
                                ])
                              );
                            } else {
                              notifyError('Error creating Reminder.');
                            }
                          });
                      }
                    });

                    // delete Sentencing Date Reminders for staff that are no longer in selected staff
                    fileContext.reminders.forEach((r) => {
                      if (
                        r.reminderTypeId === REMINDER_TYPE_SENTENCING_DATE &&
                        !r.staffToNotify.every((id) => selectedStaff.some((s) => s.userId === id))
                      ) {
                        authAxios
                          .post('/api/files/delete-reminder', { fileId: file.fileId, reminderId: r.reminderId })
                          .then((response) => {
                            if (response.data.status === 'OK') {
                              setIsDirty(false);
                              notifySuccess('Reminder deleted.');
                              fileContext.setReminders((rArray) =>
                                rArray.filter((rem) => rem.reminderId !== r.reminderId)
                              );
                            } else {
                              notifyError('Error deleting reminder.');
                            }
                          });
                      }
                    });
                  }
                  console.log('Community Impact updated :)');
                } else {
                  console.log('Community Impact not updated :(');
                }
              });
            }
          } else {
            notifyError(response.data.errorMessage);
          }
        });
      }
    } else {
      setTrySave(true);
    }
  };

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />

      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex flex-wrap sticky-top'}>
          <NavbarBrand className={'w-mobile-100'}>
            Add {getFileType()} File
            {clients.find((c) => c.clientId === location.state.clientId)
              ? ' For ' +
                clients.find((c) => c.clientId === location.state.clientId)?.lastName.toUpperCase() +
                ', ' +
                clients.find((c) => c.clientId === location.state.clientId)?.firstName
              : ''}
          </NavbarBrand>
          <div
            className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}
            style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
          >
            <Button color={'link'} className={'w-mobile-100'} onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button
              color={'primary'}
              className={'w-mobile-100'}
              onClick={() => {
                if (categoryMissing || numberOfChildrenMissing) {
                  setOpenMissingRequestInfoModal(true);
                  setTrySave(true);
                } else if (fileContext.fileTypeId === FILE_TYPE_INTAKE && intakeFileStageChangedToCompleted) {
                  setOpenStageCompletedModal(true);
                } else if (clientsMissingIndigenousInfo.length > 0) {
                  setOpenMissingIndigenousAncestryModal(true);
                } else {
                  createFile();
                }
              }}
            >
              Create File
            </Button>
          </div>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE && (
            <JordansPrincipleFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION && (
            <FamilyPreservationFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && (
            <BirthWorkerFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_CULTURAL_CONNECTIONS && (
            <CulturalConnectionsFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_INTAKE && (
            <IntakeFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              setIntakeFileStageChangedToCompleted={setIntakeFileStageChangedToCompleted}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_WELLNESS_CENTRE && (
            <WellnessCentreFileDetails
              setIsDirty={setIsDirty}
              isEditingAllowed={isEditingAllowed}
              trySave={trySave}
              setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
              setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
            />
          )}
          {fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
            fileContext.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT && (
              <GladueReportFileDetails
                setIsDirty={setIsDirty}
                isEditingAllowed={isEditingAllowed}
                trySave={trySave}
                setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
                setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              />
            )}
          {fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
            fileContext.fileSubTypeId === FILE_SUB_TYPE_WCC_CONNECTIVE && (
              <WCCConnectiveFileDetails
                setIsDirty={setIsDirty}
                isEditingAllowed={isEditingAllowed}
                trySave={trySave}
                setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
                setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              />
            )}
          {fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
            fileContext.fileSubTypeId === FILE_SUB_TYPE_COMMUNITY_IMPACT && (
              <CommunityImpactFileDetails
                setIsDirty={setIsDirty}
                isEditingAllowed={isEditingAllowed}
                trySave={trySave}
                setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
                setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              />
            )}
          {fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
            fileContext.fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR && (
              <LegalSystemNavigatorFileDetails
                setIsDirty={setIsDirty}
                isEditingAllowed={isEditingAllowed}
                trySave={trySave}
                setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
                setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
              />
            )}
          {(fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION ||
            (fileContext.fileTypeId === FILE_TYPE_JUSTICE &&
              !(
                fileContext.fileSubTypeId === FILE_SUB_TYPE_GLADUE_REPORT ||
                fileContext.fileSubTypeId === FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR
              )) ||
            fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) && (
            <Row className={'mb-3'}>
              <Col>
                <Referrals setIsDirty={setIsDirty} />
              </Col>
            </Row>
          )}
          {fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER && (
            <Row className={'mb-3'}>
              <Col>
                <Referrals setIsDirty={setIsDirty} fromCYFN={true} />
              </Col>
            </Row>
          )}
          {fileContext.fileTypeId === FILE_TYPE_INTAKE && (
            <Row className={'mb-3'}>
              <Col>
                <IncomeOrEmployments setIsDirty={setIsDirty} isEditingAllowed={true} />
              </Col>
              <Col>
                <ResourceSupports setIsDirty={setIsDirty} isEditingAllowed={true} />
              </Col>
            </Row>
          )}
          {(fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION ||
            fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) && (
            <Row className={'mb-3'}>
              <Col>
                <ServicesProvided setIsDirty={setIsDirty} />
              </Col>
            </Row>
          )}
          {(fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE ||
            fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION ||
            fileContext.fileTypeId === FILE_TYPE_BIRTH_WORKER) && (
            <Row className={'mb-3'}>
              <Col>
                <Requests setIsDirty={setIsDirty} isEditingAllowed={isEditingAllowed} trySave={trySave} />
              </Col>
            </Row>
          )}
        </Container>
      </form>
      <Modal isOpen={openMissingIndigenousAncestryModal} className='logout-modal'>
        <ModalHeader>Missing Indigenous Ancestry</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            There is at least one client on this file that is missing Indigenous ancestry information. Are you sure you
            want to create this file? The client(s) are as follows:
            <div className='d-flex flex-column'>
              <ul>
                {clientsMissingIndigenousInfo.map((client, i) => (
                  <li key={i}>{client.firstName + ' ' + (client.middleName + ' ') + client.lastName}</li>
                ))}
              </ul>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              setOpenMissingIndigenousAncestryModal(false);
              createFile();
            }}
          >
            Proceed
          </Button>
          <Button
            color='link'
            onClick={() => {
              setOpenMissingIndigenousAncestryModal(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openMissingRequestInfoModal} className='logout-modal'>
        <ModalHeader>Missing Request Information</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>File cannot be saved. There are requests with missing information.</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              setOpenMissingRequestInfoModal(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openStageCompletedModal} className='logout-modal'>
        <ModalHeader>Complete Intake File</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            Warning - are you sure you want to update the file stage? The intake details will no longer be editable.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              setOpenStageCompletedModal(false);
              setIntakeFileStageChangedToCompleted(false);
              createFile();
            }}
          >
            Proceed
          </Button>
          <Button
            color={'light'}
            onClick={() => {
              setOpenStageCompletedModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ConfidentialityModals
        setIsDirty={setIsDirty}
        openCheckConfidentialityModal={openCheckConfidentialityModal}
        setOpenCheckConfidentialityModal={setOpenCheckConfidentialityModal}
        openUncheckConfidentialityModal={openUncheckConfidentialityModal}
        setOpenUncheckConfidentialityModal={setOpenUncheckConfidentialityModal}
        staff={selectedStaff}
      />
    </div>
  );
};
