import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  DEPARTMENT_BIRTH_WORKER,
  DEPARTMENT_CULTURAL_CONNECTIONS,
  DEPARTMENT_FAMILY_PRESERVATION,
  DEPARTMENT_INTAKE,
  DEPARTMENT_JORDANS_PRINCIPLE,
  DEPARTMENT_JUSTICE,
  DEPARTMENT_WELLNESS_CENTRE,
  FILE_SUB_TYPE_COMMUNITY_IMPACT,
  FILE_SUB_TYPE_GLADUE_REPORT,
  FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR,
  FILE_SUB_TYPE_WCC_CONNECTIVE,
  STAGE_ACTIVE,
  STAGE_APPEAL,
  STAGE_ASSIGNED,
  STAGE_COMPLETED,
  STAGE_DECISION,
  STAGE_DENIED,
  STAGE_DRAFT,
  STAGE_ESCALATED,
  STAGE_FILED,
  STAGE_FINALIZED,
  STAGE_IN_PROGRESS,
  STAGE_INACTIVE,
  STAGE_INQUIRY,
  STAGE_INTAKE_CONVERTED_TO_DRAFT_DEPRECATED,
  STAGE_INTAKE_IN_PROGRESS,
  STAGE_REJECTED,
  STAGE_REQUESTED,
  STAGE_SUBMITTED,
  STAGE_UNDER_REVIEW,
} from '../../contexts/DomainContext';
import { MyUserContext } from '../../contexts/MyUserContext';

type ContextType = {
  selectedGlobalSearch?: string;
  setSelectedGlobalSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  filters?: { id: string; value: any }[];
  setFilters: React.Dispatch<React.SetStateAction<{ id: string; value: any }[]>>;
  selectedDepartmentType?: string;
  setSelectedDepartmentType: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const FilesTableContext = React.createContext<ContextType>({
  selectedGlobalSearch: undefined,
  setSelectedGlobalSearch: () => {},
  filters: [],
  setFilters: () => {},
  selectedDepartmentType: undefined,
  setSelectedDepartmentType: () => {},
});

export const FilesTableProvider = (props: any) => {
  const myUserContext = useContext(MyUserContext);
  const [selectedGlobalSearch, setSelectedGlobalSearch] = useState<string | undefined>('');
  const [selectedDepartmentType, setSelectedDepartmentType] = useState<string | undefined>(
    myUserContext.homeDepartmentId
      ? myUserContext.homeDepartmentId
      : myUserContext.departmentAccessIds.length > 0
      ? myUserContext.departmentAccessIds[0]
      : undefined
  );

  const defaultBirthWorkerStageFilters = [
    {
      id: 'stageId',
      value: [
        STAGE_INTAKE_CONVERTED_TO_DRAFT_DEPRECATED,
        STAGE_DRAFT,
        STAGE_ACTIVE,
        STAGE_INACTIVE,
        STAGE_INTAKE_IN_PROGRESS,
        STAGE_REJECTED,
      ],
    },
  ];

  const defaultCulturalConnectionsStageFilters = [
    {
      id: 'stageId',
      value: [
        STAGE_INTAKE_CONVERTED_TO_DRAFT_DEPRECATED,
        STAGE_DRAFT,
        STAGE_ACTIVE,
        STAGE_INACTIVE,
        STAGE_INTAKE_IN_PROGRESS,
        STAGE_REJECTED,
      ],
    },
  ];

  const defaultFamilyPreservationStageFilters = [
    {
      id: 'stageId',
      value: [
        STAGE_INTAKE_IN_PROGRESS,
        STAGE_REJECTED,
        STAGE_INTAKE_CONVERTED_TO_DRAFT_DEPRECATED,
        STAGE_DRAFT,
        STAGE_ACTIVE,
        STAGE_INACTIVE,
      ],
    },
  ];

  const defaultJordansPrincipalStageFilters = [
    {
      id: 'stageId',
      value: [
        STAGE_DRAFT,
        STAGE_SUBMITTED,
        STAGE_DECISION,
        STAGE_ESCALATED,
        STAGE_APPEAL,
        STAGE_COMPLETED,
        STAGE_INQUIRY,
        STAGE_DENIED,
        STAGE_INTAKE_IN_PROGRESS,
        STAGE_REJECTED,
      ],
    },
  ];

  const defaultIntakeStageFilters = [
    {
      id: 'stageId',
      value: [STAGE_DRAFT, STAGE_COMPLETED],
    },
  ];

  const defaultWellnessCentreStageFilters = [
    {
      id: 'stageId',
      value: [
        STAGE_INTAKE_IN_PROGRESS,
        STAGE_REJECTED,
        STAGE_INTAKE_CONVERTED_TO_DRAFT_DEPRECATED,
        STAGE_DRAFT,
        STAGE_ACTIVE,
        STAGE_INACTIVE,
      ],
    },
  ];

  const defaultJusticeStageFilters = [
    {
      id: 'stageIdTuple',
      value: [
        [FILE_SUB_TYPE_WCC_CONNECTIVE, STAGE_ACTIVE],
        [FILE_SUB_TYPE_WCC_CONNECTIVE, STAGE_INACTIVE],
        [FILE_SUB_TYPE_COMMUNITY_IMPACT, STAGE_IN_PROGRESS],
        [FILE_SUB_TYPE_COMMUNITY_IMPACT, STAGE_UNDER_REVIEW],
        [FILE_SUB_TYPE_GLADUE_REPORT, STAGE_UNDER_REVIEW],
        [FILE_SUB_TYPE_COMMUNITY_IMPACT, STAGE_FILED],
        [FILE_SUB_TYPE_GLADUE_REPORT, STAGE_FILED],
        [FILE_SUB_TYPE_GLADUE_REPORT, STAGE_REQUESTED],
        [FILE_SUB_TYPE_GLADUE_REPORT, STAGE_ASSIGNED],
        [FILE_SUB_TYPE_GLADUE_REPORT, STAGE_FINALIZED],
        [FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR, STAGE_ACTIVE],
        [FILE_SUB_TYPE_LEGAL_SYSTEM_NAVIGATOR, STAGE_INACTIVE],
      ],
    },
  ];

  const [filters, setFilters] = useState<{ id: string; value: any }[]>(
    selectedDepartmentType === DEPARTMENT_BIRTH_WORKER
      ? defaultBirthWorkerStageFilters
      : selectedDepartmentType === DEPARTMENT_CULTURAL_CONNECTIONS
      ? defaultCulturalConnectionsStageFilters
      : selectedDepartmentType === DEPARTMENT_FAMILY_PRESERVATION
      ? defaultFamilyPreservationStageFilters
      : selectedDepartmentType === DEPARTMENT_JORDANS_PRINCIPLE
      ? defaultJordansPrincipalStageFilters
      : selectedDepartmentType === DEPARTMENT_JUSTICE
      ? defaultJusticeStageFilters
      : selectedDepartmentType === DEPARTMENT_INTAKE
      ? defaultIntakeStageFilters
      : selectedDepartmentType === DEPARTMENT_WELLNESS_CENTRE
      ? defaultWellnessCentreStageFilters
      : []
  );

  useEffect(() => {
    setFilters(
      selectedDepartmentType === DEPARTMENT_BIRTH_WORKER
        ? defaultBirthWorkerStageFilters
        : selectedDepartmentType === DEPARTMENT_CULTURAL_CONNECTIONS
        ? defaultCulturalConnectionsStageFilters
        : selectedDepartmentType === DEPARTMENT_FAMILY_PRESERVATION
        ? defaultFamilyPreservationStageFilters
        : selectedDepartmentType === DEPARTMENT_JORDANS_PRINCIPLE
        ? defaultJordansPrincipalStageFilters
        : selectedDepartmentType === DEPARTMENT_JUSTICE
        ? defaultJusticeStageFilters
        : selectedDepartmentType === DEPARTMENT_INTAKE
        ? defaultIntakeStageFilters
        : selectedDepartmentType === DEPARTMENT_WELLNESS_CENTRE
        ? defaultWellnessCentreStageFilters
        : []
    );
  }, [selectedDepartmentType]);

  return (
    <FilesTableContext.Provider
      value={{
        selectedGlobalSearch,
        setSelectedGlobalSearch,
        filters,
        setFilters,
        selectedDepartmentType,
        setSelectedDepartmentType,
      }}
    >
      {props.children}
    </FilesTableContext.Provider>
  );
};

export const FilesTableConsumer = FilesTableContext.Consumer;
