import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { DEPARTMENT_INTAKE, DomainContext, IStage, STAGE_COMPLETED } from '../../contexts/DomainContext';
import { FileContext, IIntakeFields } from './FileContext';
import { UsersContext } from '../../contexts/UsersContext';
import { IUser } from '../Users/UsersTable';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { RequiredLabel } from '../RequiredLabel';
import DatePicker from 'react-datepicker';
import { MyUserContext } from '../../contexts/MyUserContext';
import { disabledSelectStylesWithMenu } from '../../utils/StylingUtils';

export const IntakeFileDetails: React.FC<{
  isEditingAllowed: boolean;
  trySave: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUncheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIntakeFileStageChangedToCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const domainContext = useContext(DomainContext);
  const fileContext = useContext(FileContext);
  const usersContext = useContext(UsersContext);
  const myUser = useContext(MyUserContext);

  const stages = domainContext.stages;
  //const file = fileContext.file;
  const stageIsCompleted = fileContext.file.stageId === STAGE_COMPLETED;

  const staff = usersContext.users;
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  const buttonToggleStyles = {
    lineHeight: 0.8,
  };

  return (
    <>
      <Row>
        <Col sm={9} md={9}>
          <Card className={'mb-4'}>
            <CardHeader>Intake Details</CardHeader>
            <CardBody>
              <Row>
                <Col lg={3} md={6} sm={6}>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label for={'intake-date'}>Intake Date</Label>
                    <DatePicker
                      selected={
                        fileContext.intakeFields?.intakeDate
                          ? new Date(fileContext.intakeFields?.intakeDate)
                          : undefined
                      }
                      name={'intake-date'}
                      onChange={(date) => {
                        fileContext.setIntakeFields(
                          (s) =>
                            ({
                              ...s,
                              intakeDate: date ? date.getTime() : null,
                            } as IIntakeFields)
                        );
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={!props.isEditingAllowed || stageIsCompleted}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Intake Details</Label>
                    <Input
                      type='textarea'
                      placeholder='Type here...'
                      style={{ minHeight: '80px' }}
                      name='intake-details'
                      value={fileContext.intakeFields?.intakeDetails}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setIntakeFields(
                          (s) =>
                            ({
                              ...s,
                              intakeDetails: e.target.value ? e.target.value : undefined,
                            } as IIntakeFields)
                        );
                        props.setIsDirty(true);
                      }}
                      disabled={!props.isEditingAllowed || stageIsCompleted}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Brief Concerns</Label>
                    <Input
                      type='textarea'
                      placeholder='Type here...'
                      style={{ minHeight: '80px' }}
                      name='brief-concerns'
                      value={fileContext.intakeFields?.briefConcerns}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setIntakeFields(
                          (s) =>
                            ({
                              ...s,
                              briefConcerns: e.target.value ? e.target.value : undefined,
                            } as IIntakeFields)
                        );
                        props.setIsDirty(true);
                      }}
                      disabled={!props.isEditingAllowed || stageIsCompleted}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Custody Information</Label>
                    <Input
                      type='textarea'
                      placeholder='Type here...'
                      style={{ minHeight: '80px' }}
                      name='custody-information'
                      value={fileContext.intakeFields?.custodyInformation}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setIntakeFields(
                          (s) =>
                            ({
                              ...s,
                              custodyInformation: e.target.value ? e.target.value : undefined,
                            } as IIntakeFields)
                        );
                        props.setIsDirty(true);
                      }}
                      disabled={!props.isEditingAllowed || stageIsCompleted}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Current Living Situation</Label>
                    <Input
                      type='textarea'
                      placeholder='Type here...'
                      style={{ minHeight: '80px' }}
                      name='current-living-situation'
                      value={fileContext.intakeFields?.currentLivingSituation}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setIntakeFields(
                          (s) =>
                            ({
                              ...s,
                              currentLivingSituation: e.target.value ? e.target.value : undefined,
                            } as IIntakeFields)
                        );
                        props.setIsDirty(true);
                      }}
                      disabled={!props.isEditingAllowed || stageIsCompleted}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Experiencing Ongoing Poverty/Hardship</Label>
                    {!stageIsCompleted ? (
                      <ButtonGroup className={'mr-auto file-list-btn-group mb-2'}>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  experiencingOngoingPovertyOrHardship: true,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={fileContext.intakeFields?.experiencingOngoingPovertyOrHardship}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          Yes
                        </Button>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  experiencingOngoingPovertyOrHardship: false,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={!fileContext.intakeFields?.experiencingOngoingPovertyOrHardship}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    ) : fileContext.intakeFields?.experiencingOngoingPovertyOrHardship ? (
                      <div className={'mb-2'}>Yes</div>
                    ) : (
                      <div className={'mb-2'}>No</div>
                    )}
                    {fileContext.intakeFields?.experiencingOngoingPovertyOrHardship && (
                      <Input
                        type='textarea'
                        placeholder='Type here...'
                        style={{ minHeight: '80px' }}
                        name='experiencing-poverty-or-hardship-details'
                        value={fileContext.intakeFields?.experiencingOngoingPovertyOrHardshipDetails}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          fileContext.setIntakeFields(
                            (s) =>
                              ({
                                ...s,
                                experiencingOngoingPovertyOrHardshipDetails: e.target.value
                                  ? e.target.value
                                  : undefined,
                              } as IIntakeFields)
                          );
                          props.setIsDirty(true);
                        }}
                        disabled={!props.isEditingAllowed || stageIsCompleted}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Experiencing Violence</Label>
                    {!stageIsCompleted ? (
                      <ButtonGroup className={'mr-auto file-list-btn-group mb-2'}>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  experiencingViolence: true,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={fileContext.intakeFields?.experiencingViolence || stageIsCompleted}
                        >
                          Yes
                        </Button>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  experiencingViolence: false,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={!fileContext.intakeFields?.experiencingViolence || stageIsCompleted}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    ) : fileContext.intakeFields?.experiencingViolence ? (
                      <div className={'mb-2'}>Yes</div>
                    ) : (
                      <div className={'mb-2'}>No</div>
                    )}
                    {fileContext.intakeFields?.experiencingViolence && (
                      <Input
                        type='textarea'
                        placeholder='Type here...'
                        style={{ minHeight: '80px' }}
                        name='experiencing-violence-details'
                        value={fileContext.intakeFields?.experiencingViolenceDetails}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          fileContext.setIntakeFields(
                            (s) =>
                              ({
                                ...s,
                                experiencingViolenceDetails: e.target.value ? e.target.value : undefined,
                              } as IIntakeFields)
                          );
                          props.setIsDirty(true);
                        }}
                        disabled={!props.isEditingAllowed || stageIsCompleted}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Identifiable Goals</Label>
                    <Input
                      type='textarea'
                      placeholder='Type here...'
                      style={{ minHeight: '80px' }}
                      name='identifiable-goals'
                      value={fileContext.intakeFields?.identifiableGoals}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setIntakeFields(
                          (s) =>
                            ({
                              ...s,
                              identifiableGoals: e.target.value ? e.target.value : '',
                            } as IIntakeFields)
                        );
                        props.setIsDirty(true);
                      }}
                      disabled={!props.isEditingAllowed || stageIsCompleted}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Next Steps/Follow Up</Label>
                    <Input
                      type='textarea'
                      placeholder='Type here...'
                      style={{ minHeight: '80px' }}
                      name='next-steps'
                      value={fileContext.intakeFields?.nextSteps}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setIntakeFields(
                          (s) =>
                            ({
                              ...s,
                              nextSteps: e.target.value ? e.target.value : '',
                            } as IIntakeFields)
                        );
                        props.setIsDirty(true);
                      }}
                      disabled={!props.isEditingAllowed || stageIsCompleted}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className={'mb-4'}>
            <CardHeader>Child Protection History</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>FCS Involvement</Label>
                    {!stageIsCompleted ? (
                      <ButtonGroup className={'mr-auto file-list-btn-group mb-2'}>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  fcsInvolvement: true,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={fileContext.intakeFields?.fcsInvolvement}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          Yes
                        </Button>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  fcsInvolvement: false,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={!fileContext.intakeFields?.fcsInvolvement}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    ) : fileContext.intakeFields?.fcsInvolvement ? (
                      <div className={'mb-2'}>Yes</div>
                    ) : (
                      <div className={'mb-2'}>No</div>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>EFCA</Label>
                    {!stageIsCompleted ? (
                      <ButtonGroup className={'mr-auto file-list-btn-group mb-2'}>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  efca: true,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={fileContext.intakeFields?.efca}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          Yes
                        </Button>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  efca: false,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={!fileContext.intakeFields?.efca}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    ) : fileContext.intakeFields?.efca ? (
                      <div className={'mb-2'}>Yes</div>
                    ) : (
                      <div className={'mb-2'}>No</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className={'mb-4'}>
            <CardHeader>Jordan&apos;s Principle Eligibility</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Complex Medical Needs</Label>
                    {!stageIsCompleted ? (
                      <ButtonGroup className={'mr-auto file-list-btn-group mb-2'}>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  complexMedicalNeeds: true,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={fileContext.intakeFields?.complexMedicalNeeds}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          Yes
                        </Button>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  complexMedicalNeeds: false,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={!fileContext.intakeFields?.complexMedicalNeeds}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    ) : fileContext.intakeFields?.complexMedicalNeeds ? (
                      <div className={'mb-2'}>Yes</div>
                    ) : (
                      <div className={'mb-2'}>No</div>
                    )}
                    {fileContext.intakeFields?.complexMedicalNeeds && (
                      <Input
                        type='textarea'
                        placeholder='Type here...'
                        style={{ minHeight: '80px' }}
                        name='complex-medical-needs-details'
                        value={fileContext.intakeFields?.complexMedicalNeedsDetails}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          fileContext.setIntakeFields(
                            (s) =>
                              ({
                                ...s,
                                complexMedicalNeedsDetails: e.target.value ? e.target.value : undefined,
                              } as IIntakeFields)
                          );
                          props.setIsDirty(true);
                        }}
                        disabled={!props.isEditingAllowed || stageIsCompleted}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>
                      Children/Families who have emergencies: natural disasters, bed bugs, immediate family emergency
                      (treatment/death) eviction/homeless, heating (fuel/wood/pellets)
                    </Label>
                    {!stageIsCompleted ? (
                      <ButtonGroup className={'mr-auto file-list-btn-group mb-2'}>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  emergencies: true,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={fileContext.intakeFields?.emergencies}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          Yes
                        </Button>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  emergencies: false,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={!fileContext.intakeFields?.emergencies}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    ) : fileContext.intakeFields?.emergencies ? (
                      <div className={'mb-2'}>Yes</div>
                    ) : (
                      <div className={'mb-2'}>No</div>
                    )}
                    {fileContext.intakeFields?.emergencies && (
                      <Input
                        type='textarea'
                        placeholder='Type here...'
                        style={{ minHeight: '80px' }}
                        name='emergencies-details'
                        value={fileContext.intakeFields?.emergenciesDetails}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          fileContext.setIntakeFields(
                            (s) =>
                              ({
                                ...s,
                                emergenciesDetails: e.target.value ? e.target.value : undefined,
                              } as IIntakeFields)
                          );
                          props.setIsDirty(true);
                        }}
                        disabled={!props.isEditingAllowed || stageIsCompleted}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Family Connection/Reunification</Label>
                    {!stageIsCompleted ? (
                      <ButtonGroup className={'mr-auto file-list-btn-group mb-2'}>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  familyConnectionOrReunification: true,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={fileContext.intakeFields?.familyConnectionOrReunification}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          Yes
                        </Button>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  familyConnectionOrReunification: false,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={!fileContext.intakeFields?.familyConnectionOrReunification}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    ) : fileContext.intakeFields?.familyConnectionOrReunification ? (
                      <div className={'mb-2'}>Yes</div>
                    ) : (
                      <div className={'mb-2'}>No</div>
                    )}
                    {fileContext.intakeFields?.familyConnectionOrReunification && (
                      <Input
                        type='textarea'
                        placeholder='Type here...'
                        style={{ minHeight: '80px' }}
                        name='family-connection-or-reunification-details'
                        value={fileContext.intakeFields?.familyConnectionOrReunificationDetails}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          fileContext.setIntakeFields(
                            (s) =>
                              ({
                                ...s,
                                familyConnectionOrReunificationDetails: e.target.value ? e.target.value : undefined,
                              } as IIntakeFields)
                          );
                          props.setIsDirty(true);
                        }}
                        disabled={!props.isEditingAllowed || stageIsCompleted}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>Cultural Connections</Label>
                    {!stageIsCompleted ? (
                      <ButtonGroup className={'mr-auto file-list-btn-group mb-2'}>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  culturalConnections: true,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={fileContext.intakeFields?.culturalConnections}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          Yes
                        </Button>
                        <Button
                          color='primary'
                          outline
                          style={buttonToggleStyles}
                          onClick={() => {
                            fileContext.setIntakeFields(
                              (s) =>
                                ({
                                  ...s,
                                  culturalConnections: false,
                                } as IIntakeFields)
                            );
                            props.setIsDirty(true);
                          }}
                          active={!fileContext.intakeFields?.culturalConnections}
                          disabled={!props.isEditingAllowed || stageIsCompleted}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    ) : fileContext.intakeFields?.culturalConnections ? (
                      <div className={'mb-2'}>Yes</div>
                    ) : (
                      <div className={'mb-2'}>No</div>
                    )}
                    {fileContext.intakeFields?.culturalConnections && (
                      <Input
                        type='textarea'
                        placeholder='Type here...'
                        style={{ minHeight: '80px' }}
                        name='cultural-connections-details'
                        value={fileContext.intakeFields?.culturalConnectionsDetails}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          fileContext.setIntakeFields(
                            (s) =>
                              ({
                                ...s,
                                culturalConnectionsDetails: e.target.value ? e.target.value : undefined,
                              } as IIntakeFields)
                          );
                          props.setIsDirty(true);
                        }}
                        disabled={!props.isEditingAllowed || stageIsCompleted}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm={9} md={9} lg={9} xl={3}>
          <Card className={'mb-4'}>
            <CardHeader>File Details</CardHeader>
            <CardBody>
              {myUser.isSuperAdmin && (
                <Row className='square border-bottom mb-2'>
                  <Col>
                    <FormGroup>
                      <Label for={'isConfidential'}>Confidentiality</Label>
                      <CustomInput
                        type='checkbox'
                        id={'isConfidential'}
                        name={'isConfidential'}
                        label={'Highly Confidential File'}
                        checked={fileContext.isConfidential}
                        onChange={() => {
                          if (fileContext.isConfidential) {
                            props.setOpenUncheckConfidentialityModal(true);
                          } else {
                            props.setOpenCheckConfidentialityModal(true);
                          }
                          fileContext.setIsConfidential(!fileContext.isConfidential);
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'stageId'}>Stage</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'stageId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={stages.filter(
                        (s) =>
                          s.fileTypes &&
                          s.fileTypes.includes(fileContext.fileTypeId ? fileContext.fileTypeId : '') &&
                          s.fileSubTypes.includes(fileContext.fileSubTypeId ? fileContext.fileSubTypeId : '')
                      )}
                      value={stages.find((s: IStage) => s.stageId === fileContext.stageId)}
                      onChange={(value: any) => {
                        fileContext.setStageId(value?.stageId || '');
                        if (value?.stageId === STAGE_COMPLETED) {
                          props.setIntakeFileStageChangedToCompleted(true);
                        } else {
                          props.setIntakeFileStageChangedToCompleted(false);
                        }
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IStage) => option.stageId}
                      getOptionLabel={(option: IStage) => option.name}
                      isDisabled={!props.isEditingAllowed}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <RequiredLabel for={'application-date'} required={fileContext.createdDate === undefined}>
                      Created Date
                    </RequiredLabel>
                    <DatePicker
                      selected={fileContext.createdDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCreatedDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                    {props.trySave && fileContext.createdDate === undefined && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'staffIds'}>Staff</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'staffIds'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={
                        fileContext.isConfidential
                          ? staff
                              .sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName))
                              .filter(
                                (u) =>
                                  (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                  u.departmentAccessIds.includes(DEPARTMENT_INTAKE) &&
                                  u.role === 'SuperAdmin'
                              )
                          : staff
                              .sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName))
                              .filter(
                                (u) =>
                                  (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                  u.departmentAccessIds.includes(DEPARTMENT_INTAKE)
                              )
                      }
                      onChange={(value: any) => {
                        fileContext.setStaffIds(value.map((v: IUser) => v.userId));
                        props.setIsDirty(true);
                      }}
                      value={selectedStaff}
                      getOptionValue={(option: IUser) => option.userId}
                      getOptionLabel={(option: IUser) =>
                        option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                      }
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
