import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { authAxios } from '../../services/AxiosService';
import * as ToastService from '../../services/ToastService';
import { useHistory, useParams } from 'react-router';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { IContactDetails } from '../Clients/ClientContext';
import { IGladueMember } from './GladueMembersTable';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { DomainContext } from '../../contexts/DomainContext';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { disabledSelectStylesWithMenu } from '../../utils/StylingUtils';

export const GladueMemberDetails: React.FC = () => {
  const params = useParams<{ gladueMemberId: string }>();
  const domainContext = useContext(DomainContext);
  const yukonFns = domainContext.yukonFns;
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactDetails, setContactDetails] = useState<IContactDetails[]>([{ contactType: '', details: '' }]);
  const [firstNation, setFirstNation] = useState('');
  const [location, setLocation] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [roles, setRoles] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  useEffect(() => {
    if (params.gladueMemberId) {
      authAxios
        .get('/api/gladue-members/' + params.gladueMemberId)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.includes('KO')) {
            history.push('/404');
          } else if (response.data) {
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
            setContactDetails(response.data.contactDetails);
            setFirstNation(response.data.firstNation);
            setLocation(response.data.location);
            setIsActive(response.data.isActive);
            setRoles(response.data.roles);
          }
        })
        .catch((response) => {
          history.push('/404');
        });
    }
  }, [params.gladueMemberId]);

  const formFullyFilled = () => {
    return firstName !== '' && lastName !== '';
  };

  const saveMemberDetails = () => {
    if (!formFullyFilled()) {
      setTrySubmit(true);
      return;
    }
    if (params.gladueMemberId) {
      authAxios
        .post('/api/gladue-members/update', {
          gladueMemberId: params.gladueMemberId,
          firstName: firstName,
          lastName: lastName,
          contactDetails: contactDetails,
          firstNation: firstNation,
          location: location,
          isActive: isActive,
          roles: roles,
        } as IGladueMember)
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Gladue Member Updated');
            setIsDirty(false);
          } else {
            ToastService.notifyError(response.data.errorMessage);
          }
        })
        .catch((error) => {
          ToastService.notifyError('Error:' + error);
        });
    } else {
      authAxios
        .post('/api/gladue-members/create', {
          firstName,
          lastName,
          contactDetails,
          firstNation,
          location,
          isActive,
          roles,
        } as IGladueMember)
        .then((response) => {
          if (response.data.status === 'OK') {
            ToastService.notifySuccess('Gladue Member Created');
            setIsDirty(false);
            history.push('/gladue-members/' + response.data.gladueMemberId);
          } else {
            ToastService.notifyError('Error Creating Gladue Member - ' + response.data.desc);
          }
        });
    }
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span
        style={{
          backgroundColor: '#EBECF0',
          borderRadius: '2em',
          color: '#172B4D',
          display: 'inline-block',
          fontSize: 12,
          fontWeight: 'normal',
          lineHeight: '1',
          minWidth: 1,
          padding: '0.16666666666667em 0.5em',
          textAlign: 'center',
        }}
      >
        {data.options.length}
      </span>
    </div>
  );

  const roleOptions = [
    { value: 'Writer', label: 'Writer' },
    { value: 'Mentee', label: 'Mentee' },
    { value: 'Mentor', label: 'Mentor' },
  ];

  const contactTypeOptions = [
    { value: 'Phone Number', label: 'Phone Number' },
    { value: 'Email', label: 'Email' },
    { value: 'Address', label: 'Address' },
  ];

  return (
    <div>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <Navbar color={'light'} light={true} expand={'xs'}>
          <Row className={'flex-fill'}>
            <Col md={3} xl={2}>
              <NavbarBrand>{params.gladueMemberId ? 'Edit Member' : 'Add New Member'}</NavbarBrand>
            </Col>
            {params.gladueMemberId ? (
              <Col className={'d-flex'}>
                <Button color={'primary'} className={'ml-auto'} onClick={() => saveMemberDetails()}>
                  Save Details
                </Button>
              </Col>
            ) : (
              <Col className={'d-flex'}>
                <Button color={'link'} className={'ml-auto'} onClick={() => history.push('/gladue-members')}>
                  Cancel
                </Button>
                <Button color={'primary'} className={'ml-2'} onClick={() => saveMemberDetails()}>
                  Save Details
                </Button>
              </Col>
            )}
          </Row>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col>
              <Row className='pt-3'>
                <FormGroup className='col-sm-6'>
                  <Label>
                    First Name <span className='text-required'>(required)</span>
                  </Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='name'
                    value={firstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstName(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && firstName === ''}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </FormGroup>
                <FormGroup className='col-sm-6'>
                  <Label>
                    Last Name <span className='text-required'>(required)</span>
                  </Label>
                  <Input
                    type='text'
                    placeholder=''
                    name='lastName'
                    value={lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLastName(e.target.value);
                      setIsDirty(true);
                    }}
                    invalid={trySubmit && lastName === ''}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </FormGroup>
              </Row>
              <Row className={'mb-3'}>
                <Col style={{ width: 100 }}>
                  <Label>First Nation</Label>
                  <CreatableSelect
                    styles={disabledSelectStylesWithMenu}
                    formatCreateLabel={(inputValue: string) => inputValue}
                    formatGroupLabel={formatGroupLabel}
                    placeholder={'Select or Type…'}
                    options={yukonFns.map((yFN) => ({ value: yFN.yukonFirstNationId, label: yFN.name }))}
                    value={
                      firstNation && firstNation !== ''
                        ? {
                            value: firstNation,
                            label: domainContext.yukonFns.find((fn) => fn.yukonFirstNationId === firstNation)
                              ? domainContext.yukonFns.find((fn) => fn.yukonFirstNationId === firstNation)?.name
                              : firstNation,
                          }
                        : undefined
                    }
                    onChange={(val) => {
                      setIsDirty(true);
                      setFirstNation(val ? val.value : '');
                    }}
                    isMulti={false}
                    isClearable={true}
                  />
                </Col>
              </Row>
              <Row>
                <FormGroup className='col-sm-6'>
                  <Label>Location</Label>
                  <Input
                    type='text'
                    placeholder='Type...'
                    name='location'
                    value={location}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLocation(e.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormGroup>
                <FormGroup className='col-sm-6'>
                  <Label>Role</Label>
                  <Select
                    styles={disabledSelectStylesWithMenu}
                    name={'roles'}
                    options={roleOptions.filter((o) => o.value !== 'Mentee')}
                    value={roleOptions.filter((r) => roles && roles.includes(r.value))}
                    onChange={(value: any) => {
                      setRoles(value.map((r: any) => r.value));
                      setIsDirty(true);
                    }}
                    getOptionValue={(option: any) => option.value}
                    getOptionLabel={(option: any) => option.label}
                    isMulti={true}
                  />
                </FormGroup>
              </Row>
              <Row>
                <Label className={'pl-3'}>Contact Details</Label>
              </Row>
              {contactDetails &&
                contactDetails.map((ci: IContactDetails, index: number) => (
                  <Row key={index} className={'mb-2 mb-4 mb-sm-2'}>
                    <Col sm={6}>
                      <CreatableSelect
                        styles={disabledSelectStylesWithMenu}
                        formatCreateLabel={(inputValue: string) => inputValue}
                        placeholder={'Type or Select…'}
                        options={[
                          ...contactTypeOptions,
                          ...(!ci.contactType || contactTypeOptions.some((e) => e.value === ci.contactType)
                            ? []
                            : [{ value: ci.contactType, label: ci.contactType }]),
                        ]}
                        value={ci.contactType ? { value: ci.contactType, label: ci.contactType } : undefined}
                        onChange={(val) => {
                          setIsDirty(true);
                          setContactDetails((acd) => [
                            ...acd.slice(0, index),
                            { ...acd[index], contactType: val ? val.value : '' },
                            ...acd.slice(index + 1),
                          ]);
                        }}
                        isMulti={false}
                      />
                    </Col>
                    <Col className={'pr-0  mt-1 mt-sm-0'}>
                      <Input
                        type='text'
                        placeholder='Type...'
                        name='details'
                        value={ci.details}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setContactDetails((acd) => [
                            ...acd.slice(0, index),
                            { ...acd[index], details: e ? e.target.value : '' },
                            ...acd.slice(index + 1),
                          ]);
                          setIsDirty(true);
                        }}
                        invalid={trySubmit && lastName === ''}
                      />
                    </Col>
                    <Col style={{ maxWidth: '50px' }} className={'pl-0   mt-1 mt-sm-0'}>
                      <Button
                        color={'link'}
                        className={'text-danger'}
                        onClick={() => {
                          setIsDirty(true);
                          setContactDetails((oldC) => [...oldC.slice(0, index), ...oldC.slice(index + 1)]);
                        }}
                      >
                        <FaTimes />
                      </Button>
                    </Col>
                  </Row>
                ))}
              <Row>
                <Button
                  size={'sm'}
                  className={'mb-3 mt-2 ml-3'}
                  color={'primary'}
                  onClick={() => {
                    setContactDetails((ci: IContactDetails[]) =>
                      ci ? [...ci, { contactType: '', details: '' }] : [{ contactType: '', details: '' }]
                    );
                  }}
                >
                  <FaPlus />
                  Add Another Contact Detail
                </Button>
              </Row>
              <Row>
                <FormGroup className='col-sm-6'>
                  <CustomInput
                    type='checkbox'
                    id={'active'}
                    name={'active'}
                    label={'Active'}
                    checked={isActive}
                    onChange={() => {
                      setIsActive((s) => !s);
                      setIsDirty(true);
                    }}
                  />
                  <FormFeedback>This field is mandatory</FormFeedback>
                </FormGroup>
              </Row>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};
