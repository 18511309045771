import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Navbar,
  NavbarBrand,
  Row,
} from 'reactstrap';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { FileContext, IPayment, IRequest } from './FileContext';
import {
  DomainContext,
  FILE_TYPE_JORDANS_PRINCIPLE,
  JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE,
  JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER,
  REQUEST_OUTCOME_APPROVED,
  REQUEST_OUTCOME_PARTIAL_APPROVAL,
} from '../../contexts/DomainContext';
import { useWindowDimensions } from '../../hooks/WindowHooks';
import { authAxios } from '../../services/AxiosService';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { useParams } from 'react-router';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { MyUserContext } from '../../contexts/MyUserContext';
import { UsersContext } from '../../contexts/UsersContext';
import { disabledSelectStyles } from '../../utils/StylingUtils';

export const FileFinancialTracking = () => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const usersContext = useContext(UsersContext);
  const payments = fileContext.payments;
  const setPayments = fileContext.setPayments;
  const requests = fileContext.requests;
  const allCategories = domainContext.allCategories;
  const frequencyOptions = domainContext.frequencies;
  const outcomeOptions = domainContext.allOutcomes;
  const vendorsOrServiceProviders = domainContext.vendorsOrServiceProviders;
  const vendorsOrServiceProvidersOptions = vendorsOrServiceProviders.map((v) => ({
    value: v.vendorOrServiceProviderId,
    label: v.name,
  }));
  const staff = usersContext.users;
  const myUser = useContext(MyUserContext);
  const [changedRequestLength] = useState(0);
  const { xs, sm } = useWindowDimensions();
  const mobile = xs || sm;
  const params = useParams<{ fileId: string; tab?: string }>();
  const [isDirty, setIsDirty] = useState(false);
  const [isEditingAllowed] = useState(true);

  const submittedToFinanceOptions = [
    { val: true, display: 'Yes' },
    { val: false, display: 'No' },
  ];

  const formatCurrencyCommas = (amount: string | undefined) => {
    if (amount) {
      return amount
        .toString()
        .replace(/[, ]+/g, '')
        .replace(/-[^0-9.]/g, '')
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const formatCurrencyCommasTwoDigits = (amount: string | undefined) => {
    if (amount) {
      const floatToUse = parseFloat(amount.toString().replace(/[, ]+/g, ''));
      return Number.isNaN(floatToUse)
        ? '0.00'
        : floatToUse
            .toFixed(2)
            .toString()
            .replace(/-[^0-9.]/g, '')
            .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (changedRequestLength > 0) {
      var elmt2 = document.getElementById('select-field');
      if (elmt2) {
        elmt2.focus();
        elmt2.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [changedRequestLength]);

  const formattedAmountToNumber = (amount: string | undefined) => {
    if (amount) {
      if (amount.length > 0 && amount.charAt(0) === '-' && amount !== '-') {
        return -Math.abs(Number(amount.toString().replace(/[, ]+|[- ]+/g, '')));
      } else {
        return Number(amount.toString().replace(/[,]+|[- ]+/g, ''));
      }
    } else {
      return 0;
    }
  };

  const calculateTotal = (cost: string | undefined, duration: string | undefined) => {
    if (cost && duration) {
      const total = formattedAmountToNumber(cost) * Number(duration);
      return formatCurrencyCommasTwoDigits(total.toString());
    } else {
      return '';
    }
  };

  const allTotals = () => {
    let requestTotal = 0;
    requests
      .filter(
        (r) =>
          r.requestId && (r.outcomeId === REQUEST_OUTCOME_APPROVED || r.outcomeId === REQUEST_OUTCOME_PARTIAL_APPROVAL)
      )
      .forEach((r) => {
        requestTotal = requestTotal + formattedAmountToNumber(calculateTotal(r.cost, r.duration));
      });
    let paymentTotal = 0;
    payments
      .filter(
        (p) =>
          p.requestId &&
          requests.find(
            (r) =>
              p.requestId === r.requestId &&
              (r.outcomeId === REQUEST_OUTCOME_APPROVED || r.outcomeId === REQUEST_OUTCOME_PARTIAL_APPROVAL)
          )
      )
      .forEach((p) => {
        paymentTotal = paymentTotal + formattedAmountToNumber(p.amount);
      });
    return formatCurrencyCommasTwoDigits((requestTotal - paymentTotal).toString());
  };

  const requestBalance = (request: IRequest) => {
    const requestSum = formattedAmountToNumber(calculateTotal(request.cost, request.duration));
    let paymentSum = 0;
    payments
      .filter((p) => p.requestId === request.requestId)
      .forEach((p) => {
        paymentSum = paymentSum + formattedAmountToNumber(p.amount);
      });
    return formatCurrencyCommasTwoDigits((requestSum - paymentSum).toString());
  };

  const getUserName = (userId: string | undefined) => {
    const user = staff.find((s) => s.userId === userId);
    if (user) {
      return user.lastName + ', ' + user.firstName;
    } else {
      return 'N/A';
    }
  };

  const saveFile = () => {
    if (fileContext.fileTypeId && fileContext.stageId && fileContext.createdDate) {
      if (params.fileId) {
        const data = fileContext.getEditData();

        authAxios.post('/api/files/update', data).then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('File updated.');
            fileContext.setFileFromAttributes();
          } else {
            notifyError(response.data.errorMessage);
          }
        });
        let JPData = fileContext.getJordansPrincipleData(params.fileId);
        JPData.payments = JPData.payments?.sort((a: IPayment, b: IPayment) => {
          if (a.paymentDate && b.paymentDate) {
            return a.paymentDate - b.paymentDate;
          } else {
            if (a.paymentDate === 0) {
              return 1;
            }
            if (b.paymentDate === 0) {
              return -1;
            }
            return 0;
          }
        });
        authAxios.post('/api/files/update-jordans-principle', JPData).then((response) => {
          if (response.data.status === 'OK') {
            console.log('JP updated :)');
          } else {
            console.log('JP not updated :(');
          }
        });
      }
    }
  };

  return (
    <>
      <LeavePageConfirm isDirty={isDirty} />
      <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom d-flex flex-wrap sticky-top'}>
        <NavbarBrand className={'w-mobile-100'}>Financial Tracking</NavbarBrand>
        <div
          className={'d-flex flex-grow-1 justify-content-end flex-wrap navbar-actions'}
          style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
        >
          {params.fileId && (
            <Button color={'primary'} className={'w-mobile-100'} onClick={() => saveFile()}>
              Save Financial Details
            </Button>
          )}
        </div>
      </Navbar>
      <Container fluid={true} className={'my-3'}>
        <ListGroup flush={true}>
          {requests.filter(
            (r) =>
              r.requestId &&
              (r.outcomeId === REQUEST_OUTCOME_APPROVED || r.outcomeId === REQUEST_OUTCOME_PARTIAL_APPROVAL)
          ).length > 0 ? (
            requests
              .filter(
                (r) =>
                  r.requestId &&
                  (r.outcomeId === REQUEST_OUTCOME_APPROVED || r.outcomeId === REQUEST_OUTCOME_PARTIAL_APPROVAL)
              )
              .map((r, rIndex) => (
                <>
                  <Card className={'mb-3'}>
                    <CardHeader className={'align-items-center'}>
                      {r.description ? 'Request - ' + r.description : 'Request'}
                    </CardHeader>
                    <CardBody className={'p-0'}>
                      <ListGroupItem key={rIndex} className={'py-3'}>
                        <div className={'d-flex'}>
                          <div className={'flex-grow-1'}>
                            <Row style={{ paddingRight: 5 }}>
                              <Col
                                sm={12}
                                md={'auto'}
                                style={!mobile ? { flexGrow: 2, flexShrink: 2, flexBasis: 0 } : {}}
                              >
                                <Label>Category</Label>
                                <div>{allCategories.find((c: any) => c.categoryId === r.categoryId)?.name || '-'}</div>
                              </Col>
                              {fileContext.fileTypeId === FILE_TYPE_JORDANS_PRINCIPLE &&
                                (fileContext.jordansPrincipleFields?.requestTypeId ===
                                  JORDANS_PRINCIPLE_REQUEST_TYPE_NECESSITIES_OF_LIFE ||
                                  fileContext.jordansPrincipleFields?.requestTypeId ===
                                    JORDANS_PRINCIPLE_REQUEST_TYPE_OTHER) && (
                                  <Col
                                    sm={12}
                                    md={'auto'}
                                    style={!mobile ? { flexGrow: 0.7, flexShrink: 0, flexBasis: 0, minWidth: 80 } : {}}
                                  >
                                    <Label># of Children</Label>
                                    <div>{r.numberOfChildren || '-'}</div>
                                  </Col>
                                )}
                              <Col
                                sm={12}
                                md={'auto'}
                                style={!mobile ? { flexGrow: 2, flexShrink: 2, flexBasis: 0 } : {}}
                              >
                                <Label>Description</Label>
                                <div>{r.description || '-'}</div>
                              </Col>
                              <Col
                                sm={12}
                                md={'auto'}
                                style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                              >
                                <Label>Cost ($)</Label>
                                <div>{r.cost || '-'}</div>
                              </Col>
                              <Col
                                sm={12}
                                md={'auto'}
                                style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                              >
                                <Label>Frequency</Label>
                                <div>
                                  {frequencyOptions.find((ft: any) => ft.frequencyId === r.frequencyId)?.name || '-'}
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                md={'auto'}
                                style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                              >
                                <Label>Duration</Label>
                                <div>{r.duration || '-'}</div>
                              </Col>
                              <Col
                                sm={12}
                                md={'auto'}
                                style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                              >
                                <Label>Service Provider</Label>
                                <div>{r.serviceProvider || '-'}</div>
                              </Col>
                              <Col
                                sm={12}
                                md={'auto'}
                                style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                              >
                                <Label>Outcome</Label>
                                <div>{outcomeOptions.find((ft: any) => ft.outcomeId === r.outcomeId)?.name || '-'}</div>
                              </Col>
                              <Col
                                sm={12}
                                md={'auto'}
                                style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                              >
                                <Label>Total ($)</Label>
                                <div>{calculateTotal(r.cost, r.duration) || '-'}</div>
                              </Col>
                            </Row>
                            <Card className={'mt-2'}>
                              <CardHeader className={'d-flex align-items-center'}>{'Payments'}</CardHeader>
                              {payments &&
                                payments
                                  .map((payment, index) => {
                                    return {
                                      payment: payment,
                                      index: index,
                                    };
                                  })
                                  .filter((p) => {
                                    return p.payment.requestId === r.requestId;
                                  }).length > 0 && (
                                  <ListGroupItem className={'py-2 mb-3 d-none d-md-block border-bottom'}>
                                    <Row style={{ width: '98.5%' }} className='row--condensed'>
                                      <Col style={{ flexGrow: 2.1 }}>
                                        <Label>Vendor/Service Provider</Label>
                                      </Col>
                                      <Col style={{ flexGrow: 1 }}>
                                        <Label>Payment Date</Label>
                                      </Col>
                                      <Col style={{ flexGrow: 1 }}>
                                        <Label>Payment Type</Label>
                                      </Col>
                                      <Col style={{ flexGrow: 1 }}>
                                        <Label>PO / Cheque #</Label>
                                      </Col>
                                      <Col style={{ flexGrow: 1 }}>
                                        <Label>Date Receipt Received</Label>
                                      </Col>
                                      <Col style={{ flexGrow: 1 }}>
                                        <Label>Submitted To Finance</Label>
                                      </Col>
                                      <Col style={{ flexGrow: 1 }}>
                                        <Label>Submitted Date</Label>
                                      </Col>
                                      <Col style={{ flexGrow: 1 }}>
                                        <Label>Created By</Label>
                                      </Col>
                                      <Col style={{ flexGrow: 0.75 }}>
                                        <Label>Amount ($)</Label>
                                      </Col>
                                    </Row>
                                  </ListGroupItem>
                                )}
                              {/*Create a projection to get original indices*/}
                              {payments
                                .map((payment, index) => {
                                  return {
                                    payment: payment,
                                    index: index,
                                  };
                                })
                                .filter((p) => {
                                  return p.payment.requestId === r.requestId;
                                })
                                .map((p, pIndex) => (
                                  <ListGroupItem key={pIndex} className={'py-1'}>
                                    <div className={'d-flex'}>
                                      <div className={'flex-grow-1'}>
                                        <Row style={{ width: '98%' }} className='row--condensed'>
                                          <Col
                                            sm={12}
                                            md={'auto'}
                                            style={!mobile ? { flexGrow: 2, flexShrink: 2, flexBasis: 0 } : {}}
                                          >
                                            <CreatableSelect
                                              formatCreateLabel={(inputValue: string) => inputValue}
                                              placeholder={<span className='placeholder'>Select or Type...</span>}
                                              styles={disabledSelectStyles}
                                              options={[
                                                ...vendorsOrServiceProvidersOptions,
                                                ...(!p.payment.vendorOrServiceProvider ||
                                                vendorsOrServiceProvidersOptions.some(
                                                  (e) => e.value === p.payment.vendorOrServiceProvider
                                                )
                                                  ? []
                                                  : [
                                                      {
                                                        value: p.payment.vendorOrServiceProvider,
                                                        label: p.payment.vendorOrServiceProvider,
                                                      },
                                                    ]),
                                              ]}
                                              value={
                                                p.payment.vendorOrServiceProvider
                                                  ? vendorsOrServiceProvidersOptions
                                                      .map((v) => v.value)
                                                      .includes(p.payment.vendorOrServiceProvider)
                                                    ? {
                                                        value: p.payment.vendorOrServiceProvider,
                                                        label: vendorsOrServiceProvidersOptions.find(
                                                          (v) => v.value === p.payment.vendorOrServiceProvider
                                                        )?.label,
                                                      }
                                                    : {
                                                        value: p.payment.vendorOrServiceProvider,
                                                        label: p.payment.vendorOrServiceProvider,
                                                      }
                                                  : undefined
                                              }
                                              onChange={(val) => {
                                                setIsDirty(true);
                                                setPayments((oldP) => [
                                                  ...oldP.slice(0, p.index),
                                                  {
                                                    ...oldP[p.index],
                                                    vendorOrServiceProvider: val ? val.value : '',
                                                  },
                                                  ...oldP.slice(p.index + 1),
                                                ]);
                                              }}
                                              isMulti={false}
                                              isClearable={true}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={'auto'}
                                            style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                                          >
                                            <DatePicker
                                              selected={
                                                p.payment.paymentDate ? new Date(p.payment.paymentDate) : undefined
                                              }
                                              onChange={(pPaymentDate) => {
                                                if (pPaymentDate) {
                                                  setPayments((oldP) => [
                                                    ...oldP.slice(0, p.index),
                                                    { ...oldP[p.index], paymentDate: pPaymentDate.getTime() },
                                                    ...oldP.slice(p.index + 1),
                                                  ]);
                                                  setIsDirty(true);
                                                } else {
                                                  setPayments((oldP) => [
                                                    ...oldP.slice(0, p.index),
                                                    { ...oldP[p.index], paymentDate: undefined },
                                                    ...oldP.slice(p.index + 1),
                                                  ]);
                                                  setIsDirty(true);
                                                }
                                              }}
                                              popperPlacement={'bottom-end'}
                                              isClearable={true}
                                              peekNextMonth
                                              showMonthDropdown
                                              disabled={!isEditingAllowed}
                                              showYearDropdown
                                              dateFormat='MMM dd, yyyy'
                                              dropdownMode='select'
                                              className={
                                                isEditingAllowed
                                                  ? 'date-picker rounded mb-0'
                                                  : 'date-picker-disabled rounded mb-0'
                                              }
                                              placeholderText={'MMM dd, yyyy'}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={'auto'}
                                            style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                                          >
                                            <Label className={'d-md-none'}>Payment Type</Label>
                                            <Input
                                              type={'text'}
                                              placeholder='Type here...'
                                              name='payment type'
                                              value={p.payment.paymentType}
                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const pPaymentType = e.target.value;
                                                setPayments((oldP) => [
                                                  ...oldP.slice(0, p.index),
                                                  { ...oldP[p.index], paymentType: pPaymentType },
                                                  ...oldP.slice(p.index + 1),
                                                ]);
                                                setIsDirty(true);
                                              }}
                                              disabled={!isEditingAllowed}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={'auto'}
                                            style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                                          >
                                            <Label className={'d-md-none'}>PO / Cheque #</Label>
                                            <Input
                                              type={'text'}
                                              placeholder='Type here...'
                                              name='PO / Cheque #'
                                              value={p.payment.poNumber}
                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const pPONumber = e.target.value;
                                                setPayments((oldP) => [
                                                  ...oldP.slice(0, p.index),
                                                  { ...oldP[p.index], poNumber: pPONumber },
                                                  ...oldP.slice(p.index + 1),
                                                ]);
                                                setIsDirty(true);
                                              }}
                                              disabled={!isEditingAllowed}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={'auto'}
                                            style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                                          >
                                            <DatePicker
                                              selected={
                                                p.payment.receiptReceivedDate
                                                  ? new Date(p.payment.receiptReceivedDate)
                                                  : undefined
                                              }
                                              onChange={(pReceiptReceivedDate) => {
                                                if (pReceiptReceivedDate) {
                                                  setPayments((oldP) => [
                                                    ...oldP.slice(0, p.index),
                                                    {
                                                      ...oldP[p.index],
                                                      receiptReceivedDate: pReceiptReceivedDate.getTime(),
                                                    },
                                                    ...oldP.slice(p.index + 1),
                                                  ]);
                                                  setIsDirty(true);
                                                } else {
                                                  setPayments((oldP) => [
                                                    ...oldP.slice(0, p.index),
                                                    { ...oldP[p.index], receiptReceivedDate: undefined },
                                                    ...oldP.slice(p.index + 1),
                                                  ]);
                                                  setIsDirty(true);
                                                }
                                              }}
                                              popperPlacement={'bottom-end'}
                                              isClearable={true}
                                              peekNextMonth
                                              showMonthDropdown
                                              disabled={!isEditingAllowed}
                                              showYearDropdown
                                              dateFormat='MMM dd, yyyy'
                                              dropdownMode='select'
                                              className={
                                                isEditingAllowed
                                                  ? 'date-picker rounded mb-0'
                                                  : 'date-picker-disabled rounded mb-0'
                                              }
                                              placeholderText={'MMM dd, yyyy'}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={'auto'}
                                            style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                                          >
                                            <Select
                                              styles={disabledSelectStyles}
                                              name={'submitted-to-finance'}
                                              options={submittedToFinanceOptions}
                                              placeholder={<span className='placeholder'>Select...</span>}
                                              value={submittedToFinanceOptions.find(
                                                (g) => g.val === p.payment.submittedToFinance
                                              )}
                                              type={'text'}
                                              onChange={(value: any) => {
                                                const pSubmittedToFinance = value?.val;
                                                setPayments((oldP) => [
                                                  ...oldP.slice(0, p.index),
                                                  {
                                                    ...oldP[p.index],
                                                    submittedToFinance: pSubmittedToFinance,
                                                    submittedDate:
                                                      pSubmittedToFinance === false
                                                        ? undefined
                                                        : oldP[p.index].submittedDate,
                                                  },
                                                  ...oldP.slice(p.index + 1),
                                                ]);
                                                setIsDirty(true);
                                              }}
                                              getOptionValue={(option: any) => option.val}
                                              getOptionLabel={(option: any) => option.display}
                                              isClearable={true}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={'auto'}
                                            style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                                          >
                                            <DatePicker
                                              selected={
                                                p.payment.submittedDate ? new Date(p.payment.submittedDate) : undefined
                                              }
                                              onChange={(pSubmittedDate) => {
                                                if (pSubmittedDate) {
                                                  setPayments((oldP) => [
                                                    ...oldP.slice(0, p.index),
                                                    { ...oldP[p.index], submittedDate: pSubmittedDate.getTime() },
                                                    ...oldP.slice(p.index + 1),
                                                  ]);
                                                  setIsDirty(true);
                                                } else {
                                                  setPayments((oldP) => [
                                                    ...oldP.slice(0, p.index),
                                                    { ...oldP[p.index], submittedDate: undefined },
                                                    ...oldP.slice(p.index + 1),
                                                  ]);
                                                  setIsDirty(true);
                                                }
                                              }}
                                              popperPlacement={'bottom-end'}
                                              isClearable={true}
                                              peekNextMonth
                                              showMonthDropdown
                                              disabled={!isEditingAllowed || p.payment.submittedToFinance === false}
                                              showYearDropdown
                                              dateFormat='MMM dd, yyyy'
                                              dropdownMode='select'
                                              className={
                                                isEditingAllowed
                                                  ? 'date-picker rounded mb-0'
                                                  : 'date-picker-disabled rounded mb-0'
                                              }
                                              placeholderText={'MMM dd, yyyy'}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={'auto'}
                                            style={!mobile ? { flexGrow: 1, flexShrink: 1, flexBasis: 0 } : {}}
                                          >
                                            <Label className={'d-md-none'}>Created By</Label>
                                            <div
                                              style={{
                                                paddingLeft: '1.25rem',
                                                paddingRight: '1.25rem',
                                                paddingTop: '0.35rem',
                                              }}
                                            >
                                              {getUserName(p.payment.createdBy)}
                                            </div>
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={'auto'}
                                            style={!mobile ? { flexGrow: 0.75, flexShrink: 1, flexBasis: 0 } : {}}
                                          >
                                            <Label className={'d-md-none'}>Amount ($)</Label>
                                            <Input
                                              type='text'
                                              style={{ textAlign: 'right' }}
                                              value={p.payment.amount}
                                              onChange={(e) => {
                                                const pAmount = e.target.value;
                                                setPayments((oldP) => [
                                                  ...oldP.slice(0, p.index),
                                                  { ...oldP[p.index], amount: formatCurrencyCommas(pAmount) },
                                                  ...oldP.slice(p.index + 1),
                                                ]);
                                                setIsDirty(true);
                                              }}
                                              onBlur={(e) => {
                                                const pAmount = e.target.value;
                                                setPayments((oldP) => [
                                                  ...oldP.slice(0, p.index),
                                                  {
                                                    ...oldP[p.index],
                                                    amount: formatCurrencyCommasTwoDigits(pAmount),
                                                  },
                                                  ...oldP.slice(p.index + 1),
                                                ]);
                                                setIsDirty(true);
                                              }}
                                              disabled={!isEditingAllowed}
                                            />
                                          </Col>
                                          <div className={'d-none d-md-block'} style={{ width: 0 }}>
                                            <Button
                                              color={'link'}
                                              className={'text-danger'}
                                              onClick={() => {
                                                setPayments((oldP) => [
                                                  ...oldP.slice(0, p.index),
                                                  ...oldP.slice(p.index + 1),
                                                ]);
                                                setIsDirty(true);
                                              }}
                                            >
                                              <FaTimes />
                                            </Button>
                                          </div>
                                        </Row>
                                      </div>
                                    </div>
                                  </ListGroupItem>
                                ))}
                              {payments.filter((p) => p.requestId === r.requestId).length > 0 && (
                                <ListGroupItem className={'py-2 d-none d-md-block'}>
                                  <Row style={{ paddingRight: 0, width: '99.5%' }}>
                                    <Col style={{ flexGrow: 2 }} />
                                    <Col />
                                    <Col />
                                    <Col />
                                    <Col style={{ textAlign: 'right' }}>
                                      <Label className={'mt-2'}>Remaining Balance ($)</Label>
                                    </Col>
                                    <Col>
                                      <Input
                                        style={{ textAlign: 'right' }}
                                        type={'text'}
                                        disabled={true}
                                        name='total'
                                        value={requestBalance(r)}
                                      />
                                    </Col>
                                  </Row>
                                </ListGroupItem>
                              )}
                              <ListGroupItem
                                id={'add-new-payment'}
                                onClick={() => {
                                  setPayments((p) =>
                                    p.concat([
                                      {
                                        paymentType: '',
                                        amount: '',
                                        paymentDate: 0,
                                        vendorOrServiceProvider: '',
                                        poNumber: '',
                                        requestId: r.requestId,
                                        submittedToFinance: false,
                                        submittedDate: 0,
                                        createdBy: myUser.userId,
                                        receiptReceivedDate: 0,
                                      },
                                    ])
                                  );
                                  setIsDirty(true);
                                }}
                                tag={'button'}
                                className='border-top'
                              >
                                <FaPlus /> Add Payment
                              </ListGroupItem>
                            </Card>
                          </div>
                        </div>
                      </ListGroupItem>
                    </CardBody>
                  </Card>
                </>
              ))
          ) : (
            <CardBody className={'mb-5'}>
              There are currently no approved funding requests for this file. Add and approve funding requests on the{' '}
              <Link to={'/files/' + params.fileId + '/details'}>File Information</Link> tab and return to this page to
              track the payment details.
            </CardBody>
          )}
          {requests.filter(
            (r) =>
              r.requestId &&
              (r.outcomeId === REQUEST_OUTCOME_APPROVED || r.outcomeId === REQUEST_OUTCOME_PARTIAL_APPROVAL)
          ).length > 0 && (
            <ListGroupItem className={'py-2 d-none d-md-block'}>
              <Row style={{ paddingRight: 15, width: '99.5%' }}>
                <Col style={{ flexGrow: 2 }} />
                <Col />
                <Col />
                <Col />
                <Col style={{ textAlign: 'right' }}>
                  <Label className={'mt-2'}>Total Remaining Balance ($)</Label>
                </Col>
                <Col>
                  <Input
                    style={{ textAlign: 'right' }}
                    type={'text'}
                    disabled={true}
                    name='total'
                    value={allTotals()}
                  />
                </Col>
              </Row>
            </ListGroupItem>
          )}
        </ListGroup>
      </Container>
    </>
  );
};
