import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, CustomInput, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { DEPARTMENT_FAMILY_PRESERVATION, DomainContext, IStage, STAGE_INACTIVE } from '../../contexts/DomainContext';
import { FileContext, IFamilyPreservationFields } from './FileContext';
import DatePicker from 'react-datepicker';

import { RequiredLabel } from '../RequiredLabel';
import { UsersContext } from '../../contexts/UsersContext';
import { IUser } from '../Users/UsersTable';
import { MyUserContext } from '../../contexts/MyUserContext';
import { disabledSelectStylesWithMenu } from '../../utils/StylingUtils';

export const FamilyPreservationFileDetails: React.FC<{
  isEditingAllowed: boolean;
  trySave: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setFCSAssignedSocialWorkerChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUncheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const domainContext = useContext(DomainContext);
  const fileContext = useContext(FileContext);
  const usersContext = useContext(UsersContext);
  const myUser = useContext(MyUserContext);

  const stages = domainContext.stages;

  const staff = usersContext.users;
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <Card className={'mb-4'}>
            <CardHeader>Client Services Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label for={'completed-date'}>Completed Date</Label>
                    <DatePicker
                      selected={fileContext.completedDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCompletedDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={fileContext.stageId !== STAGE_INACTIVE || !props.isEditingAllowed}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'initial-referral-date'}>Initial Referral Date</Label>
                    <DatePicker
                      selected={
                        fileContext.familyPreservationFields && fileContext.familyPreservationFields.initialReferralDate
                          ? new Date(fileContext.familyPreservationFields.initialReferralDate)
                          : undefined
                      }
                      name={'initial-referral-date'}
                      onChange={(date) => {
                        fileContext.setFamilyPreservationFields(
                          (s) =>
                            ({
                              ...s,
                              initialReferralDate: date ? date.getTime() : null,
                            } as IFamilyPreservationFields)
                        );
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label for={'number-of-kids-grandkids'}>Number of Kids/Grandkids</Label>
                    <Input
                      type='number'
                      value={fileContext.familyPreservationFields?.numberOfKidsGrandkids}
                      onChange={(e) => {
                        fileContext.setFamilyPreservationFields(
                          (s) =>
                            ({
                              ...s,
                              numberOfKidsGrandkids: e.target.value ? e.target.value : undefined,
                            } as IFamilyPreservationFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <Label>FCS Assigned Social Worker</Label>
                    <Input
                      type='text'
                      placeholder='Type here...'
                      name='fcs-assigned-social-worker'
                      value={fileContext.familyPreservationFields?.fcsAssignedSocialWorker}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setFamilyPreservationFields(
                          (s) =>
                            ({
                              ...s,
                              fcsAssignedSocialWorker: e.target.value ? e.target.value : undefined,
                            } as IFamilyPreservationFields)
                        );
                        if (props.setFCSAssignedSocialWorkerChanged) {
                          props.setFCSAssignedSocialWorkerChanged(true);
                        }
                        props.setIsDirty(true);
                      }}
                      disabled={!props.isEditingAllowed}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card className={'mb-4'}>
            <CardHeader>File Details</CardHeader>
            <CardBody>
              {myUser.isSuperAdmin && (
                <Row className='square border-bottom mb-2'>
                  <Col>
                    <FormGroup>
                      <Label for={'isConfidential'}>Confidentiality</Label>
                      <CustomInput
                        type='checkbox'
                        id={'isConfidential'}
                        name={'isConfidential'}
                        label={'Highly Confidential File'}
                        checked={fileContext.isConfidential}
                        onChange={() => {
                          if (fileContext.isConfidential) {
                            props.setOpenUncheckConfidentialityModal(true);
                          } else {
                            props.setOpenCheckConfidentialityModal(true);
                          }
                          fileContext.setIsConfidential(!fileContext.isConfidential);
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <RequiredLabel for={'application-date'} required={fileContext.createdDate === undefined}>
                      Created Date
                    </RequiredLabel>
                    <DatePicker
                      selected={fileContext.createdDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCreatedDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                    {props.trySave && fileContext.createdDate === undefined && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2 '}>
                    <RequiredLabel for={'stageId'} required={!fileContext.stageId}>
                      Stage
                    </RequiredLabel>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'stageId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={stages.filter(
                        (s) => s.fileTypes && s.fileTypes.includes(fileContext.fileTypeId ? fileContext.fileTypeId : '')
                      )}
                      value={stages.find((s: IStage) => s.stageId === fileContext.stageId)}
                      onChange={(value: any) => {
                        fileContext.setStageId(value?.stageId || '');
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IStage) => option.stageId}
                      getOptionLabel={(option: IStage) => option.name}
                      isDisabled={!props.isEditingAllowed}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'staffIds'}>Staff</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'staffIds'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={
                        fileContext.isConfidential
                          ? staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_FAMILY_PRESERVATION) &&
                                u.role === 'SuperAdmin'
                            )
                          : staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_FAMILY_PRESERVATION)
                            )
                      }
                      onChange={(value: any) => {
                        fileContext.setStaffIds(value.map((v: IUser) => v.userId));
                        props.setIsDirty(true);
                      }}
                      value={selectedStaff}
                      getOptionValue={(option: IUser) => option.userId}
                      getOptionLabel={(option: IUser) =>
                        option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                      }
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>File Details/Background</Label>
                    <Input
                      type='textarea'
                      placeholder=''
                      style={{ minHeight: '226px' }}
                      name='name'
                      value={fileContext.details}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setDetails(e.target.value);
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
