import { Button, Card, CardBody, CardHeader, Col, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FileContext } from './FileContext';
import { FaPlus, FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import { DomainContext, FILE_TYPE_FAMILY_PRESERVATION, IServiceType } from '../../contexts/DomainContext';
import DatePicker from 'react-datepicker';
import { disabledSelectStyles } from '../../utils/StylingUtils';

interface IProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

export const ServicesProvided = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const familyPreservationServiceTypes = domainContext.familyPreservationServiceTypes;
  const birthWorkerServiceTypes = domainContext.birthWorkerServiceTypes;
  const [changedServiceLength, setChangedServiceLength] = useState(0);
  let ref: any = undefined;

  useEffect(() => {
    if (changedServiceLength > 0) {
      var elmnt = document.getElementById('add-new-service');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
        ref.focus();
      }
    }
  }, [changedServiceLength]);

  return (
    <>
      <Card>
        <CardHeader className={'d-flex align-items-center'}>Services Provided</CardHeader>
        <CardBody className={'p-0'}>
          <ListGroup flush={true}>
            {fileContext.servicesProvided.length > 0 && (
              <ListGroupItem className={'py-2 d-none d-md-block'}>
                <Row style={{ paddingRight: 42 }} className='row--condensed'>
                  <Col md={3}>
                    <Label>Service Type</Label>
                  </Col>
                  <Col md={2}>
                    <Label>From Date</Label>
                  </Col>
                  <Col md={2}>
                    <Label>To Date</Label>
                  </Col>
                  <Col md={5}>
                    <Label>Description</Label>
                  </Col>
                </Row>
              </ListGroupItem>
            )}
            {fileContext.servicesProvided &&
              fileContext.servicesProvided.map((sp, index) => (
                <ListGroupItem key={sp.serviceProvidedId} className='list-group-item--borderless'>
                  <div className={'d-flex'}>
                    <Row className={'flex-grow-1 row--condensed'}>
                      <Col md={3}>
                        <Label className={'d-md-none'}>Service Type</Label>
                        <Select
                          styles={disabledSelectStyles}
                          name={'serviceType'}
                          ref={(r) => {
                            ref = r;
                          }}
                          options={
                            fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION
                              ? familyPreservationServiceTypes
                              : birthWorkerServiceTypes
                          }
                          placeholder={<span className='placeholder'>Select...</span>}
                          value={
                            fileContext.fileTypeId === FILE_TYPE_FAMILY_PRESERVATION
                              ? familyPreservationServiceTypes.find(
                                  (s: IServiceType) => s.serviceTypeId === sp.serviceTypeId
                                )
                              : birthWorkerServiceTypes.find((s: IServiceType) => s.serviceTypeId === sp.serviceTypeId)
                          }
                          onChange={(value: any) => {
                            props.setIsDirty(true);
                            fileContext.setServicesProvided((oldS) => [
                              ...oldS.slice(0, index),
                              { ...oldS[index], serviceTypeId: value ? value.serviceTypeId : '' },
                              ...oldS.slice(index + 1),
                            ]);
                          }}
                          getOptionValue={(option: IServiceType) => option.serviceTypeId}
                          getOptionLabel={(option: IServiceType) => option.name}
                        />
                      </Col>
                      <Col md={2}>
                        <Label className={'d-md-none'}>From Date</Label>
                        <DatePicker
                          selected={sp.fromDate ? new Date(sp.fromDate) : undefined}
                          name={'from-date'}
                          onChange={(date) => {
                            props.setIsDirty(true);
                            fileContext.setServicesProvided((oldS) => [
                              ...oldS.slice(0, index),
                              { ...oldS[index], fromDate: date ? date.getTime() : undefined },
                              ...oldS.slice(index + 1),
                            ]);
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          isClearable={true}
                          dateFormat='MMM dd, yyyy'
                          dropdownMode='select'
                          className={'date-picker rounded mb-0'}
                          placeholderText={'MMM dd, yyyy'}
                        />
                      </Col>
                      <Col md={2}>
                        <Label className={'d-md-none'}>To Date</Label>
                        <DatePicker
                          selected={sp.toDate ? new Date(sp.toDate) : undefined}
                          name={'to-date'}
                          onChange={(date) => {
                            props.setIsDirty(true);
                            fileContext.setServicesProvided((oldS) => [
                              ...oldS.slice(0, index),
                              { ...oldS[index], toDate: date ? date.getTime() : undefined },
                              ...oldS.slice(index + 1),
                            ]);
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          isClearable={true}
                          dateFormat='MMM dd, yyyy'
                          dropdownMode='select'
                          className={'date-picker rounded mb-0'}
                          placeholderText={'MMM dd, yyyy'}
                        />
                      </Col>
                      <Col md={5}>
                        <Label className={'d-md-none'}>Description</Label>
                        <Input
                          type='text'
                          value={sp.description}
                          onChange={(e) => {
                            const descriptionTemp = e.target.value;
                            fileContext.setServicesProvided((oldS) => [
                              ...oldS.slice(0, index),
                              { ...oldS[index], description: descriptionTemp },
                              ...oldS.slice(index + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                        />
                      </Col>
                      <Col className={'d-md-none'}>
                        <Button
                          color='danger'
                          className='w-mobile-100'
                          onClick={() => {
                            props.setIsDirty(true);
                            fileContext.setServicesProvided((oldR) => [
                              ...oldR.slice(0, index),
                              ...oldR.slice(index + 1),
                            ]);
                          }}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>

                    <div className={'d-none d-md-block'} style={{ width: 42 }}>
                      <Button
                        color={'link'}
                        className={'text-danger'}
                        onClick={() => {
                          props.setIsDirty(true);
                          fileContext.setServicesProvided((oldR) => [
                            ...oldR.slice(0, index),
                            ...oldR.slice(index + 1),
                          ]);
                        }}
                      >
                        <FaTimes />
                      </Button>
                    </div>
                  </div>
                </ListGroupItem>
              ))}
            <ListGroupItem
              tag={'button'}
              id={'add-new-service'}
              className='border-top'
              onClick={() => {
                props.setIsDirty(true);
                fileContext.setServicesProvided((r) =>
                  r.concat([
                    {
                      serviceProvidedId: Math.random().toString(),
                      serviceTypeId: '',
                      fromDate: 0,
                      toDate: 0,
                      description: '',
                    },
                  ])
                );
                setChangedServiceLength((s) => s + 1);
              }}
            >
              <FaPlus /> Add Service Provided
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </>
  );
};
