import * as React from 'react';
import { useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { SessionTimeout } from './components/SessionTimout/SessionTimeout';
import { UsersTable } from './components/Users/UsersTable';
import { MyAccount } from './components/MyAccount/MyAccount';
import { ToastContainer } from 'react-toastify';
import { SignOut } from './components/ChangePassword/SignOut';
import { DepartmentsTable } from './components/Departments/DepartmentsTable';
import { Department } from './components/Departments/Department';
import { UserDetails } from './components/Users/UserDetails';
import { User } from './components/Users/User';
import { ClientsTable } from './components/Clients/ClientsTable';
import { Exports } from './components/Reporting/Exports';
import { ClientDetails } from './components/Clients/ClientDetails';
import { Client } from './components/Clients/Client';
import { FilesTable } from './components/FilesTable/FilesTable';
import { File } from './components/Files/File';
import { FileProvider } from './components/Files/FileContext';
import { ClientProvider } from './components/Clients/ClientContext';
import { UsersContext } from './contexts/UsersContext';
import { DomainContext } from './contexts/DomainContext';
import { MyUserContext } from './contexts/MyUserContext';
import { ClientsContext } from './contexts/ClientsContext';
import { FileCreate } from './components/Files/FileCreate';
import { ClientsTableProvider } from './components/Clients/ClientsTableContext';
import { FilesTableProvider } from './components/FilesTable/FilesTableContext';
import { GladueMemberTable } from './components/GladueMembers/GladueMembersTable';
import { GladueMemberDetails } from './components/GladueMembers/GladueMemberDetails';
import { PageNotFound } from './PageNotFound';
import { ClientsMerge } from './components/Clients/ClientsMerge';
import { ChargesTable } from './components/Charges/ChargesTable';
import { ChargeDetails } from './components/Charges/ChargesDetails';
import { WorkshopsTable } from './components/Workshops/WorkshopsTable';
import { WorkshopDetails } from './components/Workshops/WorkshopDetails';
import { Workshop } from './components/Workshops/Workshop';
import { WellnessVacancyReport } from './components/Reporting/WellnessVacancyReport';

export const SignedInLayout = () => {
  const domainContext = useContext(DomainContext);
  const usersContext = useContext(UsersContext);
  const myUserContext = useContext(MyUserContext);
  const clientsContext = useContext(ClientsContext);

  if (
    domainContext.loaded &&
    usersContext.loaded &&
    myUserContext.loaded &&
    clientsContext.state.type !== 'CLIENTS_STATE_LOADING'
  ) {
    return (
      <Router>
        <Route path='/login'>
          <Redirect to='/' />
        </Route>
        <Route path='/:section?/:sectionId?'>
          <NavigationBar />
          <SessionTimeout />
        </Route>
        <Route path='/change-password/:token'>
          <SignOut />
        </Route>
        <Route path='/create-password/:token'>
          <SignOut />
        </Route>
        <main className={'content-wrapper'} id={'main'}>
          <Switch>
            <Route path={['/(files)?', '/files/:fileId/:tab?/:tabId?/:tabId2?']} exact={true}>
              <FilesTableProvider>
                <Switch>
                  <Route path='/(files)?' exact={true}>
                    <FilesTable />
                  </Route>
                  <Route path='/files'>
                    <FileProvider>
                      <Switch>
                        <Route path='/files/add' exact={true}>
                          <FileCreate />
                        </Route>
                        <Route path='/files/:fileId/:tab?/:tabId?/:tabId2?'>
                          <File />
                        </Route>
                      </Switch>
                    </FileProvider>
                  </Route>
                </Switch>
              </FilesTableProvider>
            </Route>
            <Route path={'/users'}>
              <Switch>
                <Route path='/users' exact={true}>
                  <UsersTable />
                </Route>
                <Route path='/users/add' exact={true}>
                  <UserDetails />
                </Route>
                <Route path='/users/:userId/:tab?/:tabId?'>
                  <User />
                </Route>
              </Switch>
            </Route>
            <Route path='/departments'>
              <Switch>
                <Route path='/departments' exact={true}>
                  <DepartmentsTable />
                </Route>
                <Route path='/departments/:departmentId/:tab?'>
                  <Department />
                </Route>
              </Switch>
            </Route>
            <Route path='/gladue-members'>
              <Switch>
                <Route path={'/gladue-members'} exact={true}>
                  <GladueMemberTable />
                </Route>
                <Route path={'/gladue-members/add'} exact={true}>
                  <GladueMemberDetails />
                </Route>
                <Route path='/gladue-members/:gladueMemberId' exact={true}>
                  <GladueMemberDetails />
                </Route>
              </Switch>
            </Route>
            <Route path='/charges'>
              <Switch>
                <Route path={'/charges'} exact={true}>
                  <ChargesTable />
                </Route>
                <Route path={'/charges/add'} exact={true}>
                  <ChargeDetails />
                </Route>
                <Route path='/charges/:chargeId' exact={true}>
                  <ChargeDetails />
                </Route>
              </Switch>
            </Route>
            <Route path='/workshops'>
              <Switch>
                <Route path={'/workshops'} exact={true}>
                  <WorkshopsTable />
                </Route>
                <Route path={'/workshops/add'} exact={true}>
                  <WorkshopDetails />
                </Route>
                <Route path='/workshops/:workshopId/:tab?/:tabId?' exact={true}>
                  <Workshop />
                </Route>
              </Switch>
            </Route>
            <Route path='/reports'>
              <Switch>
                <Route path={'/reports/exports'}>
                  <Exports />
                </Route>
                <Route path={'/reports/wellnessVacancyReport'}>
                  <WellnessVacancyReport />
                </Route>
              </Switch>
            </Route>
            <Route path='/clients'>
              <ClientsTableProvider>
                <Switch>
                  <Route path='/clients' exact={true}>
                    <ClientsTable />
                  </Route>
                  <Route path='/clients/merge' exact={true}>
                    <ClientsMerge />
                  </Route>
                  <Route path='/clients/:clientId'>
                    <ClientProvider>
                      <Switch>
                        <Route path='/clients/add' exact={true}>
                          <ClientDetails allowCreateFile={false} newClient={true} />
                        </Route>
                        <Route path='/clients/:clientId/:tab?'>
                          <Client />
                        </Route>
                      </Switch>
                    </ClientProvider>
                  </Route>
                </Switch>
              </ClientsTableProvider>
            </Route>
            <Route path='/my-account'>
              <MyAccount />
            </Route>
            <Route path='*'>
              <PageNotFound />
            </Route>
          </Switch>
        </main>
        <ToastContainer autoClose={8000} />
      </Router>
    );
  } else {
    return <div>Loading</div>;
  }
};
