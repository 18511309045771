import * as React from 'react';
import {
  DEPARTMENT_JUSTICE,
  DomainContext,
  GLADUE_REPORT_SENTENCE_FINE,
  GLADUE_REPORT_SENTENCE_RESTITUTION,
  ICourtLocation,
  IIndigenousGroup,
  INDIGENOUS_GROUP_FIRST_NATION,
  INDIGENOUS_GROUP_INUIT,
  INDIGENOUS_GROUP_METIS,
  INDIGENOUS_GROUP_OTHER,
  ISentenceType,
  IStage,
} from '../../contexts/DomainContext';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { FileContext, IGladueReportFields } from './FileContext';
import { IGladueMember } from '../GladueMembers/GladueMembersTable';
import CreatableSelect from 'react-select/creatable';
import { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { ICharge } from '../Charges/ChargesTable';
import { IUser } from '../Users/UsersTable';
import { UsersContext } from '../../contexts/UsersContext';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { MyUserContext } from '../../contexts/MyUserContext';
import { disabledSelectStylesWithMenu } from '../../utils/StylingUtils';

export const GladueReportFileDetails: React.FC<{
  isEditingAllowed: boolean;
  trySave: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setGladueSentencingDateChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setCrownLawyerChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setDefenceLawyerChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateReminder?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUncheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const usersContext = useContext(UsersContext);
  const myUser = useContext(MyUserContext);
  const stages = domainContext.stages;
  const staff = usersContext.users;
  const courtLocationOptions = domainContext.courtLocations;
  const sentenceTypeOptions = domainContext.sentenceTypes;
  const indigenousGroups = domainContext.indigenousGroups;
  const yukonFns = domainContext.yukonFns;
  const charges = domainContext.charges;

  const [changedInitialSentencingPositionListLength, setChangedInitialSentencingPositionListLength] = useState(0);
  const [changedDispositionListLength, setChangedDispositionListLength] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);

  const firstNationOptions = yukonFns.map((fn) => ({ value: fn.yukonFirstNationId, label: fn.name }));
  //Use original Gladue Report Fields so Mentee field only disappears on save
  const [showMentee, setShowMentee] = useState<boolean>(
    fileContext.originalGladueReportFields?.mentee !== '' &&
      fileContext.originalGladueReportFields?.mentee !== undefined
  );

  useEffect(() => {
    if (
      fileContext.originalGladueReportFields?.mentee !== '' &&
      fileContext.originalGladueReportFields?.mentee !== undefined
    ) {
      setShowMentee(true);
    } else {
      setShowMentee(false);
    }
  }, [fileContext.originalGladueReportFields?.mentee]);

  useEffect(() => {
    if (fileContext.gladueReportFields?.initialSentencingPosition) {
      fileContext.setInitialSentencingPositionList((ispl) =>
        ispl.concat([
          {
            sentenceTypeId: fileContext.gladueReportFields?.initialSentencingPosition,
            amount: fileContext.gladueReportFields?.initialSentencingPositionAmount,
            months: fileContext.gladueReportFields?.initialSentencingPositionMonths,
            days: fileContext.gladueReportFields?.initialSentencingPositionDays,
          },
        ])
      );
      fileContext.setGladueReportFields(
        (s) =>
          ({
            ...s,
            initialSentencingPositionMonths: undefined,
            initialSentencingPositionDays: undefined,
            initialSentencingPositionAmount: undefined,
            initialSentencingPosition: undefined,
          } as IGladueReportFields)
      );
    }
  }, [fileContext.gladueReportFields?.initialSentencingPosition]);

  useEffect(() => {
    if (fileContext.gladueReportFields?.dispositionType) {
      fileContext.setDispositionList((dl) =>
        dl.concat([
          {
            sentenceTypeId: fileContext.gladueReportFields?.dispositionType,
            amount: fileContext.gladueReportFields?.dispositionAmount,
            months: fileContext.gladueReportFields?.dispositionMonths,
            days: fileContext.gladueReportFields?.dispositionDays,
          },
        ])
      );
      fileContext.setGladueReportFields(
        (s) =>
          ({
            ...s,
            dispositionMonths: undefined,
            dispositionDays: undefined,
            dispositionAmount: undefined,
            dispositionType: undefined,
          } as IGladueReportFields)
      );
    }
  }, [fileContext.gladueReportFields?.dispositionType]);

  useEffect(() => {
    if (fileContext.initialSentencingPositionList.length === 0) {
      fileContext.setInitialSentencingPositionList((ispl) =>
        ispl.concat([{ sentenceTypeId: undefined, amount: undefined, months: undefined, days: undefined }])
      );
    }
  }, [fileContext.initialSentencingPositionList]);

  useEffect(() => {
    if (fileContext.dispositionList.length === 0) {
      fileContext.setDispositionList((dl) =>
        dl.concat([{ sentenceTypeId: undefined, amount: undefined, months: undefined, days: undefined }])
      );
    }
  }, [fileContext.dispositionList]);

  const formatCurrencyCommas = (amount: string | undefined) => {
    if (amount) {
      return amount
        .toString()
        .replace(/[, ]+/g, '')
        .replace(/-[^0-9.]/g, '')
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const formatCurrencyCommasTwoDigits = (amount: string | undefined) => {
    if (amount) {
      const floatToUse = parseFloat(amount.toString().replace(/[, ]+/g, ''));
      return Number.isNaN(floatToUse)
        ? '0.00'
        : floatToUse
            .toFixed(2)
            .toString()
            .replace(/-[^0-9.]/g, '')
            .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    } else {
      return '';
    }
  };

  const contactTypeOptions = [
    { value: 'Phone Number', label: 'Phone Number' },
    { value: 'Email', label: 'Email' },
    { value: 'Address', label: 'Address' },
  ];

  const reportUpdatedOptions = [
    { val: true, display: 'Yes' },
    { val: false, display: 'No' },
  ];

  const communityImpactOptions = [
    { val: true, display: 'Yes' },
    { val: false, display: 'No' },
  ];

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  useEffect(() => {
    if (changedInitialSentencingPositionListLength > 0) {
      var elmt2 = document.getElementById('select-initial-sentencing-position');
      if (elmt2) {
        elmt2.focus();
        elmt2.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [changedInitialSentencingPositionListLength]);

  useEffect(() => {
    if (changedDispositionListLength > 0) {
      var elmt2 = document.getElementById('select-disposition');
      if (elmt2) {
        elmt2.focus();
        elmt2.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [changedDispositionListLength]);

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <Card className={'mb-4'}>
            <CardHeader>Court Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'sentencing-date'}>Sentencing Date</Label>
                    <DatePicker
                      selected={
                        fileContext.gladueReportFields && fileContext.gladueReportFields.sentencingDate
                          ? new Date(fileContext.gladueReportFields.sentencingDate)
                          : undefined
                      }
                      name={'sentencing-date'}
                      onChange={(date) => {
                        fileContext.setGladueReportFields(
                          (s) =>
                            ({
                              ...s,
                              sentencingDate: date ? date.getTime() : null,
                            } as IGladueReportFields)
                        );
                        if (props.setGladueSentencingDateChanged) {
                          props.setGladueSentencingDateChanged(true);
                        }
                        if (props.setUpdateReminder) {
                          props.setUpdateReminder(true);
                        }
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
                <Col className={'pl-0'}>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'court-location'}>Court Location</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'courtLocationId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={courtLocationOptions}
                      value={
                        courtLocationOptions.find((ft: any) => ft.courtLocationId === fileContext.courtLocationId) ??
                        undefined
                      }
                      onChange={(value: any) => {
                        if (value !== null) {
                          fileContext.setCourtLocationId(value.courtLocationId);
                        } else {
                          fileContext.setCourtLocationId(undefined);
                        }
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: ICourtLocation) => option.courtLocationId}
                      getOptionLabel={(option: ICourtLocation) => option.name}
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                <Col className={'pl-0'}>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'court-matter'}>Court Matter</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='court-matter'
                      value={fileContext.gladueReportFields?.courtMatter}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setGladueReportFields(
                          (s) => ({ ...s, courtMatter: e.target.value } as IGladueReportFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {fileContext.gladueReportFields?.dispositionHistoric && (
                <Row>
                  <Col className={'pr-0'}>
                    <FormGroup className={'w-100 mb-2'}>
                      <Label for={'disposition-historic'}>Disposition - Historic</Label>
                      <Input
                        type='text'
                        placeholder=''
                        name='outcome'
                        value={fileContext.gladueReportFields?.dispositionHistoric}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <div className={'d-md-flex mb-0'}>
                <FormGroup className={'w-100 mb-2'}>
                  <Label for={'staffIds'}>Charges</Label>
                  <Select
                    styles={disabledSelectStylesWithMenu}
                    className={'mb-2'}
                    name={'chargeIds'}
                    placeholder={<span className='placeholder'>Select Charge to Add...</span>}
                    options={charges.filter(
                      (c) =>
                        c.isActive && !fileContext.gladueReportFields?.chargeIds?.includes(c.chargeId ? c.chargeId : '')
                    )}
                    onChange={(value: any) => {
                      fileContext.setGladueReportFields(
                        (s) =>
                          ({
                            ...s,
                            chargeIds: value
                              .map((c: ICharge) => c.chargeId)
                              .concat(s?.chargeIds)
                              .filter((s: string) => s),
                          } as IGladueReportFields)
                      );
                      props.setIsDirty(true);
                    }}
                    value={[]}
                    hideSelectedOptions={true}
                    getOptionValue={(option: ICharge) => (option.chargeId ? option.chargeId : '')}
                    formatOptionLabel={(option: ICharge) => (
                      <span>
                        {option.criminalSectionCode}
                        <i style={{ color: '#6c757d' }}>{' (' + option.description + ')'}</i>
                        {option.isActive ? '' : ' (Inactive)'}
                      </span>
                    )}
                    isDisabled={!props.isEditingAllowed}
                    isClearable={true}
                    isMulti={true}
                  />
                  <ListGroup>
                    {fileContext.gladueReportFields?.chargeIds &&
                      fileContext.gladueReportFields?.chargeIds.map((c) => {
                        const charge = charges.find((ch) => ch.chargeId === c);
                        if (charge) {
                          return (
                            <ListGroupItem key={charge.chargeId} className={'p-2 d-flex flex-row'}>
                              <span>
                                {charge.criminalSectionCode}
                                <i style={{ color: '#6c757d' }}>{' (' + charge.description + ')'}</i>
                              </span>
                              <Button
                                color={'link'}
                                className={'text-danger p-0 ml-auto'}
                                style={{ textAlign: 'right' }}
                                onClick={() => {
                                  fileContext.setGladueReportFields(
                                    (s) =>
                                      ({
                                        ...s,
                                        chargeIds: s?.chargeIds.filter((ocId) => ocId !== charge.chargeId),
                                      } as IGladueReportFields)
                                  );
                                  props.setIsDirty(true);
                                }}
                              >
                                <FaTimes />
                              </Button>
                            </ListGroupItem>
                          );
                        }
                      })}
                  </ListGroup>
                </FormGroup>
              </div>
            </CardBody>
          </Card>
          <Card className={'mb-3'}>
            <CardHeader>Sentencing Details</CardHeader>
            <CardBody>
              <ListGroup flush={true}>
                {fileContext.initialSentencingPositionList &&
                  fileContext.initialSentencingPositionList.map((s, sIndex) => (
                    <ListGroupItem key={sIndex} className={'py-0 border-0'}>
                      <Row className={'px-0'}>
                        <Col className={'pl-0'} md={6}>
                          <FormGroup className={'w-100 mb-2'}>
                            <Label for={'initial-sentencing-position'}>Initial Sentencing Position</Label>
                            <Select
                              styles={disabledSelectStylesWithMenu}
                              id={
                                sIndex === fileContext.initialSentencingPositionList.length - 1
                                  ? 'select-initial-sentencing-position'
                                  : ''
                              }
                              name={'initial-sentencing-position' + '_' + sIndex.toString()}
                              placeholder={<span className='placeholder'>Select...</span>}
                              options={sentenceTypeOptions}
                              value={
                                sentenceTypeOptions.find(
                                  (ft: any) =>
                                    ft.sentenceTypeId ===
                                    fileContext.initialSentencingPositionList[sIndex].sentenceTypeId
                                ) ?? undefined
                              }
                              onChange={(value: any) => {
                                if (
                                  value !== null &&
                                  (value.sentenceTypeId === GLADUE_REPORT_SENTENCE_RESTITUTION ||
                                    value.sentenceTypeId === GLADUE_REPORT_SENTENCE_FINE)
                                ) {
                                  fileContext.setInitialSentencingPositionList((oldISPL) => [
                                    ...oldISPL.slice(0, sIndex),
                                    {
                                      ...oldISPL[sIndex],
                                      months: undefined,
                                      days: undefined,
                                      sentenceTypeId: value ? value.sentenceTypeId : undefined,
                                    },
                                    ...oldISPL.slice(sIndex + 1),
                                  ]);
                                } else if (value !== null) {
                                  fileContext.setInitialSentencingPositionList((oldISPL) => [
                                    ...oldISPL.slice(0, sIndex),
                                    {
                                      ...oldISPL[sIndex],
                                      amount: undefined,
                                      sentenceTypeId: value ? value.sentenceTypeId : undefined,
                                    },
                                    ...oldISPL.slice(sIndex + 1),
                                  ]);
                                } else {
                                  fileContext.setInitialSentencingPositionList((oldISPL) => [
                                    ...oldISPL.slice(0, sIndex),
                                    {
                                      ...oldISPL[sIndex],
                                      amount: undefined,
                                      months: undefined,
                                      days: undefined,
                                      sentenceTypeId: value ? value.sentenceTypeId : undefined,
                                    },
                                    ...oldISPL.slice(sIndex + 1),
                                  ]);
                                }

                                props.setIsDirty(true);
                              }}
                              getOptionValue={(option: ISentenceType) => option.sentenceTypeId}
                              getOptionLabel={(option: ISentenceType) => option.name}
                              isDisabled={!props.isEditingAllowed}
                              isClearable={true}
                            />
                          </FormGroup>
                        </Col>
                        {(fileContext.initialSentencingPositionList[sIndex].sentenceTypeId ===
                          GLADUE_REPORT_SENTENCE_FINE ||
                          fileContext.initialSentencingPositionList[sIndex].sentenceTypeId ===
                            GLADUE_REPORT_SENTENCE_RESTITUTION) && (
                          <>
                            <Col className={'pl-0'}>
                              <FormGroup className={'mb-2'}>
                                <Label for={'initial-sentencing-position-amount'}>Amount ($)</Label>
                                <Input
                                  type='text'
                                  style={{ textAlign: 'right' }}
                                  value={fileContext.initialSentencingPositionList[sIndex].amount}
                                  onChange={(e) => {
                                    const amount = e.target.value;
                                    fileContext.setInitialSentencingPositionList((oldISPL) => [
                                      ...oldISPL.slice(0, sIndex),
                                      {
                                        ...oldISPL[sIndex],
                                        amount: amount ? formatCurrencyCommas(amount) : undefined,
                                      },
                                      ...oldISPL.slice(sIndex + 1),
                                    ]);
                                    props.setIsDirty(true);
                                  }}
                                  onBlur={(e) => {
                                    const amount = e.target.value;
                                    fileContext.setInitialSentencingPositionList((oldISPL) => [
                                      ...oldISPL.slice(0, sIndex),
                                      {
                                        ...oldISPL[sIndex],
                                        amount: amount ? formatCurrencyCommasTwoDigits(amount) : undefined,
                                      },
                                      ...oldISPL.slice(sIndex + 1),
                                    ]);
                                    props.setIsDirty(true);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col className={'pl-0'} />
                          </>
                        )}
                        {fileContext.initialSentencingPositionList[sIndex].sentenceTypeId &&
                          fileContext.initialSentencingPositionList[sIndex].sentenceTypeId !==
                            GLADUE_REPORT_SENTENCE_FINE &&
                          fileContext.initialSentencingPositionList[sIndex].sentenceTypeId !==
                            GLADUE_REPORT_SENTENCE_RESTITUTION && (
                            <>
                              <Col className={'pl-0'}>
                                <FormGroup className={'mb-2'}>
                                  <Label for={'initial-sentencing-position-months'}>Month(s)</Label>
                                  <Input
                                    type='number'
                                    value={fileContext.initialSentencingPositionList[sIndex].months}
                                    onChange={(e) => {
                                      fileContext.setInitialSentencingPositionList((oldISPL) => [
                                        ...oldISPL.slice(0, sIndex),
                                        {
                                          ...oldISPL[sIndex],
                                          months: e.target.value ? e.target.value : undefined,
                                        },
                                        ...oldISPL.slice(sIndex + 1),
                                      ]);
                                      props.setIsDirty(true);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col className={'pl-0'}>
                                <FormGroup className={'mb-2'}>
                                  <Label for={'initial-sentencing-position-days'}>Days</Label>
                                  <Input
                                    type='number'
                                    value={fileContext.initialSentencingPositionList[sIndex].days}
                                    onChange={(e) => {
                                      fileContext.setInitialSentencingPositionList((oldISPL) => [
                                        ...oldISPL.slice(0, sIndex),
                                        {
                                          ...oldISPL[sIndex],
                                          days: e.target.value ? e.target.value : undefined,
                                        },
                                        ...oldISPL.slice(sIndex + 1),
                                      ]);
                                      props.setIsDirty(true);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          )}
                        {fileContext.initialSentencingPositionList[sIndex].sentenceTypeId === undefined && (
                          <>
                            <Col className={'pl-0'} />
                            <Col className={'pl-0'} />
                          </>
                        )}
                        <Button
                          color={'link'}
                          className={'text-danger mt-3'}
                          onClick={() => {
                            fileContext.setInitialSentencingPositionList((oldISPL) => [
                              ...oldISPL.slice(0, sIndex),
                              ...oldISPL.slice(sIndex + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                        >
                          <FaTimes />
                        </Button>
                      </Row>
                    </ListGroupItem>
                  ))}
                {fileContext.initialSentencingPositionList.length > 0 &&
                  fileContext.initialSentencingPositionList[0].sentenceTypeId !== undefined && (
                    <ListGroupItem
                      id={'add-new-initial-sentencing-position'}
                      onClick={() => {
                        fileContext.setInitialSentencingPositionList((ispl) =>
                          ispl.concat([
                            { sentenceTypeId: undefined, amount: undefined, months: undefined, days: undefined },
                          ])
                        );
                        setChangedInitialSentencingPositionListLength((s) => s + 1);
                        props.setIsDirty(true);
                      }}
                      className={'pl-0'}
                      tag={'button'}
                    >
                      <FaPlus /> Add Initial Sentencing Position
                    </ListGroupItem>
                  )}
              </ListGroup>
              <ListGroup flush={true}>
                {fileContext.dispositionList &&
                  fileContext.dispositionList.map((s, sIndex) => (
                    <ListGroupItem key={sIndex} className={'py-0 border-0'}>
                      <Row className={'px-0'}>
                        <Col className={'pl-0'} md={6}>
                          <FormGroup className={'w-100 mb-2'}>
                            <Label for={'disposition'}>Disposition</Label>
                            <Select
                              styles={disabledSelectStylesWithMenu}
                              id={sIndex === fileContext.dispositionList.length - 1 ? 'select-disposition' : ''}
                              name={'disposition' + '_' + sIndex.toString()}
                              placeholder={<span className='placeholder'>Select...</span>}
                              options={sentenceTypeOptions}
                              value={
                                sentenceTypeOptions.find(
                                  (ft: any) => ft.sentenceTypeId === fileContext.dispositionList[sIndex].sentenceTypeId
                                ) ?? undefined
                              }
                              onChange={(value: any) => {
                                if (
                                  value !== null &&
                                  (value.sentenceTypeId === GLADUE_REPORT_SENTENCE_RESTITUTION ||
                                    value.sentenceTypeId === GLADUE_REPORT_SENTENCE_FINE)
                                ) {
                                  fileContext.setDispositionList((oldDL) => [
                                    ...oldDL.slice(0, sIndex),
                                    {
                                      ...oldDL[sIndex],
                                      months: undefined,
                                      days: undefined,
                                      sentenceTypeId: value ? value.sentenceTypeId : undefined,
                                    },
                                    ...oldDL.slice(sIndex + 1),
                                  ]);
                                } else if (value !== null) {
                                  fileContext.setDispositionList((oldDL) => [
                                    ...oldDL.slice(0, sIndex),
                                    {
                                      ...oldDL[sIndex],
                                      amount: undefined,
                                      sentenceTypeId: value ? value.sentenceTypeId : undefined,
                                    },
                                    ...oldDL.slice(sIndex + 1),
                                  ]);
                                } else {
                                  fileContext.setDispositionList((oldDL) => [
                                    ...oldDL.slice(0, sIndex),
                                    {
                                      ...oldDL[sIndex],
                                      amount: undefined,
                                      months: undefined,
                                      days: undefined,
                                      sentenceTypeId: value ? value.sentenceTypeId : undefined,
                                    },
                                    ...oldDL.slice(sIndex + 1),
                                  ]);
                                }

                                props.setIsDirty(true);
                              }}
                              getOptionValue={(option: ISentenceType) => option.sentenceTypeId}
                              getOptionLabel={(option: ISentenceType) => option.name}
                              isDisabled={!props.isEditingAllowed}
                              isClearable={true}
                            />
                          </FormGroup>
                        </Col>
                        {(fileContext.dispositionList[sIndex].sentenceTypeId === GLADUE_REPORT_SENTENCE_FINE ||
                          fileContext.dispositionList[sIndex].sentenceTypeId ===
                            GLADUE_REPORT_SENTENCE_RESTITUTION) && (
                          <>
                            <Col className={'pl-0'}>
                              <FormGroup className={'mb-2'}>
                                <Label for={'disposition-amount'}>Amount ($)</Label>
                                <Input
                                  type='text'
                                  style={{ textAlign: 'right' }}
                                  value={fileContext.dispositionList[sIndex].amount}
                                  onChange={(e) => {
                                    const amount = e.target.value;
                                    fileContext.setDispositionList((oldDL) => [
                                      ...oldDL.slice(0, sIndex),
                                      {
                                        ...oldDL[sIndex],
                                        amount: amount ? formatCurrencyCommas(amount) : undefined,
                                      },
                                      ...oldDL.slice(sIndex + 1),
                                    ]);
                                    props.setIsDirty(true);
                                  }}
                                  onBlur={(e) => {
                                    const amount = e.target.value;
                                    fileContext.setDispositionList((oldDL) => [
                                      ...oldDL.slice(0, sIndex),
                                      {
                                        ...oldDL[sIndex],
                                        amount: amount ? formatCurrencyCommasTwoDigits(amount) : undefined,
                                      },
                                      ...oldDL.slice(sIndex + 1),
                                    ]);
                                    props.setIsDirty(true);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col className={'pl-0'} />
                          </>
                        )}
                        {fileContext.dispositionList[sIndex].sentenceTypeId &&
                          fileContext.dispositionList[sIndex].sentenceTypeId !== GLADUE_REPORT_SENTENCE_FINE &&
                          fileContext.dispositionList[sIndex].sentenceTypeId !== GLADUE_REPORT_SENTENCE_RESTITUTION && (
                            <>
                              <Col className={'pl-0'}>
                                <FormGroup className={'mb-2'}>
                                  <Label for={'disposition-months'}>Month(s)</Label>
                                  <Input
                                    type='number'
                                    value={fileContext.dispositionList[sIndex].months}
                                    onChange={(e) => {
                                      fileContext.setDispositionList((oldDL) => [
                                        ...oldDL.slice(0, sIndex),
                                        {
                                          ...oldDL[sIndex],
                                          months: e.target.value ? e.target.value : undefined,
                                        },
                                        ...oldDL.slice(sIndex + 1),
                                      ]);
                                      props.setIsDirty(true);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col className={'pl-0'}>
                                <FormGroup className={'mb-2'}>
                                  <Label for={'initial-sentencing-position-days'}>Days</Label>
                                  <Input
                                    type='number'
                                    value={fileContext.dispositionList[sIndex].days}
                                    onChange={(e) => {
                                      fileContext.setDispositionList((oldDL) => [
                                        ...oldDL.slice(0, sIndex),
                                        {
                                          ...oldDL[sIndex],
                                          days: e.target.value ? e.target.value : undefined,
                                        },
                                        ...oldDL.slice(sIndex + 1),
                                      ]);
                                      props.setIsDirty(true);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          )}
                        {fileContext.dispositionList[sIndex].sentenceTypeId === undefined && (
                          <>
                            <Col className={'pl-0'} />
                            <Col className={'pl-0'} />
                          </>
                        )}
                        <Button
                          color={'link'}
                          className={'text-danger mt-3'}
                          onClick={() => {
                            fileContext.setDispositionList((oldDL) => [
                              ...oldDL.slice(0, sIndex),
                              ...oldDL.slice(sIndex + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                        >
                          <FaTimes />
                        </Button>
                      </Row>
                    </ListGroupItem>
                  ))}
                {fileContext.dispositionList.length > 0 &&
                  fileContext.dispositionList[0].sentenceTypeId !== undefined && (
                    <ListGroupItem
                      id={'add-new-disposition'}
                      onClick={() => {
                        fileContext.setDispositionList((dl) =>
                          dl.concat([
                            { sentenceTypeId: undefined, amount: undefined, months: undefined, days: undefined },
                          ])
                        );
                        setChangedDispositionListLength((s) => s + 1);
                        props.setIsDirty(true);
                      }}
                      className={'pl-0'}
                      tag={'button'}
                    >
                      <FaPlus /> Add Disposition
                    </ListGroupItem>
                  )}
              </ListGroup>
            </CardBody>
          </Card>
          <Card className={'mb-3'}>
            <CardHeader>Report Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'writers'}>Assigned Writer</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'assignedWriter'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={domainContext.gladueMembers.filter((gm) => gm.roles.includes('Writer') && gm.isActive)}
                      onChange={(value: any) => {
                        fileContext.setGladueReportFields(
                          (s) =>
                            ({
                              ...s,
                              assignedWriter: value ? value.gladueMemberId : '',
                            } as IGladueReportFields)
                        );
                        props.setIsDirty(true);
                      }}
                      value={domainContext.gladueMembers.find((gm) =>
                        fileContext.gladueReportFields && fileContext.gladueReportFields?.assignedWriter
                          ? fileContext.gladueReportFields?.assignedWriter.includes(
                              gm.gladueMemberId ? gm.gladueMemberId : ''
                            )
                          : false
                      )}
                      getOptionValue={(option: IGladueMember) =>
                        option.gladueMemberId ? option.gladueMemberId : Math.random().toString()
                      }
                      getOptionLabel={(option: IGladueMember) => option.lastName + ', ' + option.firstName}
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'writers'}>Mentor</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'mentor'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={domainContext.gladueMembers.filter((gm) => gm.roles.includes('Mentor') && gm.isActive)}
                      onChange={(value: any) => {
                        fileContext.setGladueReportFields(
                          (s) => ({ ...s, mentor: value ? value.gladueMemberId : '' } as IGladueReportFields)
                        );
                        props.setIsDirty(true);
                      }}
                      value={domainContext.gladueMembers.find((gm) =>
                        fileContext.gladueReportFields && fileContext.gladueReportFields?.mentor
                          ? fileContext.gladueReportFields.mentor.includes(gm.gladueMemberId ? gm.gladueMemberId : '')
                          : false
                      )}
                      getOptionValue={(option: IGladueMember) =>
                        option.gladueMemberId ? option.gladueMemberId : Math.random().toString()
                      }
                      getOptionLabel={(option: IGladueMember) => option.lastName + ', ' + option.firstName}
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                {showMentee && (
                  <Col>
                    <FormGroup className={'w-100 mb-2'}>
                      <Label for={'writers'}>Mentee</Label>
                      <Select
                        styles={disabledSelectStylesWithMenu}
                        name={'mentee'}
                        placeholder={<span className='placeholder'>Select...</span>}
                        options={domainContext.gladueMembers.filter((gm) => gm.roles.includes('Mentee') && gm.isActive)}
                        onChange={(value: any) => {
                          fileContext.setGladueReportFields(
                            (s) => ({ ...s, mentee: value ? value.gladueMemberId : '' } as IGladueReportFields)
                          );
                          props.setIsDirty(true);
                        }}
                        value={domainContext.gladueMembers.find((gm) =>
                          fileContext.gladueReportFields && fileContext.gladueReportFields?.mentee
                            ? fileContext.gladueReportFields.mentee.includes(gm.gladueMemberId ? gm.gladueMemberId : '')
                            : false
                        )}
                        getOptionValue={(option: IGladueMember) =>
                          option.gladueMemberId ? option.gladueMemberId : Math.random().toString()
                        }
                        getOptionLabel={(option: IGladueMember) => option.lastName + ', ' + option.firstName}
                        isDisabled={!props.isEditingAllowed}
                        isClearable={true}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card className={'mb-4'}>
            <CardHeader>File Details</CardHeader>
            <CardBody>
              {myUser.isSuperAdmin && (
                <Row className='square border-bottom mb-2'>
                  <Col>
                    <FormGroup>
                      <Label for={'isConfidential'}>Confidentiality</Label>
                      <CustomInput
                        type='checkbox'
                        id={'isConfidential'}
                        name={'isConfidential'}
                        label={'Highly Confidential File'}
                        checked={fileContext.isConfidential}
                        onChange={() => {
                          if (fileContext.isConfidential) {
                            props.setOpenUncheckConfidentialityModal(true);
                          } else {
                            props.setOpenCheckConfidentialityModal(true);
                          }
                          fileContext.setIsConfidential(!fileContext.isConfidential);
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'stageId'}>Stage</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'stageId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={stages.filter(
                        (s) =>
                          s.fileTypes &&
                          s.fileTypes.includes(fileContext.fileTypeId ? fileContext.fileTypeId : '') &&
                          (s.fileSubTypes
                            ? s.fileSubTypes.includes(fileContext.fileSubTypeId ? fileContext.fileSubTypeId : '')
                            : true)
                      )}
                      value={stages.find((s: IStage) => s.stageId === fileContext.stageId)}
                      onChange={(value: any) => {
                        fileContext.setStageId(value?.stageId || '');
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IStage) => option.stageId}
                      getOptionLabel={(option: IStage) => option.name}
                      isDisabled={!props.isEditingAllowed}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
                <Col className={'pl-0'}>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'requested-date'}>Requested Date</Label>
                    <DatePicker
                      selected={
                        fileContext.gladueReportFields && fileContext.gladueReportFields.requestedDate
                          ? new Date(fileContext.gladueReportFields.requestedDate)
                          : fileContext.createdDate
                      }
                      name={'requested-date'}
                      onChange={(date) => {
                        fileContext.setGladueReportFields(
                          (s) => ({ ...s, requestedDate: date ? date.getTime() : null } as IGladueReportFields)
                        );
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      isClearable={false}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
                <Col className={'pl-0'}>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'report-updated'}>Report Updated</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'report-updated'}
                      options={reportUpdatedOptions}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={reportUpdatedOptions.find((g) => g.val === fileContext.gladueReportFields?.reportUpdated)}
                      type={'text'}
                      onChange={(value: any) => {
                        fileContext.setGladueReportFields(
                          (s) => ({ ...s, reportUpdated: value?.val } as IGladueReportFields)
                        );
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: any) => option.val}
                      getOptionLabel={(option: any) => option.display}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'staffIds'}>Staff</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'staffIds'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={
                        fileContext.isConfidential
                          ? staff
                              .sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName))
                              .filter(
                                (u) =>
                                  (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                  u.departmentAccessIds.includes(DEPARTMENT_JUSTICE) &&
                                  u.role === 'SuperAdmin'
                              )
                          : staff
                              .sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName))
                              .filter(
                                (u) =>
                                  (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                  u.departmentAccessIds.includes(DEPARTMENT_JUSTICE)
                              )
                      }
                      onChange={(value: any) => {
                        fileContext.setStaffIds(value.map((v: IUser) => v.userId));
                        props.setIsDirty(true);
                        if (props.setUpdateReminder) {
                          props.setUpdateReminder(true);
                        }
                      }}
                      value={selectedStaff}
                      getOptionValue={(option: IUser) => option.userId}
                      getOptionLabel={(option: IUser) =>
                        option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                      }
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'community-impact'}>Community Impact Statement</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'community-impact'}
                      options={communityImpactOptions}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={communityImpactOptions.find(
                        (g) => g.val === fileContext.gladueReportFields?.communityImpact
                      )}
                      type={'text'}
                      onChange={(value: any) => {
                        fileContext.setGladueReportFields(
                          (s) => ({ ...s, communityImpact: value?.val } as IGladueReportFields)
                        );
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: any) => option.val}
                      getOptionLabel={(option: any) => option.display}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                <Col className={'px-0'}>
                  <FormGroup className={'mb-2'}>
                    <Label>Indigenous Group</Label>
                    <Select
                      name={'indigenousGroup'}
                      styles={disabledSelectStylesWithMenu}
                      options={indigenousGroups}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={
                        indigenousGroups.find(
                          (d: any) => d.indigenousGroupId === fileContext.gladueReportFields?.indigenousGroupId
                        ) ?? undefined
                      }
                      onChange={(value) => {
                        fileContext.setGladueReportFields(
                          (s) =>
                            ({
                              ...s,
                              indigenousGroupId: value ? value.indigenousGroupId : undefined,
                              //fixes issue with switching from Metis to Inuit
                              //tries to set a textbox to undefined which clears the value but does not update the textbox itself
                              bandNation: value
                                ? value.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION
                                  ? undefined
                                  : ''
                                : undefined,
                            } as IGladueReportFields)
                        );
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IIndigenousGroup) => option.indigenousGroupId}
                      getOptionLabel={(option: IIndigenousGroup) => option.name}
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                {fileContext.gladueReportFields?.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION && (
                  <Col>
                    <FormGroup className={'mb-2'}>
                      <Label>First Nation</Label>
                      <CreatableSelect
                        formatCreateLabel={(inputValue: string) => inputValue}
                        placeholder={<span className='placeholder'>Select or Type...</span>}
                        styles={disabledSelectStylesWithMenu}
                        options={firstNationOptions}
                        value={
                          firstNationOptions.find(
                            (firstNation) => firstNation.value === fileContext.gladueReportFields?.bandNation
                          ) ?? undefined
                        }
                        onChange={(val) => {
                          fileContext.setGladueReportFields(
                            (s) => ({ ...s, bandNation: val ? val.value : '' } as IGladueReportFields)
                          );
                        }}
                        isMulti={false}
                        isClearable={true}
                      />
                    </FormGroup>
                  </Col>
                )}
                {(fileContext.gladueReportFields?.indigenousGroupId === INDIGENOUS_GROUP_INUIT ||
                  fileContext.gladueReportFields?.indigenousGroupId === INDIGENOUS_GROUP_METIS ||
                  fileContext.gladueReportFields?.indigenousGroupId === INDIGENOUS_GROUP_OTHER) && (
                  <Col>
                    <FormGroup className={'mb-2'}>
                      <Label>Band / Council</Label>
                      <Input
                        type='text'
                        placeholder='Type... '
                        name='band'
                        autoComplete={'off'}
                        disabled={!props.isEditingAllowed}
                        value={fileContext.gladueReportFields?.bandNation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const detail = e.target.value;
                          fileContext.setGladueReportFields(
                            (s) => ({ ...s, bandNation: detail } as IGladueReportFields)
                          );
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
                {fileContext.gladueReportFields?.indigenousGroupId === undefined && <Col></Col>}
              </Row>
              <Row>
                <FormGroup className={'w-100 mb-2 mx-3'}>
                  <Label>File Details/Background</Label>
                  <Input
                    type='textarea'
                    placeholder=''
                    style={{ minHeight: '226px' }}
                    name='name'
                    value={fileContext.details}
                    disabled={!props.isEditingAllowed}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      fileContext.setDetails(e.target.value);
                      props.setIsDirty(true);
                    }}
                  />
                </FormGroup>
              </Row>
            </CardBody>
          </Card>
          <Card className={'mb-4'}>
            <CardHeader>Lawyers</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'defenceLawyerId'}>Defence Lawyer</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='defenceLawyerId'
                      value={fileContext.gladueReportFields?.defenceLawyer}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setGladueReportFields(
                          (s) => ({ ...s, defenceLawyer: e.target.value } as IGladueReportFields)
                        );
                        if (props.setDefenceLawyerChanged) {
                          props.setDefenceLawyerChanged(true);
                        }
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className={'px-0'}>
                  <FormGroup className={'mb-2'}>
                    <Label for={'defenceLawyerContactType'}>Lawyer Contact Type</Label>
                    <CreatableSelect
                      formatCreateLabel={(inputValue: string) => inputValue}
                      placeholder={<span className='placeholder'>Select or Type...</span>}
                      options={[
                        ...contactTypeOptions,
                        ...(!fileContext.gladueReportFields?.defenceLawyerContactDetails ||
                        contactTypeOptions.some(
                          (e) => e.value === fileContext.gladueReportFields?.defenceLawyerContactDetails?.contactType
                        )
                          ? []
                          : [
                              {
                                value: fileContext.gladueReportFields?.defenceLawyerContactDetails.contactType,
                                label: fileContext.gladueReportFields?.defenceLawyerContactDetails.contactType,
                              },
                            ]),
                      ]}
                      value={
                        fileContext.gladueReportFields &&
                        fileContext.gladueReportFields?.defenceLawyerContactDetails &&
                        fileContext.gladueReportFields?.defenceLawyerContactDetails.contactType
                          ? {
                              value: fileContext.gladueReportFields?.defenceLawyerContactDetails.contactType,
                              label: fileContext.gladueReportFields?.defenceLawyerContactDetails.contactType,
                            }
                          : undefined
                      }
                      onChange={(val) => {
                        props.setIsDirty(true);
                        fileContext.setGladueReportFields(
                          (s) =>
                            ({
                              ...s,
                              defenceLawyerContactDetails: {
                                contactType: val ? val.value : '',
                                details:
                                  fileContext.gladueReportFields?.defenceLawyerContactDetails &&
                                  fileContext.gladueReportFields?.defenceLawyerContactDetails.details
                                    ? fileContext.gladueReportFields?.defenceLawyerContactDetails.details
                                    : '',
                              },
                            } as IGladueReportFields)
                        );
                      }}
                      isMulti={false}
                      isClearable={true}
                      isDisabled={!props.isEditingAllowed}
                      styles={disabledSelectStylesWithMenu}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'mb-2'}>
                    <Label>Lawyer Contact Detail</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='defenceLawyerContactInfo'
                      value={
                        fileContext.gladueReportFields?.defenceLawyerContactDetails &&
                        fileContext.gladueReportFields?.defenceLawyerContactDetails.details
                          ? fileContext.gladueReportFields?.defenceLawyerContactDetails.details
                          : ''
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const detail = e.target.value;
                        fileContext.setGladueReportFields(
                          (s) =>
                            ({
                              ...s,
                              defenceLawyerContactDetails: {
                                contactType:
                                  fileContext.gladueReportFields?.defenceLawyerContactDetails &&
                                  fileContext.gladueReportFields?.defenceLawyerContactDetails.contactType
                                    ? fileContext.gladueReportFields?.defenceLawyerContactDetails.contactType
                                    : '',
                                details: detail,
                              },
                            } as IGladueReportFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'crownLawyerId'}>Crown Lawyer</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='crownLawyerId'
                      value={fileContext.gladueReportFields?.crownLawyer}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setGladueReportFields(
                          (s) => ({ ...s, crownLawyer: e.target.value } as IGladueReportFields)
                        );
                        if (props.setCrownLawyerChanged) {
                          props.setCrownLawyerChanged(true);
                        }
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className={'px-0'}>
                  <FormGroup className={'mb-2'}>
                    <Label for={'lawyerContactType'}>Lawyer Contact Type</Label>
                    <CreatableSelect
                      formatCreateLabel={(inputValue: string) => inputValue}
                      placeholder={<span className='placeholder'>Select or Type...</span>}
                      options={[
                        ...contactTypeOptions,
                        ...(!fileContext.gladueReportFields?.crownLawyerContactDetails ||
                        contactTypeOptions.some(
                          (e) => e.value === fileContext.gladueReportFields?.crownLawyerContactDetails?.contactType
                        )
                          ? []
                          : [
                              {
                                value: fileContext.gladueReportFields?.crownLawyerContactDetails.contactType,
                                label: fileContext.gladueReportFields?.crownLawyerContactDetails.contactType,
                              },
                            ]),
                      ]}
                      value={
                        fileContext.gladueReportFields &&
                        fileContext.gladueReportFields?.crownLawyerContactDetails &&
                        fileContext.gladueReportFields?.crownLawyerContactDetails.contactType
                          ? {
                              value: fileContext.gladueReportFields?.crownLawyerContactDetails.contactType,
                              label: fileContext.gladueReportFields?.crownLawyerContactDetails.contactType,
                            }
                          : undefined
                      }
                      onChange={(val) => {
                        props.setIsDirty(true);
                        fileContext.setGladueReportFields(
                          (s) =>
                            ({
                              ...s,
                              crownLawyerContactDetails: {
                                contactType: val ? val.value : '',
                                details:
                                  fileContext.gladueReportFields?.crownLawyerContactDetails &&
                                  fileContext.gladueReportFields?.crownLawyerContactDetails.details
                                    ? fileContext.gladueReportFields?.crownLawyerContactDetails.details
                                    : '',
                              },
                            } as IGladueReportFields)
                        );
                      }}
                      isMulti={false}
                      isClearable={true}
                      isDisabled={!props.isEditingAllowed}
                      styles={disabledSelectStylesWithMenu}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Lawyer Contact Detail</Label>
                    <Input
                      type='text'
                      placeholder=''
                      name='defenceLawyerContactInfo'
                      value={
                        fileContext.gladueReportFields?.crownLawyerContactDetails &&
                        fileContext.gladueReportFields?.crownLawyerContactDetails.details
                          ? fileContext.gladueReportFields?.crownLawyerContactDetails.details
                          : ''
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const detail = e.target.value;
                        fileContext.setGladueReportFields(
                          (s) =>
                            ({
                              ...s,
                              crownLawyerContactDetails: {
                                contactType:
                                  fileContext.gladueReportFields?.crownLawyerContactDetails &&
                                  fileContext.gladueReportFields?.crownLawyerContactDetails.contactType
                                    ? fileContext.gladueReportFields?.crownLawyerContactDetails.contactType
                                    : '',
                                details: detail,
                              },
                            } as IGladueReportFields)
                        );
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
