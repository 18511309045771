import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { RequiredLabel } from '../RequiredLabel';
import { Button, Col, Label, Modal, ModalBody } from 'reactstrap';
import { formatDateFromISONumber } from '../../formatters/DateTimeFormatter';
import { setHours, setMinutes } from 'date-fns';
import { IEventWorkshop, IEventParticipant } from './WorkshopsTable';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { ClientsContext } from '../../contexts/ClientsContext';
import { FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import { DomainContext, IAttendance, WORKSHOP_EVENT_ATTENDANCE_ATTENDED } from '../../contexts/DomainContext';
import { disabledSelectStyles } from '../../utils/StylingUtils';

interface IProps {
  eventWorkshopId?: string;
  eventStartDate: number;
  eventEndDate?: number;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  index: number;
  setWorkshopEvents: Dispatch<SetStateAction<IEventWorkshop[]>>;
  openIndex?: number;
  setOpenIndex: Dispatch<SetStateAction<number>>;
  workshopParticipants: string[];
  attendanceTaken: boolean;
  participants: IEventParticipant[];
}

export const EventDetails: React.FC<IProps> = (props) => {
  const clientsContext = useContext(ClientsContext);
  const domainContext = useContext(DomainContext);
  const attendance = domainContext.attendance;
  const clients = clientsContext.state.clients;
  const [attendanceModalOpen, setAttendanceModalOpen] = useState<boolean>(false);
  const [openDeleteEventModal, setOpenDeleteEventModal] = useState(false);
  const currentDate = new Date();
  const [tempParticipants, setTempParticipants] = useState<IEventParticipant[]>([]);
  const [attendanceDropdownOpen, setAttendanceDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    //newParticipants are participants that were added after the Event was created, so they have no attendance for this event
    const newParticipants = props.workshopParticipants
      .filter((pId) => !props.participants.some((p) => p.participantId === pId))
      .map((newP) => ({ participantId: newP, attendanceId: undefined } as IEventParticipant));

    setTempParticipants(props.participants.concat(newParticipants));
  }, [props.workshopParticipants, props.participants]);

  const getName = (participant: string) => {
    const p = clients.find((c) => c.clientId === participant);
    if (p !== undefined) {
      return p.lastName?.toUpperCase() + ', ' + p.firstName + ' ' + p.middleName;
    } else {
      return '';
    }
  };

  const indexIsOpen = props.openIndex === props.index;

  useEffect(() => {
    var elmnt = document.getElementById('attendanceModal');
    if (elmnt) {
      elmnt.scrollTop = elmnt.scrollHeight;
    }
  }, [attendanceDropdownOpen]);

  return (
    <>
      <div className={'d-flex flex-grow-1 justify-content-between flex-wrap'}>
        {indexIsOpen ? (
          <>
            <div className={'d-flex align-items-baseline flex-fill'} style={{ gap: 6 }}>
              <RequiredLabel required={props.eventStartDate === undefined} for={'startDate'} className={'sr-only'}>
                Start Date
              </RequiredLabel>
              <DatePicker
                selected={props.eventStartDate ? new Date(props.eventStartDate) : undefined}
                id={'startDate'}
                onChange={(dateToSet) => {
                  if (dateToSet) {
                    props.setWorkshopEvents((oldEW) => [
                      ...oldEW.slice(0, props.index),
                      {
                        ...oldEW[props.index],
                        startDate: dateToSet ? dateToSet.getTime() : new Date().getTime(),
                        //set endDate to one hour ahead of startDate
                        endDate: dateToSet
                          ? dateToSet.getTime() + 60 * 60 * 1000
                          : new Date().getTime() + 60 * 60 * 1000,
                      },
                      ...oldEW.slice(props.index + 1),
                    ]);
                    props.setIsDirty(true);
                  }
                }}
                className={'form-control flex-grow-1'}
                minTime={setHours(setMinutes(currentDate, 0), 0)}
                maxTime={setHours(setMinutes(currentDate, 59), 23)}
                placeholderText={'MMM dd, yyyy hh:mm aa'}
                peekNextMonth
                showMonthDropdown
                showTimeSelect={true}
                showYearDropdown
                dateFormat='MMM dd, yyyy hh:mm aa'
              />
              <Label for={'endDate'} className={'sr-only'}>
                End Date
              </Label>
              <DatePicker
                selected={props.eventEndDate ? new Date(props.eventEndDate) : undefined}
                id={'endDate'}
                onChange={(dateToSet) => {
                  if (dateToSet) {
                    props.setWorkshopEvents((oldEW) => [
                      ...oldEW.slice(0, props.index),
                      {
                        ...oldEW[props.index],
                        endDate: dateToSet ? dateToSet.getTime() : undefined,
                      },
                      ...oldEW.slice(props.index + 1),
                    ]);
                    props.setIsDirty(true);
                  } else {
                    props.setWorkshopEvents((oldEW) => [
                      ...oldEW.slice(0, props.index),
                      {
                        ...oldEW[props.index],
                        endDate: undefined,
                      },
                      ...oldEW.slice(props.index + 1),
                    ]);
                    props.setIsDirty(true);
                  }
                }}
                className={'form-control flex-grow-1'}
                isClearable={true}
                minDate={new Date(props.eventStartDate)}
                minTime={setHours(
                  new Date(props.eventStartDate).getMinutes(),
                  new Date(props.eventStartDate).getHours()
                )}
                maxTime={setHours(setMinutes(currentDate, 59), 23)}
                placeholderText={'MMM dd, yyyy hh:mm aa'}
                peekNextMonth
                showMonthDropdown
                showTimeSelect={true}
                showYearDropdown
                dateFormat='MMM dd, yyyy hh:mm aa'
              />
            </div>
            <div
              className={'d-flex flex-grow-1 align-items-center justify-content-end flex-wrap'}
              style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
            >
              <Button color={'link'} size={'sm'} disabled={false} onClick={() => props.setOpenIndex(-1)}>
                Done
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={'d-flex align-items-baseline flex-fill'} style={{ gap: 6 }}>
              {formatDateFromISONumber(props.eventStartDate)}
              {props.eventEndDate ? ' - ' : ''}
              {formatDateFromISONumber(props.eventEndDate)}
              <Button color={'link'} size={'sm'} disabled={false} onClick={() => props.setOpenIndex(props.index)}>
                Edit
              </Button>
            </div>
            <div
              className={'d-flex flex-grow-1 align-items-center justify-content-end flex-wrap'}
              style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}
            >
              {props.attendanceTaken && (
                <div className={'text-center small text-muted'}>
                  {tempParticipants.filter((p) => p.attendanceId === WORKSHOP_EVENT_ATTENDANCE_ATTENDED).length +
                    ' / ' +
                    tempParticipants.length +
                    ' Attended'}
                </div>
              )}
              <Button
                color={props.attendanceTaken ? 'light' : 'primary'}
                size={'sm'}
                className={'w-mobile-100'}
                disabled={!props.openIndex}
                onClick={() => {
                  setAttendanceModalOpen(true);
                }}
              >
                {props.attendanceTaken ? 'Edit Attendance' : 'Take Attendance'}
              </Button>
              <Button
                color={'link'}
                disabled={!props.openIndex}
                className={'p-0 text-danger'}
                onClick={() => {
                  setOpenDeleteEventModal(true);
                }}
                aria-label={'Remove Event'}
                title={'Remove Event'}
              >
                <FaTimes />
              </Button>
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={attendanceModalOpen}
        participantList={props.workshopParticipants}
        style={{ overflow: 'visible', minWidth: '40%' }}
      >
        <ModalHeader>Attendance for {formatDateFromISONumber(props.eventStartDate)}</ModalHeader>
        <ModalBody id={'attendanceModal'} style={{ minHeight: '30vh', maxHeight: '80vh', overflowY: 'auto' }}>
          <div className='d-flex flex-column'>
            {tempParticipants
              .sort((a, b) =>
                getName(a.participantId).toUpperCase().localeCompare(getName(b.participantId).toUpperCase())
              )
              .map((participant, i) => (
                <div key={participant.participantId} className='d-inline-flex align-items-center'>
                  <Col>
                    <Label>{getName(participant.participantId)}</Label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className={'mb-2 flex-grow-1'}
                      styles={disabledSelectStyles}
                      name={'attendanceId'}
                      onMenuOpen={() => setAttendanceDropdownOpen(true)}
                      onMenuClose={() => setAttendanceDropdownOpen(false)}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={attendance}
                      value={attendance.find((a: IAttendance) => a.attendanceId === participant.attendanceId) || null}
                      onChange={(value: any) => {
                        setTempParticipants((oldTP) => [
                          ...oldTP.slice(0, i),
                          { ...oldTP[i], attendanceId: value ? value.attendanceId : undefined },
                          ...oldTP.slice(i + 1),
                        ]);
                      }}
                      getOptionValue={(option: IAttendance) => option.attendanceId}
                      getOptionLabel={(option: IAttendance) => option.name}
                      isClearable={true}
                    />
                  </Col>
                </div>
              ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            onClick={() => {
              props.setWorkshopEvents((oldEW) => [
                ...oldEW.slice(0, props.index),
                {
                  ...oldEW[props.index],
                  attendanceTaken: true,
                  participants: tempParticipants,
                },
                ...oldEW.slice(props.index + 1),
              ]);
              setAttendanceModalOpen((s: boolean) => !s);
              props.setIsDirty(true);
            }}
          >
            Save
          </Button>
          <Button
            color={'link'}
            onClick={() => {
              setAttendanceModalOpen((s: boolean) => !s);
              //newParticipants are participants that were added after the Event was created, so they have no attendance for this event
              const newParticipants = props.workshopParticipants
                .filter((pId) => !props.participants.some((p) => p.participantId === pId))
                .map((newP) => ({ participantId: newP, attendanceId: undefined } as IEventParticipant));
              setTempParticipants(props.participants.concat(newParticipants));
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openDeleteEventModal}
        toggle={() => setOpenDeleteEventModal(!openDeleteEventModal)}
        className='logout-modal'
      >
        <ModalHeader toggle={() => setOpenDeleteEventModal(!openDeleteEventModal)}>Remove Event</ModalHeader>
        <ModalBody>
          <div className={'mb-4'}>
            Are you sure you want to remove this event? Any attendance records for the event will be deleted.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'danger'}
            onClick={() => {
              props.setWorkshopEvents((oldR) => [...oldR.slice(0, props.index), ...oldR.slice(props.index + 1)]);
              props.setIsDirty(true);
            }}
          >
            Yes
          </Button>
          <Button color={'light'} onClick={() => setOpenDeleteEventModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
