import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, CustomInput, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { DEPARTMENT_BIRTH_WORKER, DomainContext, IPrePostNatal, IStage } from '../../contexts/DomainContext';
import { FileContext, IBirthWorkersFields } from './FileContext';
import { UsersContext } from '../../contexts/UsersContext';
import { IUser } from '../Users/UsersTable';
import { RequiredLabel } from '../RequiredLabel';
import DatePicker from 'react-datepicker';
import { MyUserContext } from '../../contexts/MyUserContext';
import { disabledSelectStylesWithMenu } from '../../utils/StylingUtils';
import { FinancialSupports } from '../../components/Files/FileFinancialSupports';

export const BirthWorkerFileDetails: React.FC<{
  isEditingAllowed: boolean;
  trySave: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUncheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const domainContext = useContext(DomainContext);
  const fileContext = useContext(FileContext);
  const usersContext = useContext(UsersContext);
  const myUser = useContext(MyUserContext);

  const stages = domainContext.stages;
  const prePostNatalOptions = domainContext.prePostNatalOptions;

  const staff = usersContext.users;
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  return (
    <>
      <Row>
        <Col sm={12} lg={6}>
          <Card className={'mb-3'}>
            <CardHeader>File Details</CardHeader>
            <CardBody>
              {myUser.isSuperAdmin && (
                <Row className='square border-bottom mb-2'>
                  <Col>
                    <FormGroup>
                      <Label for={'isConfidential'}>Confidentiality</Label>
                      <CustomInput
                        type='checkbox'
                        id={'isConfidential'}
                        name={'isConfidential'}
                        label={'Highly Confidential File'}
                        checked={fileContext.isConfidential}
                        onChange={() => {
                          if (fileContext.isConfidential) {
                            props.setOpenUncheckConfidentialityModal(true);
                          } else {
                            props.setOpenCheckConfidentialityModal(true);
                          }
                          fileContext.setIsConfidential(!fileContext.isConfidential);
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'stageId'}>Stage</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'stageId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={stages.filter(
                        (s) => s.fileTypes && s.fileTypes.includes(fileContext.fileTypeId ? fileContext.fileTypeId : '')
                      )}
                      value={stages.find((s: IStage) => s.stageId === fileContext.stageId)}
                      onChange={(value: any) => {
                        fileContext.setStageId(value?.stageId || '');
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IStage) => option.stageId}
                      getOptionLabel={(option: IStage) => option.name}
                      isDisabled={!props.isEditingAllowed}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <RequiredLabel for={'application-date'} required={fileContext.createdDate === undefined}>
                      Created Date
                    </RequiredLabel>
                    <DatePicker
                      selected={fileContext.createdDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCreatedDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                    {props.trySave && fileContext.createdDate === undefined && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Pre/Postnatal</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'prePostNatalId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={prePostNatalOptions}
                      value={prePostNatalOptions.find(
                        (p: IPrePostNatal) => p.prePostNatalId === fileContext.birthWorkerFields?.prePostNatalId
                      )}
                      onChange={(value: any) => {
                        fileContext.setBirthWorkerFields(
                          (s) => ({ ...s, prePostNatalId: value?.prePostNatalId } as IBirthWorkersFields)
                        );
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IPrePostNatal) => option.prePostNatalId}
                      getOptionLabel={(option: IPrePostNatal) => option.name}
                      isDisabled={!props.isEditingAllowed}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'staffIds'}>Staff</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'staffIds'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={
                        fileContext.isConfidential
                          ? staff
                              .sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName))
                              .filter(
                                (u) =>
                                  (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                  u.departmentAccessIds.includes(DEPARTMENT_BIRTH_WORKER) &&
                                  u.role === 'SuperAdmin'
                              )
                          : staff
                              .sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName))
                              .filter(
                                (u) =>
                                  (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                  u.departmentAccessIds.includes(DEPARTMENT_BIRTH_WORKER)
                              )
                      }
                      onChange={(value: any) => {
                        fileContext.setStaffIds(value.map((v: IUser) => v.userId));
                        props.setIsDirty(true);
                      }}
                      value={selectedStaff}
                      getOptionValue={(option: IUser) => option.userId}
                      getOptionLabel={(option: IUser) =>
                        option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                      }
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className={'mb-sm-3 mb-md-3'}>
          <FinancialSupports setIsDirty={props.setIsDirty} />
        </Col>
      </Row>
    </>
  );
};
