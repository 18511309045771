import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, CustomInput, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { FileContext, IWCCConnectiveFields } from './FileContext';
import DatePicker from 'react-datepicker';
import { UsersContext } from '../../contexts/UsersContext';
import { IUser } from '../Users/UsersTable';
import CreatableSelect from 'react-select/creatable';
import {
  DEPARTMENT_JUSTICE,
  DomainContext,
  IIndigenousGroup,
  INDIGENOUS_GROUP_FIRST_NATION,
  INDIGENOUS_GROUP_INUIT,
  INDIGENOUS_GROUP_METIS,
  INDIGENOUS_GROUP_OTHER,
  IStage,
} from '../../contexts/DomainContext';
import { MyUserContext } from '../../contexts/MyUserContext';
import { disabledSelectStylesWithMenu } from '../../utils/StylingUtils';

export const WCCConnectiveFileDetails: React.FC<{
  isEditingAllowed: boolean;
  trySave: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateReminder?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUncheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCheckConfidentialityModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const fileContext = useContext(FileContext);
  const usersContext = useContext(UsersContext);
  const domainContext = useContext(DomainContext);
  const myUser = useContext(MyUserContext);
  const stages = domainContext.stages;
  const yukonFns = domainContext.yukonFns;
  const indigenousGroups = domainContext.indigenousGroups;

  const staff = usersContext.users;
  const [selectedStaff, setSelectedStaff] = useState<IUser[]>([]);

  const participated = [
    { val: 1, display: 'Yes' },
    { val: 0, display: 'No' },
  ];

  const firstNationOptions = yukonFns.map((fn) => ({ value: fn.yukonFirstNationId, label: fn.name }));

  useEffect(() => {
    setSelectedStaff(staff.filter((s) => fileContext.staffIds.includes(s.userId)));
  }, [staff, fileContext.staffIds]);

  const clientStatusOptions = [
    { value: 'Remand', label: 'Remand' },
    { value: 'Sentenced', label: 'Sentenced' },
    { value: 'Community', label: 'Community' },
  ];

  const clientStatus = fileContext.wccConnectiveFields?.clientStatus;
  const setClientStatus = (e?: string) =>
    fileContext.setWCCConnectiveFields((s) => ({ ...s, clientStatus: e } as IWCCConnectiveFields));

  return (
    <>
      <Row className={'mb-4'}>
        <Col>
          <Card>
            <CardHeader>Court Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'sentencing-date'}>Sentencing Date</Label>
                    <DatePicker
                      selected={
                        fileContext.wccConnectiveFields?.sentencingDate
                          ? new Date(fileContext.wccConnectiveFields?.sentencingDate)
                          : undefined
                      }
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setWCCConnectiveFields(
                          (s) => ({ ...s, sentencingDate: date?.getTime() } as IWCCConnectiveFields)
                        );
                        if (props.setUpdateReminder) {
                          props.setUpdateReminder(true);
                        }
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      isClearable={true}
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                  </FormGroup>
                </Col>
                <Col className={'px-0'}>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Client Status</Label>
                    <CreatableSelect
                      formatCreateLabel={(inputValue: string) => inputValue}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={[
                        ...clientStatusOptions,
                        ...(!clientStatus || clientStatusOptions.some((e) => e.value === clientStatus)
                          ? []
                          : [{ value: clientStatus, label: clientStatus }]),
                      ]}
                      value={clientStatus ? { value: clientStatus, label: clientStatus } : undefined}
                      onChange={(val) => {
                        props.setIsDirty(true);
                        setClientStatus(val ? val.value : '');
                      }}
                      isClearable={true}
                      isMulti={false}
                      isDisabled={!props.isEditingAllowed}
                      styles={disabledSelectStylesWithMenu}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'participated'}>Participated in Discharge and Case Planning</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'participated'}
                      options={participated}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={participated.find(
                        (g) => g.val === fileContext.wccConnectiveFields?.participatedInDischargeAndCasePlanning
                      )}
                      type={'text'}
                      onChange={(value: any) => {
                        fileContext.setWCCConnectiveFields(
                          (s) => ({ ...s, participatedInDischargeAndCasePlanning: value?.val } as IWCCConnectiveFields)
                        );
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: any) => option.val}
                      getOptionLabel={(option: any) => option.display}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>Charges</Label>
                    <Input
                      type='textarea'
                      placeholder=''
                      style={{ minHeight: '226px' }}
                      name='name'
                      value={fileContext.wccConnectiveFields?.charges}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const charges = e.target.value;
                        fileContext.setWCCConnectiveFields((s) => ({ ...s, charges } as IWCCConnectiveFields));
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>File Details</CardHeader>
            <CardBody>
              {myUser.isSuperAdmin && (
                <Row className='square border-bottom mb-2'>
                  <Col>
                    <FormGroup>
                      <Label for={'isConfidential'}>Confidentiality</Label>
                      <CustomInput
                        type='checkbox'
                        id={'isConfidential'}
                        name={'isConfidential'}
                        label={'Highly Confidential File'}
                        checked={fileContext.isConfidential}
                        onChange={() => {
                          if (fileContext.isConfidential) {
                            props.setOpenUncheckConfidentialityModal(true);
                          } else {
                            props.setOpenCheckConfidentialityModal(true);
                          }
                          fileContext.setIsConfidential(!fileContext.isConfidential);
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'stageId'}>Stage</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'stageId'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={stages.filter(
                        (s) =>
                          s.fileTypes &&
                          s.fileTypes.includes(fileContext.fileTypeId ? fileContext.fileTypeId : '') &&
                          (s.fileSubTypes
                            ? s.fileSubTypes.includes(fileContext.fileSubTypeId ? fileContext.fileSubTypeId : '')
                            : true)
                      )}
                      value={stages.find((s: IStage) => s.stageId === fileContext.stageId)}
                      onChange={(value: any) => {
                        fileContext.setStageId(value?.stageId || '');
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IStage) => option.stageId}
                      getOptionLabel={(option: IStage) => option.name}
                      isDisabled={!props.isEditingAllowed}
                    />
                    <FormFeedback>This field is mandatory</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'application-date'}>Created Date</Label>
                    <DatePicker
                      selected={fileContext.createdDate}
                      name={'application-date'}
                      onChange={(date) => {
                        fileContext.setCreatedDate(date ? date : undefined);
                        props.setIsDirty(true);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      disabled={!props.isEditingAllowed}
                      showYearDropdown
                      isClearable={true}
                      dateFormat='MMM dd, yyyy'
                      dropdownMode='select'
                      className={
                        props.isEditingAllowed ? 'date-picker rounded mb-0' : 'date-picker-disabled rounded mb-0'
                      }
                      placeholderText={'MMM dd, yyyy'}
                    />
                    {props.trySave && fileContext.createdDate === undefined && (
                      <span style={{ fontSize: 13, color: '#B22222' }}>This field is mandatory</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label for={'staffIds'}>Staff</Label>
                    <Select
                      styles={disabledSelectStylesWithMenu}
                      name={'staffIds'}
                      placeholder={<span className='placeholder'>Select...</span>}
                      options={
                        fileContext.isConfidential
                          ? staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_JUSTICE) &&
                                u.role === 'SuperAdmin'
                            )
                          : staff.filter(
                              (u) =>
                                (u.active || fileContext.file.staffIds?.some((e) => e === u.userId)) &&
                                u.departmentAccessIds.includes(DEPARTMENT_JUSTICE)
                            )
                      }
                      onChange={(value: any) => {
                        fileContext.setStaffIds(value.map((v: IUser) => v.userId));
                        if (props.setUpdateReminder) {
                          props.setUpdateReminder(true);
                        }
                        props.setIsDirty(true);
                      }}
                      value={selectedStaff}
                      getOptionValue={(option: IUser) => option.userId}
                      getOptionLabel={(option: IUser) =>
                        option.lastName + ', ' + option.firstName + (option.active ? '' : ' (Inactive)')
                      }
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'mb-2'}>
                    <Label>Indigenous Group</Label>
                    <Select
                      name={'indigenousGroup'}
                      styles={disabledSelectStylesWithMenu}
                      options={indigenousGroups}
                      placeholder={<span className='placeholder'>Select...</span>}
                      value={
                        indigenousGroups.find(
                          (d: any) => d.indigenousGroupId === fileContext.wccConnectiveFields?.indigenousGroupId
                        ) ?? undefined
                      }
                      onChange={(value) => {
                        fileContext.setWCCConnectiveFields(
                          (s) =>
                            ({
                              ...s,
                              indigenousGroupId: value ? value.indigenousGroupId : undefined,
                              //fixes issue with switching from Metis to Inuit
                              //tries to set a textbox to undefined which clears the value but does not update the textbox itself
                              bandNation: value
                                ? value.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION
                                  ? undefined
                                  : ''
                                : undefined,
                            } as IWCCConnectiveFields)
                        );
                        props.setIsDirty(true);
                      }}
                      getOptionValue={(option: IIndigenousGroup) => option.indigenousGroupId}
                      getOptionLabel={(option: IIndigenousGroup) => option.name}
                      isDisabled={!props.isEditingAllowed}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                {fileContext.wccConnectiveFields?.indigenousGroupId === INDIGENOUS_GROUP_FIRST_NATION && (
                  <Col>
                    <FormGroup className={'mb-2'}>
                      <Label>First Nation</Label>
                      <CreatableSelect
                        formatCreateLabel={(inputValue: string) => inputValue}
                        placeholder={<span className='placeholder'>Select or Type...</span>}
                        styles={disabledSelectStylesWithMenu}
                        options={firstNationOptions}
                        value={
                          firstNationOptions.find(
                            (firstNation) => firstNation.value === fileContext.wccConnectiveFields?.bandNation
                          ) ?? undefined
                        }
                        onChange={(val) => {
                          fileContext.setWCCConnectiveFields(
                            (s) => ({ ...s, bandNation: val ? val.value : '' } as IWCCConnectiveFields)
                          );
                        }}
                        isMulti={false}
                        isClearable={true}
                      />
                    </FormGroup>
                  </Col>
                )}
                {(fileContext.wccConnectiveFields?.indigenousGroupId === INDIGENOUS_GROUP_INUIT ||
                  fileContext.wccConnectiveFields?.indigenousGroupId === INDIGENOUS_GROUP_METIS ||
                  fileContext.wccConnectiveFields?.indigenousGroupId === INDIGENOUS_GROUP_OTHER) && (
                  <Col>
                    <FormGroup className={'mb-2'}>
                      <Label>Band / Council</Label>
                      <Input
                        type='text'
                        placeholder='Type... '
                        name='band'
                        autoComplete={'off'}
                        disabled={!props.isEditingAllowed}
                        value={fileContext.wccConnectiveFields?.bandNation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const detail = e.target.value;
                          fileContext.setWCCConnectiveFields(
                            (s) => ({ ...s, bandNation: detail } as IWCCConnectiveFields)
                          );
                          props.setIsDirty(true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
                {fileContext.wccConnectiveFields?.indigenousGroupId === undefined && <Col></Col>}
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'w-100 mb-2'}>
                    <Label>File Details/Background</Label>
                    <Input
                      type='textarea'
                      placeholder=''
                      style={{ minHeight: '226px' }}
                      name='name'
                      value={fileContext.details}
                      disabled={!props.isEditingAllowed}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        fileContext.setDetails(e.target.value);
                        props.setIsDirty(true);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
