import * as React from 'react';
import { FormEvent, useState } from 'react';
import { Col, Container, Navbar, NavbarBrand, Row, Input, FormGroup, Label, Button } from 'reactstrap';
import { useEffect } from 'react';
import { authAxios } from '../../../services/AxiosService';
import { useParams } from 'react-router';
import Select from 'react-select';
import { formatDateTime } from '../../../formatters/DateTimeFormatter';
import { getJwtFromStorage } from '../../../services/AuthenticationService';
import { notifyError, notifySuccess } from '../../../services/ToastService';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import {
  DomainContext,
  IDocumentType,
  NOTE_DOCUMENT_TYPE,
  WORKSHOP_DOCUMENT_TYPE,
} from '../../../contexts/DomainContext';
import { LeavePageConfirm } from '../../LeavePageConfirm/LeavePageConfirm';
import { IDocument } from './Documents';
import { disabledSelectStyles } from '../../../utils/StylingUtils';

export const FileDocumentDetails: React.FC = () => {
  const params = useParams<{ fileId: string; documentId: string }>();
  const [doc, setDoc] = useState<IDocument | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const history = useHistory();
  const domainContext = useContext(DomainContext);
  const documentTypes = domainContext.fileAndClientDocumentTypes;

  useEffect(() => {
    authAxios.get('/api/files/' + params.fileId + '/documents/' + params.documentId).then((response) => {
      if (response.data) {
        setDoc(response.data);
      }
    });
  }, [params.fileId, params.documentId]);

  const downloadDocument = (uploadId: string) => {
    const getUrl = (uId: string) => '/api/uploads/download/' + uId + '/' + getJwtFromStorage();
    const link = document.createElement('a');
    console.log(getUrl(uploadId));
    link.href = getUrl(uploadId);
    link.setAttribute('download', 'download');
    document.body.appendChild(link);
    link.click();
  };

  const saveDoc = () => {
    const data = {
      fileId: params.fileId,
      documentId: params.documentId,
      documentTypeId: doc?.documentTypeId,
      description: doc?.description,
    };
    authAxios.put('/api/documents/update-document', data).then((response) => {
      if (response.data.status === 'OK') {
        setIsDirty(false);
        notifySuccess('File updated.');
      } else {
        notifyError(response.data.errorMessage);
      }
    });
  };

  const deleteDoc = () => {
    const data = {
      fileId: params.fileId,
      documentId: params.documentId,
    };
    authAxios.post('/api/documents/remove-file-document', data).then((response) => {
      if (response.data.status === 'OK') {
        notifySuccess('Document deleted.');
        history.push({
          pathname: '/files/' + params.fileId + '/documents',
          state: { deletedDocumentIds: [params.documentId] },
        });
      } else {
        notifyError(response.data.errorMessage);
      }
    });
  };

  return (
    <>
      <LeavePageConfirm isDirty={isDirty} />
      <div>
        <form
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}
        >
          <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
            <Row className={'flex-fill'}>
              <Col md={3} xl={2}>
                <NavbarBrand>Document Details</NavbarBrand>
              </Col>
              <Col className={'d-flex'}>
                {doc?.documentTypeId !== NOTE_DOCUMENT_TYPE && (
                  <Button className={'ml-auto'} color={'danger'} onClick={() => setIsDeleteModalOpen(true)}>
                    Delete
                  </Button>
                )}
                <Button
                  className={doc?.documentTypeId !== NOTE_DOCUMENT_TYPE ? 'ml-2' : 'ml-auto'}
                  color={'primary'}
                  onClick={() => saveDoc()}
                >
                  Save Details
                </Button>
              </Col>
            </Row>
          </Navbar>
          <Container fluid={true} className={'my-3'}>
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <FormGroup>
                  <Label>File Name</Label>
                  <Input
                    type='text'
                    placeholder='Type here...'
                    name='docFileName'
                    disabled={true}
                    value={doc?.fileName}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Document Type</Label>
                  <Select
                    styles={disabledSelectStyles}
                    name={'documentTypeId'}
                    options={documentTypes.filter(
                      (dt) =>
                        (dt.documentTypeId !== NOTE_DOCUMENT_TYPE && dt.documentTypeId !== WORKSHOP_DOCUMENT_TYPE) ||
                        doc?.documentTypeId === NOTE_DOCUMENT_TYPE
                    )}
                    isDisabled={doc?.documentTypeId === NOTE_DOCUMENT_TYPE}
                    placeholder={<span className='placeholder'>Select...</span>}
                    value={documentTypes.find((d: IDocumentType) => d.documentTypeId === doc?.documentTypeId)}
                    onChange={(value: any) => {
                      setIsDirty(true);
                      setDoc((d) => (d ? { ...d, documentTypeId: value.documentTypeId } : undefined));
                    }}
                    getOptionValue={(option: IDocumentType) => option.documentTypeId}
                    getOptionLabel={(option: IDocumentType) => option.name}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Document Description</Label>
                  <Input
                    type='text'
                    placeholder='Type here...'
                    name='docDescription'
                    value={doc?.description}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsDirty(true);
                      setDoc((d) => (d ? { ...d, description: e.target.value } : undefined));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Uploaded By</Label>
                  <Input
                    type='text'
                    disabled={true}
                    placeholder='Type here...'
                    name='uploadedBy'
                    value={doc?.uploadedBy}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Uploaded Date</Label>
                  <Input
                    type='text'
                    placeholder='Type here...'
                    name='uploadDate'
                    disabled={true}
                    value={doc?.uploadDate ? formatDateTime(new Date(doc.uploadDate)) : ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Button
                    className={'ml-auto'}
                    color={'primary'}
                    onClick={() => {
                      if (doc?.uploadId) {
                        downloadDocument(doc.uploadId);
                      }
                    }}
                  >
                    Download
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        title={'Close File'}
        onConfirm={() => {
          setIsDeleteModalOpen(false);
          deleteDoc();
        }}
        onCancel={() => setIsDeleteModalOpen(false)}
        confirmButton={'Delete Document'}
      >
        <div>Are you sure you want to delete this document?</div>
      </ConfirmModal>
    </>
  );
};
