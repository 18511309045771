import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { LeavePageConfirm } from '../LeavePageConfirm/LeavePageConfirm';
import { Button, Col, Container, CustomInput, FormGroup, Input, Label, Navbar, NavbarBrand, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { authAxios } from '../../services/AxiosService';
import { useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../services/ToastService';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { FileContext } from './FileContext';
import DatePicker from 'react-datepicker';
import { UsersContext } from '../../contexts/UsersContext';
import { IAppointment } from './FileAppointments';
import Select from 'react-select';
import { IUser } from '../Users/UsersTable';
import {
  fileTypeIdToDepartmentId,
  getPreviousBusinessDay,
  NOTIFICATION_TYPE_EMAIL,
  NOTIFICATION_TYPE_ON_SCREEN,
  REMINDER_TYPE_APPOINTMENT,
} from '../../contexts/DomainContext';
import { FileReminderForm } from './FileReminderForm';
import { IReminder } from './FileReminders';
import { formatDateTime } from '../../formatters/DateTimeFormatter';
import { disabledSelectStylesWithMenu } from '../../utils/StylingUtils';

export const FileAppointmentsDetails: React.FC = (props) => {
  const fileContext = useContext(FileContext);
  const [isDirty, setIsDirty] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [description, setDescription] = useState<string | undefined>();
  const [location, setLocation] = useState<string | undefined>('');
  const [staff, setStaff] = useState<string[]>(fileContext.staffIds);
  const [addReminder, setAddReminder] = useState(false);
  const [clientAttended, setClientAttended] = useState<boolean | undefined>(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const params = useParams<{ fileId: string; appointmentId?: string }>();
  const history = useHistory();
  const [printOption, setPrintOption] = useState('');
  const usersContext = useContext(UsersContext);
  const users = usersContext.users;
  const initialReminder = {
    reminderId: '',
    text: 'Appointment on: ' + (date ? formatDateTime(date) : '') + ' at location: ' + location,
    date: getPreviousBusinessDay(date).getTime(),
    staffToNotify: fileContext.staffIds,
    notificationTypeIds: [NOTIFICATION_TYPE_ON_SCREEN, NOTIFICATION_TYPE_EMAIL],
    resolved: false,
  } as IReminder;
  const [reminder, setReminder] = useState<IReminder | undefined>(initialReminder);

  useEffect(() => {
    setReminder((r) =>
      r
        ? {
            ...r,
            text: 'Appointment on: ' + formatDateTime(date) + ' at location: ' + location,
            date: getPreviousBusinessDay(date).getTime(),
          }
        : undefined
    );
  }, [location, date]);

  useEffect(() => {
    if (params.appointmentId) {
      authAxios.get('/api/files/admin/' + params.fileId + '/appointments/' + params.appointmentId).then((response) => {
        const a: IAppointment = response.data;
        setDate(a.date ? new Date(a.date) : new Date());
        setDescription(a.description);
        setLocation(a.location);
        setStaff(a.staff);
        setAddReminder(a.hasReminder);
        setReminder(a.reminder);
        setClientAttended(a.clientAttended);
      });
    }
  }, [params.appointmentId, params.fileId]);

  useEffect(() => {
    if (printOption !== '') {
      window.print();
      setPrintOption('');
    }
  }, [printOption]);

  const clientAttendedOptions = [
    { val: true, display: 'Yes' },
    { val: false, display: 'No' },
  ];

  const save = () => {
    if (params.appointmentId) {
      //update
      authAxios
        .post('/api/files/update-appointment', {
          fileId: params.fileId,
          appointmentId: params.appointmentId,
          date: date.getTime(),
          description: description,
          location: location,
          staff: staff,
          addReminder: addReminder,
          reminder: {
            ...reminder,
            reminderId: reminder?.reminderId,
            text: reminder?.text,
            date: reminder?.date,
            staffToNotify: reminder?.staffToNotify,
            notificationTypeIds: reminder?.notificationTypeIds,
            reminderTypeId: REMINDER_TYPE_APPOINTMENT,
            resolved: reminder?.resolved,
            emailSent: false,
          },
          clientAttended: clientAttended,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            fileContext.setAppointments((a) =>
              a.map((appointment) => {
                if (appointment.appointmentId === params.appointmentId) {
                  return {
                    appointmentId: params.appointmentId,
                    date: date.getTime(),
                    description: description,
                    location: location,
                    staff: staff,
                    hasReminder: addReminder,
                    reminder: reminder,
                    clientAttended: clientAttended,
                  } as IAppointment;
                } else {
                  return appointment;
                }
              })
            );
            if (response.data.reminderId && response.data.reminderUpdateType === 'update') {
              //update fileContext with updated reminder
              const rIndex: number = fileContext.reminders.findIndex((r) => r.reminderId === response.data.reminderId);
              fileContext.reminders[rIndex].staffToNotify = reminder?.staffToNotify ? reminder.staffToNotify : [];
              fileContext.reminders[rIndex].date = reminder?.date;
              fileContext.reminders[rIndex].notificationTypeIds = reminder?.notificationTypeIds
                ? reminder?.notificationTypeIds
                : [];
              fileContext.reminders[rIndex].text = reminder?.text ? reminder.text : '';
              fileContext.setReminders(fileContext.reminders);
            } else if (response.data.reminderId && response.data.reminderUpdateType === 'create') {
              //if a new reminder was created instead, add it to the context
              fileContext.setReminders((r) =>
                r.concat([
                  {
                    reminderId: response.data.reminderId,
                    text: reminder?.text ? reminder.text : '',
                    date: reminder?.date,
                    staffToNotify: reminder?.staffToNotify ? reminder.staffToNotify : [],
                    notificationTypeIds: reminder?.notificationTypeIds ? reminder.notificationTypeIds : [],
                    reminderTypeId: REMINDER_TYPE_APPOINTMENT,
                    resolved: reminder?.resolved ? reminder.resolved : false,
                  },
                ])
              );
            } else if (response.data.reminderId && response.data.reminderUpdateType === 'delete') {
              if (response.data.reminderId) {
                fileContext.setReminders((rArray) =>
                  rArray.filter((rem) => rem.reminderId !== response.data.reminderId)
                );
              }
            }
            notifySuccess('Appointment updated.');
          } else {
            notifyError('Error updating appointment.');
          }
        });
    } else {
      //create
      authAxios
        .post('/api/files/create-appointment', {
          fileId: params.fileId,
          date: date.getTime(),
          description: description,
          location: location,
          staff: staff,
          addReminder: addReminder,
          reminder: addReminder
            ? ({
                ...reminder,
                reminderId: reminder?.reminderId,
                text: reminder?.text,
                date: reminder?.date,
                staffToNotify: reminder?.staffToNotify,
                notificationTypeIds: reminder?.notificationTypeIds,
                reminderTypeId: REMINDER_TYPE_APPOINTMENT,
                resolved: reminder?.resolved,
                emailSent: false,
              } as IReminder)
            : undefined,
          clientAttended: clientAttended,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Appointment created.');
            fileContext.setAppointments((a) =>
              a.concat([
                {
                  appointmentId: response.data.appointmentId,
                  date: date.getTime(),
                  description: description,
                  location: location,
                  staff: staff,
                  hasReminder: addReminder,
                  clientAttended: clientAttended,
                } as IAppointment,
              ])
            );
            if (response.data.reminderId) {
              fileContext.setReminders((r) =>
                r.concat([
                  {
                    reminderId: response.data.reminderId,
                    text: reminder?.text ? reminder.text : '',
                    date: reminder?.date,
                    staffToNotify: reminder?.staffToNotify ? reminder.staffToNotify : [],
                    notificationTypeIds: reminder?.notificationTypeIds ? reminder.notificationTypeIds : [],
                    reminderTypeId: REMINDER_TYPE_APPOINTMENT,
                    resolved: reminder?.resolved ? reminder.resolved : false,
                  },
                ])
              );
            }
            history.push('/files/' + params.fileId + '/appointments/' + response.data.appointmentId);
          } else {
            notifyError('Error creating appointment.');
          }
        });
    }
  };

  const deleteAppointment = () => {
    if (params.appointmentId) {
      authAxios
        .post('/api/files/delete-appointment', {
          fileId: params.fileId,
          appointmentId: params.appointmentId,
          reminderId: reminder?.reminderId,
        })
        .then((response) => {
          if (response.data.status === 'OK') {
            setIsDirty(false);
            notifySuccess('Appointment deleted.');
            fileContext.setAppointments((a) =>
              a.filter((appointment) => appointment.appointmentId !== params.appointmentId)
            );
            if (response.data.reminderId) {
              fileContext.setReminders((rArray) => rArray.filter((rem) => rem.reminderId !== response.data.reminderId));
            }
            history.push('/files/' + params.fileId + '/appointments');
          } else {
            notifyError('Error deleting appointment.');
          }
        });
    }
  };

  const handleCancel = () => {
    if (isDirty) {
      setCancelModalOpen(true);
    } else {
      history.push('/files/' + params.fileId + '/appointments');
    }
  };

  return (
    <>
      <LeavePageConfirm isDirty={isDirty} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
        className={'no-print'}
      >
        <Navbar color={'light'} light={true} expand={'xs'} className={'border-bottom sticky-top'}>
          <NavbarBrand>{params.appointmentId ? 'Edit Appointment' : 'Add Appointment'}</NavbarBrand>
          <div className={'ml-auto d-flex flex-row-reverse'}>
            <Button color={'primary'} className={'ml-2'} onClick={save} disabled={!isDirty}>
              Save Appointment
            </Button>
            {params.appointmentId ? (
              <div>
                <Button color='danger' className={'ml-2'} onClick={() => setDeleteModalOpen(true)}>
                  Delete Appointment
                </Button>
              </div>
            ) : (
              <Button color='link' className={'ml-auto'} onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </div>
        </Navbar>
        <Container fluid={true} className={'my-3'}>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <FormGroup>
                <Label htmlFor={'appointmentDate'}>Appointment Date</Label>
                <DatePicker
                  selected={date}
                  id={'appointmentDate'}
                  onChange={(date) => {
                    if (date) {
                      setDate(date);
                      setIsDirty(true);
                    }
                  }}
                  className={'form-control'}
                  peekNextMonth
                  showMonthDropdown
                  showTimeSelect
                  showYearDropdown
                  dateFormat='MMM dd, yyyy hh:mm aa'
                />
              </FormGroup>
              <FormGroup>
                <Label>Purpose / Description</Label>
                <Input
                  type='textarea'
                  style={{ minHeight: '50px' }}
                  placeholder=''
                  name='description'
                  value={description}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDescription(e.target.value);
                    setIsDirty(true);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Location</Label>
                <Input
                  type='text'
                  placeholder=''
                  name='location'
                  value={location}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setLocation(e.target.value);
                    setIsDirty(true);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Client Attended</Label>
                <Select
                  styles={disabledSelectStylesWithMenu}
                  name={'submitted-to-finance'}
                  options={clientAttendedOptions}
                  placeholder={<span className='placeholder'>Select...</span>}
                  value={clientAttendedOptions.find((g) => g.val === clientAttended)}
                  type={'text'}
                  onChange={(value: any) => {
                    setClientAttended(value?.val);
                    setIsDirty(true);
                  }}
                  getOptionValue={(option: any) => option.val}
                  getOptionLabel={(option: any) => option.display}
                  isClearable={true}
                />
              </FormGroup>
              <FormGroup>
                <Label>Staff</Label>
                <Select
                  name={'staffIds'}
                  options={users.filter((u) =>
                    u.departmentAccessIds.includes(
                      fileTypeIdToDepartmentId(fileContext.fileTypeId ? fileContext.fileTypeId : '')
                    )
                  )}
                  onChange={(value: any) => {
                    setStaff(value.map((v: IUser) => v.userId));
                    setIsDirty(true);
                  }}
                  styles={disabledSelectStylesWithMenu}
                  value={users.filter((u) => staff.includes(u.userId))}
                  getOptionValue={(option: IUser) => option.userId}
                  getOptionLabel={(option: IUser) => option.lastName + ', ' + option.firstName}
                  isClearable={true}
                  isMulti={true}
                />
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type='checkbox'
                  id={'addReminder'}
                  name={'addReminder'}
                  label={'Add Associated Reminder'}
                  checked={addReminder}
                  onChange={() => {
                    if (!reminder) {
                      setReminder(initialReminder);
                    }
                    setAddReminder((s) => !s);
                    setIsDirty(true);
                  }}
                />
              </FormGroup>
              {addReminder && (
                <FileReminderForm reminder={reminder} setReminder={setReminder} setIsDirty={setIsDirty} />
              )}
            </Col>
          </Row>
        </Container>
      </form>
      <ConfirmModal
        isOpen={deleteModalOpen}
        title={'Delete Appointment'}
        onConfirm={() => {
          setDeleteModalOpen(false);
          deleteAppointment();
        }}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButton={'Delete Appointment'}
        confirmButtonColor={'danger'}
      >
        <div>Are you sure you want to delete this appointment?</div>
      </ConfirmModal>
      <ConfirmModal
        isOpen={cancelModalOpen}
        title={`You have unsaved changes.`}
        onConfirm={() => {
          setCancelModalOpen(false);
          history.push('/files/' + params.fileId + '/appointments');
        }}
        onCancel={() => setDeleteModalOpen(false)}
        confirmButton={'Leave this Page'}
        cancelButton={'Stay on Page'}
      />
    </>
  );
};
