import { Button, Card, CardBody, CardHeader, Col, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FileContext } from './FileContext';
import { FaPlus, FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import { DomainContext, IFinancialSupportType } from '../../contexts/DomainContext';

interface IProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

export const FinancialSupports = (props: IProps) => {
  const fileContext = useContext(FileContext);
  const domainContext = useContext(DomainContext);
  const financialSupportTypes = domainContext.financialSupportTypes;
  const [changedFinancialSupportsLength, setChangedFinancialSupportsLength] = useState(0);
  let ref: any = undefined;

  useEffect(() => {
    if (changedFinancialSupportsLength > 0) {
      var elmnt = document.getElementById('add-new-financial-support');
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
        ref.focus();
      }
    }
  }, [changedFinancialSupportsLength]);

  const disabledSelectStyles = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        borderColor: '#ced4da',
        borderRadius: '1.25rem',
      };
    },
    singleValue: (styles: any, state: any) => {
      return {
        ...styles,
        color: '#495057',
      };
    },
  };

  return (
    <>
      <Card>
        <CardHeader className={'d-flex align-items-center'}>Financial Supports</CardHeader>
        <CardBody className={'p-0'}>
          <ListGroup flush={true}>
            {fileContext.financialSupports.length > 0 && (
              <ListGroupItem className={'py-2 d-none d-md-block'}>
                <Row style={{ paddingRight: 42 }} className='row--condensed'>
                  <Col md={5}>
                    <Label>Support</Label>
                  </Col>
                  <Col md={7}>
                    <Label>Description</Label>
                  </Col>
                </Row>
              </ListGroupItem>
            )}
            {fileContext.financialSupports &&
              fileContext.financialSupports.map((fs, index) => (
                <ListGroupItem key={fs.financialSupportId} className='list-group-item--borderless'>
                  <div className={'d-flex'}>
                    <Row className={'flex-grow-1 row--condensed'}>
                      <Col md={5}>
                        <Label className={'d-md-none'}>Support</Label>
                        <Select
                          styles={disabledSelectStyles}
                          name={'support'}
                          ref={(r) => {
                            ref = r;
                          }}
                          options={financialSupportTypes}
                          placeholder={<span className='placeholder'>Select...</span>}
                          value={financialSupportTypes.find(
                            (f: IFinancialSupportType) => f.financialSupportTypeId === fs.financialSupportTypeId
                          )}
                          onChange={(value: any) => {
                            props.setIsDirty(true);
                            fileContext.setFinancialSupports((oldFS) => [
                              ...oldFS.slice(0, index),
                              { ...oldFS[index], financialSupportTypeId: value ? value.financialSupportTypeId : '' },
                              ...oldFS.slice(index + 1),
                            ]);
                          }}
                          getOptionValue={(option: IFinancialSupportType) => option.financialSupportTypeId}
                          getOptionLabel={(option: IFinancialSupportType) => option.name}
                        />
                      </Col>
                      <Col md={7}>
                        <Label className={'d-md-none'}>Description</Label>
                        <Input
                          type='text'
                          value={fs.description}
                          onChange={(e) => {
                            const descriptionTemp = e.target.value;
                            fileContext.setFinancialSupports((oldFS) => [
                              ...oldFS.slice(0, index),
                              { ...oldFS[index], description: descriptionTemp },
                              ...oldFS.slice(index + 1),
                            ]);
                            props.setIsDirty(true);
                          }}
                        />
                      </Col>
                      <Col className={'d-md-none mt-3'}>
                        <Button
                          color='danger'
                          className='w-mobile-100'
                          onClick={() => {
                            props.setIsDirty(true);
                            fileContext.setFinancialSupports((oldFS) => [
                              ...oldFS.slice(0, index),
                              ...oldFS.slice(index + 1),
                            ]);
                          }}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>

                    <div className={'d-none d-md-block'} style={{ width: 42 }}>
                      <Button
                        color={'link'}
                        className={'text-danger'}
                        onClick={() => {
                          props.setIsDirty(true);
                          fileContext.setFinancialSupports((oldFS) => [
                            ...oldFS.slice(0, index),
                            ...oldFS.slice(index + 1),
                          ]);
                        }}
                      >
                        <FaTimes />
                      </Button>
                    </div>
                  </div>
                </ListGroupItem>
              ))}
            <ListGroupItem
              tag={'button'}
              id={'add-new-financial-support'}
              className='border-top'
              onClick={() => {
                props.setIsDirty(true);
                fileContext.setFinancialSupports((f) =>
                  f.concat([
                    {
                      financialSupportId: Math.random().toString(),
                      financialSupportTypeId: '',
                      description: '',
                    },
                  ])
                );
                setChangedFinancialSupportsLength((f) => f + 1);
              }}
            >
              <FaPlus /> Add Financial Support
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </>
  );
};
